import {
  faEnvelope,
  faGear,
  faLock,
  faMailBulk,
  faSignOut,
  faUser,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Badge, Button, Dropdown, Modal, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import Storage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import ConversionTable from "./ConversionTable";
import UserManual from "./UserManual";
const ROLES = [
  {
    name: "Preparer",
    value: 1,
  },
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approver",
    value: 4,
  },
];
const UserToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    drop="start"
    sty={{
      display: "flex",
      flexDirection: "column",
    }}
  ></Button>
));
const Header = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [conversionTable, setConversionTable] = useState(false);
  const role = ROLES.find((r) => r.value == parseInt(credentials.updated_role));
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);

  const getMessages = async () => {
    try {
      let response = await fetch(
        CONSTANTS.API_ROOT + "/Approval/inbox/" + credentials.region_sess
      );
      let data = await response.json();
      console.log(data);
      setMessages(data);
    } catch (error) {
      console.log("error@getting inbox", error);
    }
  };
  useEffect(() => {
    getMessages();
  }, []);
  return (
    <>
      <div className="row m-0">
        <div
          className="col-md-12"
          style={{ backgroundColor: "white", padding: 10 }}
        >
          <div className="d-flex flex-row  align-items-center">
            <img src="/logo-clear.png" style={{ width: "50px" }} />
            <div style={{ marginLeft: 10 }}>
              <div className="fw-bold">Emission</div>
              <div className="fw-bold">Inventory System</div>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              {props.region == "central" ? (
                <h3>Central Office</h3>
              ) : (
                <h3>Region - {props.region}</h3>
              )}
            </div>
            <ConversionTable />
            <UserManual/>
            <div style={{ fontSize: 30, display:'none' }}>
              <Dropdown drop="start" id="user-profile-menu">
                <Dropdown.Toggle
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    color="#2A3F54"
                    role="button"
                    style={{ fontSize: 30 }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      display: "inline-block",
                      backgroundColor: "red",
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                      fontSize: 12,
                      fontWeight: "bold",
                    }}
                  >
                    <span>4</span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ width: 400 }}>
                  {messages.map((message) => {
                    return (
                      <Dropdown.Item
                        role="button"
                        onClick={() => {
                          navigate(
                            "/" + message.souce_type + "/" + message.entry_id
                          );
                        }}
                      >
                        <Stack
                          style={{
                            borderBottom: "1px solid #2a2a2a50",
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <span style={{ textTransform: "capitalize" }}>
                            Date: <b>{message.date_created ?? ""}</b>
                          </span>
                          <span style={{ textTransform: "capitalize" }}>
                            From: <b>{message.sent_by ?? ""}</b>
                          </span>
                          <span>
                            Subject: <b>{message.subject}</b>
                          </span>
                        </Stack>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div
              style={{
                marginLeft: "auto",
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                borderLeft: "1px solid #2A3F5430",
                paddingLeft: 30,
              }}
            >
              <div style={{ marginRight: 10 }}>
                <span className="d-block fw-bold">{props.username}</span>
                <span className="d-block" style={{ fontSize: 12 }}>
                  {role?.name ?? ""}
                </span>
              </div>
              <div style={{ fontSize: 30 }}>
                <Dropdown drop="start" id="user-profile-menu">
                  <Dropdown.Toggle
                    style={{ backgroundColor: "transparent", border: "none" }}
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      color="#2A3F54"
                      role="button"
                      style={{ fontSize: 30 }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {/* <Dropdown.Item>
                    <FontAwesomeIcon icon={faGear} />
                    <span className="ms-1">Settings</span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <FontAwesomeIcon icon={faLock} />
                    <span className="ms-1">Change Password</span>
                  </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => {
                        setCredentials("");
                        setTimeout(() => {
                          navigate("/");
                        }, 1000);
                      }}
                    >
                      <FontAwesomeIcon icon={faSignOut} />
                      <span className="ms-1">Logout</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
