import {
  faEye,
  faEyeSlash,
  faFileInvoice,
  faParagraph,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import CONSTANTS from "../../utilities/constants";

const REGION_ROLES = [
  {
    name: "Preparer",
    value: 1,
  },
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approval",
    value: 4,
  },
];

const CENTRAL_ROLES = [
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approval",
    value: 4,
  },
];

const randomString = () => {
  // return "jmsalcedo";
  return Math.random().toString(36).slice(-6);
};
const AddUser = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const isCentral = credentials.user_fullname == "Central Section";
  const ROLES = isCentral ? CENTRAL_ROLES : REGION_ROLES;
  const [isValid, setIsValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [userData, setUserdata] = useState({
    password: randomString(),
    firstname: "",
    lastname: "",
    email: "",
    role: ROLES[0]["value"],
    region: credentials.region_sess,
  });
  const [password, setPassword] = useState(randomString());
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);

  const addUser = async () => {
    try {
      let response = await fetch(CONSTANTS.API_ROOT + "/Users/add", {
        method: "POST",
        body: JSON.stringify(userData),
      });
      let r = await response.json();
      console.log(r);
      swal({
        text: "New user saved successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        props.updateUsers();
      });
    } catch (error) {
      console.log(error);
    }
  };
  function ValidateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    (async () => {
      let hasError = false;
      if (userData.firstname.trim() == "") hasError = true;
      if (userData.lastname.trim() == "") hasError = true;
      if (userData.email.trim() == "") hasError = true;
      if (userData.email.trim() != "") {
        let emailValid = ValidateEmail(userData.email);
        if (emailValid) {
          // check if existing
          let isExisting = await checkUser(userData.email);
          if (isExisting) {
            hasError = true;
            setEmailError("User is already existing on Region: " + isExisting);
          } else {
            setEmailError("'");
          }
        } else {
          hasError = true;
          setEmailError("Invalid email address");
        }
      }
      setIsValid(!hasError)
    })();
  }, [userData]);

  const checkUser = async (email) => {
    let response = await fetch(CONSTANTS.API_ROOT + "/Users/isExisting", {
      method: "POST",
      body: JSON.stringify({ username: email }),
    });
    let r = await response.json();
    if (r.length > 0) {
      return r[0]["region"];
    } else {
      return false;
    }
  };
  useEffect(() => {
    console.log(userData);
  }, [userData]);
  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="success"
        className={props.className}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span> Add User</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          {isCentral ? (
            <Modal.Title>Add User &mdash; Central Office</Modal.Title>
          ) : (
            <Modal.Title>
              Add User &mdash; Region {credentials.region_sess}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body style={{ padding: "20px", minHeight: "500px" }}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="e.g. Juan"
                  onChange={(e) =>
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        firstname: e.target.value,
                      };
                    })
                  }
                />
                <Form.Label className="mt-2">Last Name</Form.Label>
                <Form.Control
                  placeholder="e.g. dela Cruz"
                  onChange={(e) =>
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        lastname: e.target.value,
                      };
                    })
                  }
                />
                <Form.Label className="mt-2">Email Address</Form.Label>
                <Form.Control
                  placeholder="e.g. delacruz.juan@denr.emb.gov.ph"
                  onChange={(e) => {
                    checkUser(e.target.value);
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    });
                  }}
                />
                <div>
                  <Form.Label className="mt-2">{emailError}</Form.Label>
                </div>
                <Form.Label className="mt-2">Role</Form.Label>
                <Form.Select
                  onChange={(e) =>
                    setUserdata((prev) => {
                      console.log(e.target.value);
                      return {
                        ...prev,
                        role: e.target.value,
                      };
                    })
                  }
                >
                  {ROLES.map((role) => {
                    return <option value={role.value}>{role.name}</option>;
                  })}
                </Form.Select>
                <Form.Label className="mt-2">
                  Auto-generated password
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={view ? "text" : "password"}
                    value={userData["password"]}
                    disabled
                  />
                  <Button
                    onClick={() => {
                      setView(!view);
                    }}
                  >
                    <FontAwesomeIcon icon={view ? faEye : faEyeSlash} />
                  </Button>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button onClick={addUser} disabled={!isValid}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUser;
