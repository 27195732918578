import logo from "./logo.svg";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import AreaSources from "./pages/area";
import StationarySources from "./pages/stationary";
import MobileSources from "./pages/mobile";
import Login from "./pages/login";
import Settings from "./pages/settings";
import MobileSourcesView from "./pages/mobile-view";
import AreaSourcesView from "./pages/area-view";
import StationaryView from "./pages/stationary-view";

const About = () => {
  return <h1>About</h1>;
};
function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="stationary" element={<StationarySources />} />
      <Route path="area" element={<AreaSources />} />
      <Route path="mobile" element={<MobileSources />} />
      <Route path="mobile/:id/:generalId" element={<MobileSourcesView />} />
      <Route path="area/:id" element={<AreaSourcesView />} />
      <Route path="stationary/:id" element={<StationaryView />} />
      <Route path="settings" element={<Settings />} />
      <Route path="about" element={<About />} />
    </Routes>
  );
}

export default App;
