import {
  faFileInvoice,
  faParagraph,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import CONSTANTS from "../../utilities/constants";

const AddStationaryEF = (props) => {
  const [show, setShow] = useState(false);
  const [fuelName, setFuelName] = useState("");
  const [sulfurContent, setSulfurContent] = useState("");
  const [ashContent, setAshContent] = useState("");
  const [equipmentDependent, setEquipmentDependent] = useState(false);
  const [apsiList, setApsiList] = useState([
    {
      name: "",
      apsi_capacity_max: 0,
      apsi_capacity_min: 0,
      apsi_unit: "",
      co: "",
      nox: "",
      pm: "",
      sox: "",
      voc: "",
    },
  ]);
  const [apsiListSimple, setApsiListSimple] = useState([
    {
      name: "",
      co: "",
      nox: "",
      pm: "",
      sox: "",
      voc: "",
    },
  ]);
  useEffect(() => {
    console.log(apsiList);
  }, [apsiList]);
  const saveStationary = async () => {
    let apsis = equipmentDependent ? apsiList : apsiListSimple;
    const payload = JSON.stringify({ apsis, fuelName, sulfurContent, ashContent, equipmentDependent });
    try {
      let response = await fetch(
        CONSTANTS.API_ROOT + "/StationaryFactors/add",
        {
          method: "POST",
          body: payload,
        }
      );
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor saved successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        // props.getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="success"
        className={props.className}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span> Add Emission Factor</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            Add Emission Factor &mdash; Stationary Sources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px", minHeight: "500px" }}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Fuel Name:</Form.Label>
              <Form.Control
                placeholder="e.g. Natural Gas, Bagasse, etc"
                onChange={(e) => setFuelName(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Sulfur Content:</Form.Label>
              <Form.Control
                placeholder="Sulfur content in percentage"
                onChange={(e) => setSulfurContent(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Ash Content:</Form.Label>
              <Form.Control
                placeholder="Ash content in percentage"
                onChange={(e) => setAshContent(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form>
              <Form.Check
                checked={equipmentDependent}
                type={"switch"}
                id={`multiple-fuel`}
                label={`Does the emission factor on this fuel vary on the APSI Capacity?`}
                style={{ marginTop: "10px" }}
                onChange={(e) => {
                  setEquipmentDependent(e.target.checked);
                }}
              />
            </Form>
          </Row>
          <Row className="mt-3">
            {equipmentDependent ? (
              <APSI_TABLE
                apsiList={apsiList}
                setter={(index, data) => {
                  let copies = [...apsiList];
                  copies[index] = { ...copies[index], ...data };
                  setApsiList(copies);
                }}
                setList={setApsiList}
              />
            ) : (
              <APSI_TABLE_SIMPLE
                apsiList={apsiListSimple}
                setter={(index, data) => {
                  let copies = [...apsiListSimple];
                  copies[index] = { ...copies[index], ...data };
                  setApsiListSimple(copies);
                }}
                setList={setApsiListSimple}
              />
            )}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={()=>setShow(false)}>Cancel</Button>
          <Button onClick={saveStationary}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const APSI_TABLE = (props) => {
  const deleteApsi = (i) => {
    let updated = props.apsiList.filter((item, index) => index != i);
    props.setList(updated);
  };
  return (
    <>
    <Table>
      <thead>
        <tr>
          <th>APSI Type</th>
          <th>Min Capacity</th>
          <th>Max Capacity</th>
          <th>Capacity Unit</th>
          <th>CO</th>
          <th>NOX</th>
          <th>PM</th>
          <th>SOx</th>
          <th>VOC</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.apsiList.map((apsi, index) => {
          return (
            <tr>
              <td>
                <Form.Control
                  value={apsi.name}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      name: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.apsi_capacity_min}
                  type={"number"}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      apsi_capacity_min: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.apsi_capacity_max}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      apsi_capacity_max: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.apsi_unit}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      apsi_unit: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.co}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      co: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.nox}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      nox: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.pm}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      pm: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.sox}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      sox: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Form.Control
                  value={apsi.voc}
                  onChange={(e) => {
                    props.setter(index, {
                      ...apsi,
                      voc: e.target.value,
                    });
                  }}
                />
              </td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    deleteApsi(index);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    
      <Button
        style={{ marginLeft: "auto", width: "180px" }}
        variant="success"
        onClick={() => {
          props.setList((f) => [
            ...f,
            {
              name: "",
              co: "",
              nox: "",
              pm: "",
              sox: "",
              voc: "",
            },
          ]);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span> Add APSI</span>
      </Button>
    </>
  );
};

const APSI_TABLE_SIMPLE = (props) => {
  const deleteApsi = (i) => {
    let updated = props.apsiList.filter((item, index) => index != i);
    props.setList(updated);
  };
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>APSI Type</th>
            <th>CO</th>
            <th>NOX</th>
            <th>PM</th>
            <th>SOx</th>
            <th>VOC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.apsiList.map((apsi, index) => {
            return (
              <tr>
                <td>
                  <Form.Control
                    value={apsi.name}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        name: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    value={apsi.co}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        co: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    value={apsi.nox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        nox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    value={apsi.pm}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        pm: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    value={apsi.sox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        sox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    value={apsi.voc}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        voc: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteApsi(index);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        style={{ marginLeft: "auto", width: "180px" }}
        variant="success"
        onClick={() => {
          props.setList((f) => [
            ...f,
            {
              name: "",
              co: "",
              nox: "",
              pm: "",
              sox: "",
              voc: "",
            },
          ]);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span> Add APSI</span>
      </Button>
    </>
  );
};

export default AddStationaryEF;
