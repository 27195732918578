import { Table, Button, Row, Col, Stack, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faCopy,
  faDownload,
  faPlus,
  faPrint,
  faSearch,
  faSpinner,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import * as ReactDOMServer from "react-dom/server";
import Select from "react-select";
import RecordActions from "../components/RecordActions";
import CommonFilter from "../components/CommonFilter";
import { useEffect, useRef, useState } from "react";
import AddStationary from "../components/AddStationary";
import swal from "sweetalert";
import ModuleContainer from "../components/ModuleContainer";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import DataTable from "react-data-table-component";
import EditStationary from "../components/EditStationary";
import CONSTANTS from "../utilities/constants";
import "leaflet/dist/leaflet.css";
import "leaflet-boundary-canvas";
import { MapContainer, TileLayer, useMap, GeoJSON } from "react-leaflet";
import * as L from "leaflet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import helper from "../utilities/helper";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");
const YEARS = [...Array(20).keys()].map((item) => {
  return {
    label: 2022 - item,
    value: 2022 - item,
  };
});
ChartJS.defaults.backgroundColor = "red";
const Dashboard = () => {
  const [region, setRegion] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [year, setYear] = useState(YEARS[0]);
  const [loaded, setLoaded] = useState(true);
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [currentData, setCurrentData] = useState([]);
  const [isPulling, setIsPulling] = useState(false);
  const navigate = useNavigate();
  const getFilteredData = () => {
    setIsPulling(true);
    fetch(CONSTANTS.API_ROOT + "/summary/getFilterData", {
      method: "POST",
      body: JSON.stringify({
        year: year.value,
        region: credentials.region_sess,
        province: selectedProvince.value,
        city: selectedCity.value,
        ...credentials,
      }),
    })
      .then(async (data) => {
        const list = await data.json();
        // console.log(list);
        setCurrentData(list);
        setIsPulling(false);
      })
      .catch((error) => {
        console.log(error);
        setIsPulling(false);
      });
  };

  useEffect(() => {
    try {
      console.log("Region Detailsss", region);
      if (region) return;
      let regionDetails = REGIONS.find(
        (r) => r.name === region || r.long == region
      );
      let regionCode = regionDetails["regCode"];
      console.log("REG CODE: :" + regionCode);
      // get the provinces
      let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
      // format for select
      let formattedProvinces = provinces.map((province) => {
        return {
          value: province["provDesc"],
          label: province["provDesc"],
        };
      });
      setProvinces([{ label: "All", value: "" }, ...formattedProvinces]);
      setSelectedProvince({ label: "All", value: "" });
      setSelectedCity({ label: "All", value: "" });
    } catch (error) {
      console.log("error@region effect", error);
    }
  }, [region]);

  useEffect(() => {
    // console.log(selectedProvince);
    let provinceDetails = PROVINCES.find(
      (p) => p.provDesc == selectedProvince.value
    );
    if (!provinceDetails) {
      return;
    }
    // console.log("PORovinde details: ");
    // console.log(provinceDetails);
    let provinceCode = provinceDetails["provCode"];
    let cities = CITIES.filter((c) => c.provCode === provinceCode);
    let formattedCities = cities.map((city) => {
      return {
        value: city["citymunDesc"],
        label: city["citymunDesc"],
      };
    });
    setCities([{ label: "All", value: "" }, ...formattedCities]);
    setSelectedCity({ label: "All", value: "" });
  }, [selectedProvince]);
  useEffect(() => {
    if (!Object.keys(credentials).includes("region_sess")) {
      // console.log(Object.keys(credentials));
      navigate("/");
    } else {
      const finalRegion = ["NCR", "CAR", "ARMM"].includes(
        credentials.region_sess
      )
        ? credentials.region_sess
        : `Region ${credentials.region_sess}`;
      setRegion(finalRegion);
      // setSelectedProvince({
      //   value: finalRegion,
      //   label: finalRegion,
      // });
      setLoaded(true);
      // console.log(credentials);
    }
  }, []);

  return (
    <ModuleContainer>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Map</Tab>
          {/* <Tab>Mobile</Tab>
          <Tab>Stationary</Tab> */}
        </TabList>
        <TabPanel>
          <GeneralTab />
        </TabPanel>
        <TabPanel>
          <AreaTab />
        </TabPanel>
        {/* <TabPanel>
          <MobileTab />
        </TabPanel>
        <TabPanel>
          <StationaryTab />
        </TabPanel> */}
      </Tabs>
    </ModuleContainer>
  );
  return (
    <ModuleContainer>
      <Row className="mt-5">
        <Col>
          <Stack
            direction="horizontal"
            gap={3}
            style={{
              alignItems: "center",
            }}
          >
            <Form.Label>Year:</Form.Label>
            <div style={{ width: "150px" }}>
              <Select
                options={YEARS}
                value={year}
                onChange={(e) => {
                  setYear(e);
                }}
              />
            </div>
            <Form.Label>Province:</Form.Label>
            <div style={{ width: "150px" }}>
              <Select
                options={provinces}
                value={selectedProvince}
                onChange={(e) => {
                  setSelectedProvince(e);
                }}
              />
            </div>
            <Form.Label>City/Municipality:</Form.Label>
            <div style={{ width: "200px" }}>
              <Select
                options={cities}
                value={selectedCity}
                onChange={(e) => setSelectedCity(e)}
              />
            </div>
            <Button variant="success" onClick={getFilteredData}>
              <FontAwesomeIcon icon={faSearch} /> Search
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <div class="table-responsive">
            <table
              id="summary-table"
              class="table table-striped users-table-lists"
            >
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Source</th>
                  <th>No of Sources</th>
                  <th class="sep">CO</th>
                  <th class="sep">NOX</th>
                  <th class="sep">PM</th>
                  <th class="sep">SOx</th>
                  <th class="sep">VOC</th>
                  <th>Total Emission tons/year</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2020</td>
                  <td>Stationary</td>
                  <td>264</td>
                  <td>2.17</td>
                  <td>10.19</td>
                  <td>0.72</td>
                  <td>0.67</td>
                  <td>0.82</td>
                  <td>12</td>
                </tr>
                <tr>
                  <td>2020</td>
                  <td>Mobile</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>2020</td>
                  <td>Area</td>
                  <td>0</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0.00</td>
                  <td>0</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2">Total Emission tons/year</th>
                  <th>264</th>
                  <th>
                    2 <span>tons/year</span>
                  </th>
                  <th>
                    10 <span>tons/year</span>
                  </th>
                  <th>
                    0 <span>tons/year</span>
                  </th>
                  <th>
                    0 <span>tons/year</span>
                  </th>
                  <th>
                    0 <span>tons/year</span>
                  </th>
                  <th>
                    12 <span>tons/year</span>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Col>
      </Row>
    </ModuleContainer>
  );
};

const findGeoJSON = (region) => {
  if (region == "NCR") {
    region = "Metropolitan Manila";
  } else if (region == "CAR") {
    region = "Cordillera Administrative Region (CAR)";
  } else if (region == "ARMM") {
    region = "Autonomous Region of Muslim Mindanao (ARMM)";
  } else {
    region = "(Region " + region + ")";
  }
  const regions = require("../data/Regions.json");
  const cities = require("../data/Cities.json");
  const current = regions.features.filter((r) =>
    r.properties.REGION.toLowerCase().includes(region.toLowerCase())
  );

  const allCities = cities.features.filter((r) =>
    r.properties.REGION.toLowerCase().includes(region.toLowerCase())
  );

  console.log("-------------------------------");
  console.log(allCities);
  // regions.features.map((r) => {
  //   console.log(r.properties.REGION);
  // });
  console.log(allCities);
  return allCities;
};

const AreaTab = (props) => {
  const [credentials] = useLocalStorage("credentials");
  const [map, setMap] = useState(null);
  const current = findGeoJSON(credentials.region_sess);
  const geoJsonRef = useRef();

  // useEffect(() => {
  //   if (!map) return;

  //   const fetchGeoJSON = async () => {
  //     try {
  //       const geoJSON = require("../data/PHL.json");
  //       const osm = L.TileLayer.boundaryCanvas(
  //         "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  //         {
  //           boundary: geoJSON,
  //           attribution:
  //             '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, UK shape <a href="https://github.com/johan/world.geo.json">johan/word.geo.json</a>',
  //         }
  //       );
  //       map.addLayer(osm);
  //       const ukLayer = L.geoJSON(current);
  //       map.fitBounds(ukLayer.getBounds());
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   fetchGeoJSON();
  // }, [map]);

  return (
    <Row>
      {/* <Col md={12}>
        <CommonFilter />
      </Col> */}
      <Col md={12} className="mt-4">
        <MapContainer
          whenReady={setMap}
          center={[14.599512, 120.984222]}
          zoom={11}
          scrollWheelZoom={true}
          style={{ height: "100vh" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png"
          />
          <GeoJSON
            ref={geoJsonRef}
            attribution="&copy; credits due..."
            data={current}
            onEachFeature={(feature, layer) => {
              // random color for now
              let ran1 = helper.randomNumber() / 100;
              let ran2 = helper.randomNumber() / 100;
              let ran3 = helper.randomNumber() / 100;
              const color = helper.numberToColorHsl(ran1, ran2, ran3);
              layer.setStyle({ color });
              const cityName = feature.properties.NAME_2;
              const popupContent = ReactDOMServer.renderToString(
                <table className="table">
                  <thead>
                    <tr>
                      <th colspan={2}>{cityName}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mobile:</td>
                      <td>27 Tons/Year</td>
                    </tr>
                    <tr>
                      <td>Area:</td>
                      <td>65 Tons/Year</td>
                    </tr>
                    <tr>
                      <td>Stationary:</td>
                      <td>39 Tons/Year</td>
                    </tr>
                  </tbody>
                </table>
              );
              layer.bindPopup(popupContent);
              layer.on({
                click: (e) => {
                  // if (!geoJsonRef.current) return;
                  // geoJsonRef.current.resetStyle();

                  console.log(feature);
                  // e.target.setStyle({ color: "red" });
                  layer.openPopup();
                },
              });
            }}
          />
        </MapContainer>
      </Col>
    </Row>
  );
};

const options = {
  indexAxis: "y",
  plugins: {
    title: {
      display: true,
      text: "Pollutant",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      display: true,
      text: "TONS/Year",
      color: "red",
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value + " Tons/Year";
        },
      },
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["CO", "NOX", "SOX", "VOC", "PM", "Total"];
const GeneralTab = (props) => {
  const pieChartRef = useRef(null);
  const barChartRef = useRef(null);
  const [credentials] = useLocalStorage("credentials");
  const finalRegion = ["NCR", "CAR", "ARMM"].includes(credentials.region_sess)
    ? credentials.region_sess
    : `Region ${credentials.region_sess}`;
  const [PIE_DATA, SET_PIE_DATA] = useState([100, 50, 0]);
  const [barData, setBarData] = useState({
    area: labels.map(() => 0),
    mobile: labels.map(() => 0),
    stationary: labels.map(() => 0),
  });
  const BAR_TEMPLATE = {
    labels,
    datasets: [
      {
        label: "Area",
        data: barData["area"],
        backgroundColor: "rgb(18, 35, 158)",
      },
      {
        label: "Mobile",
        data: barData["mobile"],
        backgroundColor: "rgb(17, 141, 255)",
      },
      {
        label: "Stationary",
        data: barData["stationary"],
        backgroundColor: "rgb(230, 108, 55)",
      },
    ],
  };

  const [filter, setFilter] = useState({
    region: finalRegion,
    year: { value: "2022" },
    province: { value: "" },
    city: { value: "" },
  });

  const updateData = async (filter) => {
    let regionFilter = credentials.region_sess;
    let yearFilter = filter["year"]["value"];
    let provinceFilter = filter["province"]["value"];
    let cityFilter = filter["city"]["value"];
    try {
      let resp = await fetch(
        CONSTANTS.API_ROOT +
          `/reports/getRegionSummary/${yearFilter}/${regionFilter}/${provinceFilter}/${cityFilter}`
      );
      let data = await resp.json();
      console.log(data);

      let area = data["area"];
      let mobile = data["mobile"];
      let stationary = data["stationary"];
      SET_PIE_DATA([
        area["TotalPollutants"],
        mobile["TotalPollutants"],
        stationary["TotalPollutants"],
      ]);

      setBarData({
        area: [
          area["TotalCO"],
          area["TotalNOX"],
          area["TotalSOX"],
          area["TotalVOC"],
          area["TotalPM"],
          area["TotalPollutants"],
        ],
        mobile: [
          mobile["TotalCO"],
          mobile["TotalNOX"],
          mobile["TotalSOX"],
          mobile["TotalVOC"],
          mobile["TotalPM"],
          mobile["TotalPollutants"],
        ],
        stationary: [
          stationary["TotalCO"],
          stationary["TotalNOX"],
          stationary["TotalSOX"],
          stationary["TotalVOC"],
          stationary["TotalPM"],
          stationary["TotalPollutants"],
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    updateData(filter);
    console.log(filter);
  }, [filter]);
  const PIE_CHART_CONFIG = {
    labels: ["Area", "Mobile", "Stationary"],
    datasets: [
      {
        label: "Tons per Year",
        data: PIE_DATA,
        backgroundColor: [
          "rgb(18, 35, 158)",
          "rgb(17, 141, 255)",
          "rgb(230, 108, 55)",
        ],
      },
    ],
  };

  return (
    <>
      <CommonFilter region={finalRegion} handler={setFilter} />
      <Row>
        <Col>
          <div style={{ height: 500, width: 500, marginTop: 20 }}>
            <Pie
              data={PIE_CHART_CONFIG}
              ref={pieChartRef}
              options={{
                centerText: {
                  display: true,
                },
              }}
            />
            {/* <Button
              className="float-end"
              onClick={() => {
                const base64Image = pieChartRef.current.toBase64Image();
                const downloadLink = document.createElement("a");
                downloadLink.href = base64Image;
                downloadLink.download =
                  "General-" + credentials.region_sess + ".jpeg";
                downloadLink.click();
              }}
            >
              Download Chart
            </Button> */}
          </div>
        </Col>
        <Col
          style={
            {
              // alignItems: "center",
              // display: "flex",
              // flexDirection: "row",
            }
          }
        >
          <div style={{ height: 600 }}>
            <Bar options={options} data={BAR_TEMPLATE} ref={barChartRef} />
            {/* <Button
              className="float-end mt-5"
              onClick={() => {
                const base64Image = barChartRef.current.toBase64Image();
                const downloadLink = document.createElement("a");
                downloadLink.href = base64Image;
                downloadLink.download =
                  "General-" + credentials.region_sess + ".jpeg";
                downloadLink.click();
              }}
            >
              Download Chart
            </Button> */}
          </div>
        </Col>
      </Row>
    </>
  );
};
const MobileTab = (props) => {
  return <div>Mobile Tab</div>;
};
const StationaryTab = (props) => {
  return <div>Stationary Tab</div>;
};
export default Dashboard;
