import {
  faEdit,
  faEllipsisV,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import helper from "../utilities/helper";
import EditArea from "./EditArea";
import EditMobile from "./EditMobile";
import EditStationary from "./EditStationary";

const RecordActions = (props) => {
  const isPreparer = helper.isPreparer();
  const navigate = useNavigate();
  const [credentials] = useLocalStorage("credentials");
  var viewUrl;
  if (props.source == "mobile") {
    viewUrl = "/" + props.source + "/" + props.mobile_id + "/" + props.id;
  } else {
    viewUrl = "/" + props.source + "/" + props.id;
  }
  return (
    <Dropdown
      className="d-inline"
      drop="start"
      id={"record-actions-" + props.index}
    >
      <Dropdown.Toggle style={internalStyles.toggle}>
        <FontAwesomeIcon
          icon={faEllipsisV}
          role="button"
          style={{ fontSize: 30 }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu style={internalStyles.menu}>
        <Dropdown.Item>
          <Button onClick={() => navigate(viewUrl)}>
            <FontAwesomeIcon icon={faEye} />
            <span className="ms-2">View</span>
          </Button>
        </Dropdown.Item>
        <Dropdown.Item className={isPreparer ? "d-block" : "d-none"}>
          {props.source == "mobile" && (
            <EditMobile
              region={credentials.region_sess}
              updateTable={props.updateTable}
              rowData={props.rowData}
            />
          )}
          {props.source == "area" && (
            <EditArea
              region={credentials.region_sess}
              updateTable={props.updateTable}
              rowData={props.rowData}
            />
          )}
          {props.source == "stationary" && (
            <EditStationary
              region={credentials.region_sess}
              updateTable={props.updateTable}
              rowData={props.rowData}
            />
          )}
        </Dropdown.Item>
        <Dropdown.Item className={isPreparer ? "d-block" : "d-none"}>
          <Button
            variant="danger"
            onClick={() => {
              swal({
                title: "",
                text: "Are you sure to delete this entry?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then(async (willDelete) => {
                if (willDelete) {
                  const response = await fetch(
                    CONSTANTS.API_ROOT +
                      "/" +
                      props.source +
                      "/delete/" +
                      props.id
                  );
                  const data = await response.json();
                  props.localRemove(props.id);
                } else {
                  // nothing to do for now
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            <span className="ms-2">Delete</span>
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const internalStyles = {
  toggle: {
    backgroundColor: "transparent",
    border: "none",
    float: "right",
    color: "#2A3F54",
  },
  menu: { marginLeft: "50px !important", zIndex: 999 },
};
export default RecordActions;
