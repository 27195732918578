import "leaflet/dist/leaflet.css";
import {
  Accordion,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
  Modal,
  InputGroup,
  Button,
} from "react-bootstrap";
import helper from "../utilities/helper";
import Select from "react-select";
import { useEffect, useState } from "react";
import PlantForm from "./PlantForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  faEdit,
  faInfoCircle,
  faMapLocation,
  faMapLocationDot,
  faPlug,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import areaHelper from "../utilities/area";

// leaflet

import { MapContainer, TileLayer, useMap } from "react-leaflet";
import DraggableMarker from "./DraggableMarker";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import LeaftletDrawer from "./LeafletDrawer";
const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");
const INDUSTRIES = require("../utilities/json/industries.json");
const SOURCE_TYPES = areaHelper.getSourceTypes();

const RESIDENTIAL_COOKING_FUELS = {
  Wood: {
    unit: "Tons/Year",
    pm: 30.6,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  Charcoal: {
    unit: "Kg/Year",
    pm: 41.31,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  LPG: {
    unit: "Liter/Year",
    pm: 0.03,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  Kerosene: {
    unit: "Liter/Year",
    pm: 0,
    co: 0,
    nox: 0,
    sox: 1.48,
    voc: 0,
  },
};

const DEFAULT_REGION = "NCR"; // hardcoded for now

const EditArea = (props) => {
  console.log(props);
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [show, setShow] = useState(false);
  const finalRegion = ["NCR", "CAR", "ARMM"].includes(props.region)
    ? props.region
    : `Region ${props.region}`;
  const [region, setRegion] = useState(finalRegion);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({
    value: props.region,
    label: props.region,
  });
  const [selectedCity, setSelectedCity] = useState("");
  const [areaSources, setAreaSources] = useState([]);
  const [year, setYear] = useState("2022");
  const updateAreaList = (index, data) => {
    let copies = [...areaSources];
    copies[index] = { ...copies[index], ...data };
    setAreaSources(copies);
    console.log(copies);
  };

  const addAreaSources = () => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to update these Area Sources?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {

      if (willSave) {
        let generalInfo = {
          year,
          province: selectedProvince["value"],
          city: selectedCity?.value ?? "",
          region,
        };
        fetch(CONSTANTS.API_ROOT + "/area/editaction/"+props.rowData.id, {
          method: "POST",
          body: JSON.stringify({ areaSources, generalInfo, credentials }),
        })
          .then(async (data) => {
            // const list = await data.json();
            // console.log(list);
            props.updateTable();
            props.handleClose(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // nothing to do for now
      }
    });
  };

  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // console.log("REG CODE: :" + regionCode);
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    setProvinces(formattedProvinces);
    let currenProvince = formattedProvinces.find(
      (p) => p.value == props.rowData.province
    );
    setSelectedProvince(currenProvince);
  }, [region]);

  useEffect(() => {}, []);

  useEffect(() => {
    try {
			// console.log(selectedProvince);
			let provinceDetails = PROVINCES.find(
				(p) => p.provDesc == selectedProvince.value
			);
			if (!provinceDetails) {
				return;
			}
			// console.log("PORovinde details: ");
			// console.log(provinceDetails);
			let provinceCode = provinceDetails["provCode"];
			let cities = CITIES.filter((c) => c.provCode === provinceCode);
			let formattedCities = cities.map((city) => {
				return {
					value: city["citymunDesc"],
					label: city["citymunDesc"]
				};
			});
			setCities(formattedCities);

			let currentCity = formattedCities.find(
				(p) => p.value == props.rowData.city
			);
			setSelectedCity(currentCity);
		} catch (error) {
      
    }
  }, [selectedProvince]);

  const getAreaSources = async () => {
    const resp = await fetch(
      CONSTANTS.API_ROOT + "/area/getSources/" + props.rowData.id
    );
    const data = await resp.json();
    setAreaSources(data);
    // setMobileSources(data);
    // setLoading(false);
  };

  useEffect(() => {
    getAreaSources();
  }, []);
  return (
    <>
      <Button
        variant="info"
        className="text-white d-block"
        onClick={() => {
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <span className="ms-2">Edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Edit Area Source &mdash; {props.region} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Card style={{ minHeight: "80vh" }}>
                <Card.Body>
                  <Card.Title>General Details</Card.Title>
                  <hr />
                  <Form.Label>Year:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="e.g. 2022"
                    max={2020}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                  <Form.Label className="mt-3">Province:</Form.Label>
                  <Select
                    options={provinces}
                    value={selectedProvince}
                    onChange={(e) => {
                      setSelectedProvince(e);
                    }}
                  />
                  <Form.Label className="mt-3">City/Municipality:</Form.Label>
                  <Select
                    options={cities}
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e);
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={9}>
              <Card style={{ height: "80vh", overflowY: "scroll" }}>
                <Card.Body>
                  <Card.Title>Area Sources List</Card.Title>
                  <hr style={{ borderBottom: "1px solid #2A3F54" }} />
                  <Stack>
                    {areaSources.map((item, index) => {
                      return (
                        <AreaSourceForm
                          key={"area-source-" + index}
                          {...item}
                          index={index}
                          updateAreaList={updateAreaList}
                        />
                      );
                    })}
                  </Stack>
                  <Button
                    variant="success"
                    className="mt-4"
                    onClick={() => {
                      setAreaSources((prev) => [...prev, { source_type: "" }]);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Line
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>{" "}
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addAreaSources}>
            Add Sources
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FUELS = Object.keys(RESIDENTIAL_COOKING_FUELS).map((item) => {
  return {
    value: item,
    label: item,
  };
});
const AreaSourceForm = (props) => {
  console.log("xxxxx", props);
  const [coordinates, setCoordinates] = useState();
  const [address, setAddress] = useState(props.address);
  const [pollutants, setPollutants] = useState({
    co: props.co,
    nox: props.nox,
    pm: props.pm,
    sox: props.sox,
    voc: props.voc,
  });
  const [activityRate, setActivityRate] = useState(props.activity_rate);
  const currentSourceType = SOURCE_TYPES.find(
    (s) => s.label == props.type_area_source
  );
  const [sourceType, setSourceType] = useState(currentSourceType);
  const [duration, setDuration] = useState(props.duration_in_monhts);
  const [showMap, setShowMap] = useState(false);
  const [ARPlaceholder, setARPlaceholder] = useState("");
  const [durationPlaceholder, setDurationPlaceholder] = useState("");
  const [fuels, setFuels] = useState(FUELS);

  const currentFuel = FUELS.find((f) => f.value == props.fuel);
  const [fuel, setFuel] = useState(currentFuel ?? "");

  useEffect(() => {
    props.updateAreaList(props.index, {
      ...pollutants,
      coordinates,
      activit_rate: activityRate,
      duration_in_monhts: duration,
      fuel: fuel.value,
      address,
      type_area_source: sourceType.label,
    });
  }, [
    sourceType,
    pollutants,
    coordinates,
    activityRate,
    duration,
    fuel,
    address,
  ]);
  useEffect(() => {
    if (fuel != "") {
      setARPlaceholder(RESIDENTIAL_COOKING_FUELS[fuel.value]["unit"]);
    }
  }, [fuel]);

//   useEffect(() => {
//     if (durationPlaceholder == "") {
//       setDuration("");
//     }
//   }, [durationPlaceholder]);
  useEffect(() => {
    if (sourceType == "") {
      return;
    }

    console.log(activityRate);
    console.log(sourceType);
    console.log(duration);
    var result = areaHelper.calculate(activityRate, sourceType.value, {
      duration: duration,
      fuel: fuel.value ?? "",
    });
    console.log({
      duration: duration,
      fuel: fuel.value,
    });
    setPollutants(result);
  }, [activityRate, sourceType, duration, fuel]);

  useEffect(() => {
    console.log(coordinates);
  }, [coordinates]);
  return (
    <>
      <Accordion defaultActiveKey={props.index} className="mt-3">
        <Accordion.Item eventKey={props.index}>
          <Accordion.Header>
            {"Area Source " + parseInt(props.index + 1)}
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={2} style={{ zIndex: 9999999 }}>
                <Form.Label className="mt-3">Type of Sources:</Form.Label>
                <Select
                  options={SOURCE_TYPES}
                  value={sourceType}
                  onChange={(e) => {
                    setDurationPlaceholder(e.duration_label ?? "");
                    setARPlaceholder(e.ar_label ?? "");
                    setSourceType(e);
                  }}
                />
              </Col>
              <Col md={2} style={{ zIndex: 9999999 }}>
                <Form.Label className="mt-3">Fuel:</Form.Label>
                <Select
                  options={fuels}
                  value={fuel}
                  onChange={(e) => {
                    setFuel(e);
                  }}
                  isDisabled={sourceType?.value !== "residential_cooking"}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">
                  {ARPlaceholder.length > 0 ? ARPlaceholder : "Activity Rate"}
                </Form.Label>
                <Form.Control
                  value={activityRate}
                  onChange={(e) => setActivityRate(e.target.value)}
                  placeholder={ARPlaceholder}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">Duration (in months): </Form.Label>
                <Form.Control
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  placeholder={durationPlaceholder}
                  disabled={durationPlaceholder == ""}
                />
              </Col>
              <Col md={3}>
                <Form.Label className="mt-3">Address:</Form.Label>
                <Form.Control onChange={(e) => setAddress(e.target.value)} value={address} />
              </Col>
              {/* <Col md={3}>
                <Form.Label className="mt-3">UTM:</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={coordinates}
                    onChange={(e) => setCoordinates(e.target.value)}
                  />

                  <Button variant="success" onClick={() => setShowMap(true)}>
                    <FontAwesomeIcon icon={faMapLocationDot} /> Plot
                  </Button>
                </InputGroup>
              </Col> */}
            </Row>
            <Row>
              <Col className="mt-3 mb-3">
                <Form.Label>Plot the location of the area source</Form.Label>
                <MapContainer
                  center={[15.2033717, 120.5850848]}
                  zoom={13}
                  scrollWheelZoom={false}
                  style={{ height: "50vh" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

                  {/* <DraggableMarker callback={setCoordinates} /> */}
                  <LeaftletDrawer />
                </MapContainer>
              </Col>
            </Row>

            <Stack direction="horizontal" gap={5}>
              <Col md={2}>
                <Form.Label className="mt-3">CO (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.co}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        co: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">NOX (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.nox}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        nox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">PM (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.pm}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        pm: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">SOx (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.sox}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        sox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">VOC (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants["voc"]}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        voc: e.target.value,
                      };
                    })
                  }
                />
              </Col>
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        size={"xl"}
      >
        {" "}
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Plot a polygon for the given area source </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "80vh" }}>
          <MapContainer
            center={[15.2033717, 120.5850848]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "80vh" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

            {/* <DraggableMarker callback={setCoordinates} /> */}
            <LeaftletDrawer />
          </MapContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditArea;
