import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const ColumnFilter = (props) => {
  const [show, setShow] = useState(false);
  console.log(props);
  if (!props.columns) return <></>;
  return (
    <>
      <Button
        variant="success"
        className={props.className}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faClipboardList} />
      </Button>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
        size="md"
        close
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Column Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Toggle the columns you want to see.</p>
          <Form>
            {Object.keys(props.columns).map((column) => {
              return (
                <Form.Check
                  checked={!props.columns[column].omit}
                  type={"switch"}
                  id={`column-${column}`}
                  label={props.columns[column].label}
                  style={{ marginTop: "10px" }}
                  onChange={(e) => {
                    props.setColumns((prev) => {
                      let copy = { ...prev };
                      copy[column]["omit"] = !e.target.checked;
                      console.log(copy);
                      return copy;
                    });
                  }}
                />
              );
            })}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ColumnFilter;
