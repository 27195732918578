import "leaflet/dist/leaflet.css";
import "leaflet/dist/images/marker-shadow.png";
import {
  faMapLocationDot,
  faPlus,
  faTrash,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MapContainer, TileLayer } from "react-leaflet";
import swal from "sweetalert";
import stationaryHelper, { EMISSION_FACTORS } from "../utilities/stationary";
import Legend from "./Legend";
import DraggableMarker from "./DraggableMarker";

const generateYears = (startYear) => {
  var currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1980;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years.reverse();
};

const YEARS = generateYears(2001);
const EditPlantForm = (props) => {
  console.log("PLANTFORM PROPS", props);
  const [data, setData] = useState(props);
  useEffect(() => {
    let details = { ...data };
    delete details["deleteAction"];
    delete details["updatePlantList"];
    props.updatePlantList(props.index, { ...details });
  }, [data]);

  return (
    <Accordion defaultActiveKey={props.index} className="mt-2">
      <Accordion.Item eventKey={props.index}>
        <Accordion.Header>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div>
              {data["Name of Plant"]
                ? data["Name of Plant"]
                : "APSI " + parseInt(props.index + 1)}
            </div>
            <div style={{ marginLeft: "auto", marginRight: "10px" }}>
              {props.deleteAction}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {!props.q && !props.c && (
            <Alert key={"danger"} variant={"danger"}>
              <h6>
                <FontAwesomeIcon icon={faWarning} color="black" />
                <span className="ms-1">
                  There is no concentration and flow rate for the given Air
                  Pollution Source Installation. Please input the necessary
                  details to compute the pollutants.
                </span>
              </h6>
            </Alert>
          )}
          <Row>
            <Col md={6}>
              <Form.Label>
                Plant Name: <Legend />
              </Form.Label>
              <Form.Control
                placeholder="e.g. Sample Plant"
                value={data["Name of Plant"]}
                onChange={(e) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      "Name of Plant": e.target.value,
                    };
                  });
                }}
              />
            </Col>
            <Col md={6}>
              <Form.Label>
                Plant Adress: <Legend />
              </Form.Label>
              <Form.Control
                value={data["Plant Address"]}
                onChange={(e) => {
                  setData((prev) => {
                    return {
                      ...prev,
                      "Plant Address": e.target.value,
                    };
                  });
                }}
                placeholder="e.g. Visayas Avenue, QC"
              />
            </Col>
          </Row>
          <h5 className="text-center mt-3">APSI</h5>
          {/* <Card body> */}
          <APSI {...props} />
          {/* </Card> */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

// const FUELS = [
//   { label: "Diesel", value: "diesel" },
//   { label: "Bunker Fuel", value: "bunker_fuel" },
//   { label: "Oil (Mixed Distillate & Residual)", value: "oil" },
//   { label: "Coal - Anthracite", value: "coal_anthracite" },
//   { label: "Bagasse", value: "bagasse" },
//   { label: "Wood", value: "wood" },
//   { label: "LPG Propane / Butane", value: "lpg" },
// ];
const FUELS = Object.keys(EMISSION_FACTORS).map((factor, index) => {
  return {
    value: factor,
    label: EMISSION_FACTORS[factor]["label"],
  };
});
// console.log(FUELS);

const DEFAULT_APSI_TYPES = [
  { value: "boiler_hp", label: "Boiler" },
  { value: "generator_kw", label: "Generator" },
  { value: "others", label: "Others" },
];

const stackSamplingEstimate = (
  sfr,
  concentration,
  operatingHours,
  apcdEfficiency
) => {
  console.log(sfr, concentration, operatingHours, apcdEfficiency);
  return (
    (0.00006 *
      sfr *
      concentration *
      parseInt(operatingHours) *
      (1 - apcdEfficiency / 100)) /
    1000
  ).toFixed(5);
};

const FORMAT_EF = () => {
  var formatted = Object.keys(EMISSION_FACTORS).flatMap((factor, index) => {
    var fuel = factor;
    var fuelDetails = EMISSION_FACTORS[factor];
    fuel = fuelDetails.label;
    var unit = fuelDetails.unit ?? "";
    var min_capacity = fuelDetails.min_capacity ?? 0;
    var efs = fuelDetails.efs;
    var efs_max = fuelDetails.efs_max ?? {};
    var equipments = fuelDetails.equipments ?? [];

    if (equipments.length > 0) {
      var equipments_efs = equipments.map((equipment, index) => {
        let equipment_efs = equipment.efs;
        let equipment_efs_max = equipment.efs_max ?? {};
        let equipment_name = equipment.value;
        if (Object.keys(equipment_efs_max).length == 0) {
          return {
            key: factor + "-" + equipment_name + "-min",
            fuel,
            equipment_name: equipment.label,
            unit,
            min_capacity,
            type: "min",
            ...equipment_efs,
            active: false,
          };
        } else {
          return [
            {
              key: factor + "-" + equipment_name + "-min",
              fuel,
              equipment_name: equipment.label,
              unit,
              min_capacity,
              ...equipment_efs,
              type: "min",
            },
            {
              key: factor + "-" + equipment_name + "-max",
              fuel,
              equipment_name,
              type: "max",
              unit,
              min_capacity,
              ...equipment_efs_max,
              active: false,
            },
          ];
        }
      });
      return equipments_efs;
    } else {
      if (Object.keys(efs_max).length == 0) {
        return {
          key: factor + "-min",
          fuel,
          unit,
          min_capacity,
          type: "min",
          ...efs,
          active: false,
        };
      } else {
        return [
          {
            key: factor + "-min",
            fuel,
            unit,
            min_capacity,
            ...efs,
            type: "min",
          },
          {
            key: factor + "-max",
            fuel,
            type: "max",
            unit,
            min_capacity,
            ...efs_max,
            active: false,
          },
        ];
      }
    }
  });
  return formatted;
};
const APSI = (props) => {
  const [apcd, setAPCD] = useState([{ name: "", efficiency: 0 }]);
  const [apcdAdd, setApcdAdd] = useState(false);
  const [apcdTotal, setAPCDTotal] = useState(0);
  const [concentration, setConcentration] = useState(props.c);
  const [emissionEstimate, setEmissionEstimate] = useState(0);
  const [hasSulfur, setHasSulfur] = useState(false);
  const [sulfur, setSulfur] = useState("");
  const [showMap, setShowMap] = useState(false);

  const [apsiTypes, setAPSITypes] = useState(DEFAULT_APSI_TYPES);
  const [apsiType, setAPSIType] = useState("Others");

  const [coordinates, setCoordinates] = useState(
    props.utm_easting + "," + props.utm_northing
  );

  const [apsiCapacity, setApsiCapacity] = useState("");

  const [fuelRateUnit, setFuelRateUnit] = useState("Gallon");
  const [fuels, setFuels] = useState(FUELS);
  const [fuel, setFuel] = useState(FUELS[0].value);
  const [fuelUnits, setFuelUnits] = useState(["Litre", "Gallon"]);
  const [operatingHours, setOperatingHours] = useState(0);
  const [fuelRate, setFuelRate] = useState("");
  const [fuelConsumption, setFuelConsumption] = useState("");
  const [flowRate, setFlowRate] = useState(props.q);
  const [opmsPollutants, setOpmsPollutants] = useState({});

  const [apsiCapacityUnits, setApsiCapacityUnits] = useState([
    "BHP",
    "HP",
    "MW",
    "BTU/hr",
  ]);
  const [apsiCapacityUnit, setApsiCapacityUnit] = useState("BHP");
  const [pollutants, setPollutants] = useState({
    co: 0,
    nox: 0,
    pm: 0,
    sox: 0,
    voc: 0,
  });

  const [showEf, setShowEf] = useState(false);
  const [emissionFactors, setEmissionFactors] = useState(FORMAT_EF());
  const [currentEf, setCurrentEf] = useState("");

  // update monitor to parent adding
  useEffect(() => {
    props.updatePlantList(props.index, {
      apcdTotal,
      concentration,
      emissionEstimate,
      apsiType,
      coordinates,
      apsiCapacity,
      fuelRateUnit,
      fuel,
      operatingHours,
      fuelRate,
      fuelConsumption,
      flowRate,
      apsiCapacityUnit,
      apcd_breakdown: JSON.stringify(apcd),
      ...pollutants,
    });
  }, [
    apcdTotal,
    concentration,
    emissionEstimate,
    apsiType,
    coordinates,
    apsiCapacity,
    fuelRateUnit,
    fuel,
    operatingHours,
    fuelRate,
    fuelConsumption,
    flowRate,
    apsiCapacityUnit,
    apcd,
  ]);

  useEffect(() => {
    const d = apsiTypes.find((a) => a.value == apsiType);
    if (d) {
      setHasSulfur(d.has_sulfur ?? false);
      console.log("Checking Sulfur");
    }
  }, [apsiType]);
  // automatic computation

  // useEffect(() => {
  //   var data = {
  //     co: stationaryHelper.calculateCO(fuelConsumption, apcdTotal),
  //     nox: stationaryHelper.calculateNOX(fuelConsumption, apcdTotal),
  //     pm: stationaryHelper.calculatePM(fuelConsumption, apcdTotal),
  //     sox: stationaryHelper.calculateSOX(fuelConsumption, apcdTotal),
  //     voc: stationaryHelper.calculateVOC(fuelConsumption, apcdTotal),
  //   };
  //   setPollutants(data);
  // }, [fuelConsumption, apcdTotal]);

  useEffect(() => {
    // for fuel rate
    var units = EMISSION_FACTORS[fuel]?.fuel_units ?? ["Litre", "Gallon"];
    setFuelUnits(units);
    setFuelRateUnit(units[0]);

    // for APSI types if EF has equipments
    var equipments = EMISSION_FACTORS[fuel]?.equipments ?? [];
    if (equipments.length > 0) {
      var apsis = equipments.map((equipment, index) => {
        return {
          value: equipment.value,
          label: equipment.label,
          has_sulfur: equipment.has_sulfur ?? false,
        };
      });
      //jm
      // setAPSITypes(apsis);
      // setAPSIType(apsis[0]["value"]);
    } else {
      // setAPSITypes(DEFAULT_APSI_TYPES);
      // setAPSIType(DEFAULT_APSI_TYPES[0]["value"]);
    }
  }, [fuel]);

  useEffect(() => {
    // console.log("automatic calculation called");
    var currentEf = stationaryHelper.detectEf(fuel, {
      value: apsiCapacity,
      type: apsiType,
      unit: apsiCapacityUnit,
    });

    var data = {
      co: stationaryHelper.calculateCOv2(fuelConsumption, apcdTotal, fuel, {
        value: apsiCapacity,
        type: apsiType,
        unit: apsiCapacityUnit,
      }),
      nox: stationaryHelper.calculateNOXv2(fuelConsumption, apcdTotal, fuel, {
        value: apsiCapacity,
        type: apsiType,
        unit: apsiCapacityUnit,
      }),
      pm: stationaryHelper.calculatePMv2(fuelConsumption, apcdTotal, fuel, {
        value: apsiCapacity,
        type: apsiType,
        unit: apsiCapacityUnit,
      }),
      sox: stationaryHelper.calculateSOXv2(fuelConsumption, apcdTotal, fuel, {
        value: apsiCapacity,
        type: apsiType,
        unit: apsiCapacityUnit,
      }),
      voc: stationaryHelper.calculateVOCv2(fuelConsumption, apcdTotal, fuel, {
        value: apsiCapacity,
        type: apsiType,
        unit: apsiCapacityUnit,
      }),
    };
    setPollutants(data);
    setCurrentEf(currentEf);
  }, [
    apsiCapacity,
    apsiCapacityUnit,
    fuel,
    fuelConsumption,
    apcdTotal,
    apsiType,
  ]);

  useEffect(() => {
    if (!isNaN(fuelRate) && !isNaN(operatingHours)) {
      var total = fuelRate * operatingHours;
      setFuelConsumption(total);
    }
  }, [fuelRate, operatingHours]);
  useEffect(() => {
    var efficiencies = apcd.map((item) =>
      !isNaN(item.efficiency) ? parseFloat(item.efficiency) : 0
    );
    var total = efficiencies.reduce((a, b) => a + b, 0);
    if (!isNaN(total)) {
      setAPCDTotal(total);
    }

    // // if have concentration and flowrate
    // if (props.c && props.q) {
    //   let ee = stackSamplingEstimate(
    //     props.q,
    //     props.c,
    //     props["Operating Hours"],
    //     total // the apcd Total
    //   );
    //   if (!isNaN(ee)) {
    //     setEmissionEstimate(ee.toFixed(5));
    //   }
    // }
    const keys = Object.keys(opmsPollutants);
    const updated = keys.map((key) => {
      if (opmsPollutants[key]) {
        // var obj = {};
        // obj[key] = {
        //   ...opmsPollutants[key],
        //   value: stackSamplingEstimate(
        //     opmsPollutants[key].flowrate,
        //     opmsPollutants[key].concentration,
        //     operatingHours,
        //     total
        //   ),
        // };
        return {
          ...opmsPollutants[key],
          value: stackSamplingEstimate(
            opmsPollutants[key].flowrate,
            opmsPollutants[key].concentration,
            operatingHours,
            total
          ),
        };
      } else {
        return {};
      }
    });
    setOpmsPollutants({
      co: updated.find((p) => p.pollutant == "co"),
      sox: updated.find((p) => p.pollutant == "sox"),
      nox: updated.find((p) => p.pollutant == "nox"),
      pm: updated.find((p) => p.pollutant == "pm"),
      voc: updated.find((p) => p.pollutant == "voc"),
    });
  }, [apcd, operatingHours]);

  useEffect(() => {
    let defaultApsiTypes = apsiTypes.map((item) => item.value);

    // useEffect(() => {
    //   if ("apsi" in props) {
    //     let isBoiler = props["apsi"].toLowerCase().includes("boiler");
    //     console.log("----------------------------------------------");
    //   }
    // }, []);
    if ("apsi" in props) {
      //       { value: "boiler_hp", label: "Boiler" },
      // { value: "generator_kw", label: "Generator" },
      // { value: "others", label: "Others" },

      let isBoiler = props["apsi"].toLowerCase().includes("boiler");
      if (isBoiler) {
        console.log("-----------------BOILER--------------------------");
        setAPSIType("boiler_hp");
      } else if (
        [
          "other fuel burning installations",
          "other sources of air pollution",
        ].includes(props.apsi.toLowerCase())
      ) {
        console.log("-----------------GENERATOR--------------------------");
        setAPSIType("generator_kw");
      } else {
        console.log("-----------------Others--------------------------");
        setAPSIType("others");
      }
      console.log("----------------------------------------------");
      console.log("Changing the APSI TPYEEE");
      // if (!defaultApsiTypes.includes(props.apsi)) {
      //   setAPSITypes((prev) => [
      //     ...prev,
      //     { value: props.apsi, label: props.apsi },
      //   ]);
      //   setAPSIType(props.apsi);
      // } else {
      //   setAPSIType(props.apsi);
      // }
    }

    setCoordinates(props.plant_geolocation);
    if (props.apsi_capacity) {
      if (!["MW", "BHP", "BTU/HR"].includes(props.apsi_unit.toUpperCase())) {
        setApsiCapacityUnits((prev) => [
          ...new Set([...prev, props.apsi_unit.toUpperCase()]),
        ]);
        setApsiCapacityUnit(props.apsi_unit.toUpperCase());
      }
      setApsiCapacity(props.apsi_capacity);
    }
    if (props.fuel_type) {
      let apsiFuel = props.fuel_type.toLowerCase();
      let fuelKeys = fuels.map((fuel) => fuel.value);
      if (fuelKeys.includes(apsiFuel.toLowerCase())) {
        setFuel(props.fuel_type.toLowerCase());
      } else {
        setFuels((prev) => [
          ...prev,
          { value: props.fuel_type, label: props.fuel_type },
        ]);
        setFuel(props.fuel_type);
      }
    }
    setOperatingHours(props["Operating Hours"]);
    setFuelRate(props["operating_hours"]);
    setFuelConsumption(props["fuel_used"]);
    setAPCD(JSON.parse(props.apcd_breakdown?? "[]"));
    setOpmsPollutants(props.opmsPollutants);
    // setPollutants();
    let p = {
      co: props.co,
      nox: props.nox,
      pm: props.pm,
      sox: props.sox,
      voc: props.voc,
    };
    console.log("Pollutants", p);
  }, []);

  useEffect(() => {
    console.log(opmsPollutants);
  }, [opmsPollutants]);
  return (
    <>
      <Modal show={showEf} onHide={() => setShowEf(false)} size={"xl"}>
        <Modal.Header
          closeButton
          // className="ei-background"
          // closeVariant="white"
        >
          <Modal.Title>Emission Factors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Fuel</th>
                <th>Equipment</th>
                <th>PM</th>
                <th>CO</th>
                <th>NOX</th>
                <th>SOX</th>
                <th>VOC</th>
              </tr>
            </thead>
            <tbody>
              {emissionFactors.map((factor, index) => {
                var description = "";
                if ("unit" in factor && factor.min_capacity > 0) {
                  description =
                    "(" +
                    ((factor.type == "min" ? "<" : ">") +
                      " " +
                      factor.min_capacity +
                      factor.unit) +
                    ")";
                }
                return (
                  <tr
                    data-key={factor.key}
                    style={
                      currentEf == factor.key
                        ? { backgroundColor: "#19875450" }
                        : {}
                    }
                  >
                    <td style={{ textTransform: "capitalize" }}>
                      {factor.fuel} {description}
                    </td>
                    <td>{factor.equipment_name ?? "----"}</td>
                    <td className="text-end">{factor.pm}</td>
                    <td className="text-end">{factor.co}</td>
                    <td className="text-end">{factor.nox}</td>
                    <td className="text-end">{factor.sox}</td>
                    <td className="text-end">{factor.voc}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        size={"xl"}
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Plot a polygon for the given area source </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "80vh" }}>
          <div>
            <Form.Label>Coordinates</Form.Label>
            <Form.Control value={coordinates} />
          </div>

          <MapContainer
            center={[15.2033717, 120.5850848]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "80vh" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}
            <DraggableMarker callback={setCoordinates} />
          </MapContainer>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowMap(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => setShowMap(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Row
        className="mt-2"
        style={{
          border: "1px solid #2a2a2a50",
          padding: "16px",
          marginTop: "10px",
        }}
      >
        <h4 className="text-center">
          Emission Estimate based from Emission Factors
        </h4>
        <Col md={3}>
          <Form.Label className="mt-3" role="button">
            Fuel Type{" "}
            <a href="#" onClick={() => setShowEf(true)}>
              [View EF:]
            </a>
            : <Legend />
          </Form.Label>
          <Form.Select
            value={fuel}
            onChange={(e) => {
              setFuel(e.target.value);
            }}
          >
            {fuels.map((fuel, index) => (
              <option value={fuel.value}>{fuel.label}</option>
            ))}
            <optgroup>
              <opton>1</opton>
              <opton>1</opton>
            </optgroup>
          </Form.Select>
        </Col>
        <Col md={3}>
          <Form.Label className="mt-3">
            APSI Type: <Legend>Air Pollution Source Installation</Legend>
          </Form.Label>
          <Form.Select
            value={apsiType}
            onChange={(e) => {
              setAPSIType(e.target.value);
            }}
          >
            {apsiTypes.map((item) => (
              <option value={item.value}>{item.label}</option>
            ))}
          </Form.Select>
        </Col>
        <Col md={3}>
          <Form.Label className="mt-3">
            APSI Capacity:11 <Legend />
          </Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              value={apsiCapacity}
              onChange={(e) => setApsiCapacity(e.target.value)}
            />
            <DropdownButton
              // variant="outline-secondary"
              title={apsiCapacityUnit}
              id="input-group-dropdown-2"
              align="end"
              style={{ borderColor: "#ced4da" }}
            >
              {apsiCapacityUnits.map((item, index) => (
                <Dropdown.Item onClick={() => setApsiCapacityUnit(item)}>
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        </Col>
        <Col md={3}>
          <Form.Label className="mt-3">
            Coordinates: <Legend />
          </Form.Label>
          <InputGroup>
            <Form.Control
              defaultValue={coordinates}
              onChange={(e) => setCoordinates(e.target.value)}
            />

            <Button variant="success" onClick={() => setShowMap(true)}>
              <FontAwesomeIcon icon={faMapLocationDot} /> Plot
            </Button>
          </InputGroup>
        </Col>
        {/* <Col md={3}>
        <Form.Label>UTM Northing:</Form.Label>
        <Form.Control />
      </Col> */}
        <Col md={2}>
          <Form.Label className="mt-3">
            Operating Capacity: (%) <Legend />
          </Form.Label>

          <Form.Control
            type="number"
            defaultValue={fuelRate}
            onChange={(e) => setFuelRate(e.target.value)}
            placeholder="e.g. 100"
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            Fuel Rate/hour: <Legend />
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              defaultValue={fuelRate}
              onChange={(e) => setFuelRate(e.target.value)}
              placeholder="e.g. 100"
            />
            <DropdownButton
              title={fuelRateUnit}
              id="input-group-dropdown-2"
              align="end"
              style={{ borderColor: "#ced4da" }}
            >
              {fuelUnits.map((item, index) => (
                <Dropdown.Item onClick={() => setFuelRateUnit(item)} href="#">
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            Operating hours: <Legend />
          </Form.Label>
          <Form.Control
            value={operatingHours}
            onChange={(e) => setOperatingHours(e.target.value)}
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            Fuel Consumption: <Legend />
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="number"
              placeholder="e.g. 100"
              value={fuelConsumption}
              onChange={(e) => setFuelConsumption(e.target.value)}
            />
            <DropdownButton
              // variant="outline-secondary"
              title={fuelRateUnit}
              id="input-group-dropdown-2"
              align="end"
              style={{ borderColor: "#ced4da" }}
            >
              {fuelUnits.map((item, index) => (
                <Dropdown.Item onClick={() => setFuelRateUnit(item)} href="#">
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </InputGroup>
        </Col>
        {/* <Col md={2}>
          <Form.Label className="mt-3">
            Sulfur (in %): <Legend />
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              value={sulfur}
              // placeholder="e.g. 100"
            />
          </InputGroup>
        </Col> */}
        <Col md={2}>
          <Form.Label className="mt-3">
            APCD: <Legend />
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              value={apcdTotal + "%"}
              disabled
              // placeholder="e.g. 100"
            />
            <Button variant="success" onClick={() => setApcdAdd(true)}>
              <FontAwesomeIcon icon={faPlus} /> Add
            </Button>
          </InputGroup>
        </Col>
        {/* <Col md={2}>
          <Form.Label className="mt-3">
            Concentration: <Legend />
          </Form.Label>
          <Form.Control
            value={concentration}
            onChange={(e) => {
              setConcentration(e.target.value);
            }}
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            Emission Estimate: <Legend />
          </Form.Label>
          <Form.Control
            value={emissionEstimate}
            disabled
            onChange={(e) => {
              setEmissionEstimate(e.target.value);
            }}
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            Flow Rate: <Legend />
          </Form.Label>
          <Form.Control
            value={flowRate}
            onChange={(e) => setFlowRate(e.target.value)}
            // placeholder="e.g. 100"
          />
        </Col> */}
        <Col md={2}></Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            CO (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            value={pollutants.co}
            onChange={(e) =>
              setPollutants((prev) => {
                return {
                  ...prev,
                  co: e.target.value,
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            NOX (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            value={pollutants.nox}
            onChange={(e) =>
              setPollutants((prev) => {
                return {
                  ...prev,
                  nox: e.target.value,
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            PM (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            value={pollutants.pm}
            onChange={(e) =>
              setPollutants((prev) => {
                return {
                  ...prev,
                  pm: e.target.value,
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            SOx (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            value={pollutants.sox}
            onChange={(e) =>
              setPollutants((prev) => {
                return {
                  ...prev,
                  sox: e.target.value,
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-3">
            VOC (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            value={pollutants["voc"]}
            onChange={(e) =>
              setPollutants((prev) => {
                return {
                  ...prev,
                  voc: e.target.value,
                };
              })
            }
          />
        </Col>
      </Row>

      {/* Stack sampling */}

      <Row
        className="mt-2"
        style={{
          border: "1px solid #2a2a2a50",
          padding: "16px",
          marginTop: "10px",
          display: `${props.q && props.c ? "flex" : "none"}`,
        }}
      >
        <h4 className="text-center">
          Emission Estimate based from Actual Stack Sampling
        </h4>

        {/* <Col md={8}></Col> */}
        {/* <Col md={2}>
          <Form.Label className="mt-3">
            Concentration: <Legend />
          </Form.Label>
          <Form.Control
            value={concentration}
            onChange={(e) => {
              setConcentration(e.target.value);
            }}
          />
        </Col> */}
        {/* <Col md={2}>
          <Form.Label className="mt-3">
            Emission Estimate: <Legend />
          </Form.Label>
          <Form.Control
            value={emissionEstimate}
            disabled
            onChange={(e) => {
              setEmissionEstimate(e.target.value);
            }}
          />
        </Col>  */}
        {/* <Col md={2}>
          <Form.Label className="mt-3">
            Flow Rate: <Legend />
          </Form.Label>
          <Form.Control
            value={flowRate}
            onChange={(e) => setFlowRate(e.target.value)}
            // placeholder="e.g. 100"
          />
        </Col> */}
        <Col md={2}>
          <Form.Label className="mt-4">
            CO (TONS/Year):
            <Legend>
              Concentration:{" "}
              {opmsPollutants.co
                ? opmsPollutants.co.concentration + " mg/Nm3"
                : "--"}
              <br />
              VFR:{" "}
              {opmsPollutants.co
                ? opmsPollutants.co.flowrate + " m3/min"
                : "--"}
            </Legend>
          </Form.Label>
          <Form.Control
            disabled={true}
            value={opmsPollutants.co ? opmsPollutants.co.value : "--"}
            onChange={(e) =>
              setOpmsPollutants((prev) => {
                return {
                  ...prev,
                  co: {
                    ...prev["co"],
                    value: e.target.value,
                  },
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-4">
            NOX (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            disabled={true}
            value={opmsPollutants.nox ? opmsPollutants.nox.value : "--"}
            onChange={(e) =>
              setOpmsPollutants((prev) => {
                return {
                  ...prev,
                  nox: {
                    ...prev["nox"],
                    value: e.target.value,
                  },
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-4">
            PM (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            disabled={true}
            value={opmsPollutants.pm ? opmsPollutants.pm.value : "--"}
            onChange={(e) =>
              setOpmsPollutants((prev) => {
                return {
                  ...prev,
                  pm: {
                    ...prev["pm"],
                    value: e.target.value,
                  },
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-4">
            SOx (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            disabled={true}
            value={opmsPollutants.sox ? opmsPollutants.sox.value : "--"}
            onChange={(e) =>
              setOpmsPollutants((prev) => {
                return {
                  ...prev,
                  sox: {
                    ...prev["sox"],
                    value: e.target.value,
                  },
                };
              })
            }
          />
        </Col>
        <Col md={2}>
          <Form.Label className="mt-4">
            VOC (TONS/Year): <Legend />
          </Form.Label>
          <Form.Control
            disabled={true}
            value={opmsPollutants.voc ? opmsPollutants.voc.value : "--"}
            onChange={(e) =>
              setOpmsPollutants((prev) => {
                return {
                  ...prev,
                  voc: {
                    ...prev["voc"],
                    value: e.target.value,
                  },
                };
              })
            }
          />
        </Col>
        <Col md={12} className="mt-3">
          <center>
            <i>
              <span style={{ borderBottom: "2px solid black" }}>
                E = (.00006)(VFR)(Conc.)(AOH) (1-ER/100)
              </span>
              <h6>1000</h6>
              <table style={{ fontSize: "16px" }}>
                <tr>
                  <td>E = Emission Estimation (TPY)</td>
                </tr>
                <tr>
                  <td>AOH= Ave. Annual Operating Hour</td>
                </tr>
                <tr>
                  <td>VFR = Volumetric Flow Rate (Ncm/min)</td>
                </tr>
                <tr>
                  <td>0.00006 =Conversion mg-kg & min-hr</td>
                </tr>
                <tr>
                  <td>1000=Conversion kg - tons</td>
                </tr>
              </table>
            </i>
          </center>
        </Col>
      </Row>

      <Modal
        show={apcdAdd}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          // check if apcd total is greater than 100
          if (apcdTotal > 100) {
            swal({
              text: "The total APCD efficiency exceeds 100%. Please check the APCD entered.",
              icon: "warning",
              // buttons: true,
              dangerMode: true,
            });
            return;
          }
          setApcdAdd(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add/View APCD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <td>Name</td>
                <td>Efficiency</td>
                <td>Actions</td>
              </tr>
            </thead>
            <tbody>
              {apcd.map((item, index) => {
                return (
                  <tr>
                    <td width="57%">
                      <Form.Control
                        defaultValue={item.name}
                        onChange={(e) => {
                          setAPCD((prev) => {
                            let copy = [...prev];
                            copy[index]["name"] = e.target.value;
                            return copy;
                          });
                        }}
                      />
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          defaultValue={item.efficiency}
                          onChange={(e) => {
                            setAPCD((prev) => {
                              let copy = [...prev];
                              copy[index]["efficiency"] = e.target.value;
                              return copy;
                            });
                          }}
                        />
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup>
                    </td>
                    <td className="text-end">
                      <Button
                        variant="danger"
                        onClick={() => {
                          setAPCD((prev) => {
                            let filtered = apcd.filter((a, i) => i !== index);
                            return filtered;
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3} className="text-center">
                  <Button
                    onClick={() => {
                      setAPCD((prev) => [...prev, { name: "", efficiency: 0 }]);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Line
                  </Button>
                </td>
              </tr>
            </tfoot>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditPlantForm;
