import {
  Table,
  Button,
  Container,
  Row,
  Col,
  Stack,
  Form,
} from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faDownload,
  faEllipsisV,
  faPlus,
  faPrint,
  faSearch,
  faSpinner,
  faClock,
  faCheck,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import RecordActions from "../components/RecordActions";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import ModuleContainer from "../components/ModuleContainer";
import AddArea from "../components/AddArea";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import Select from "react-select";
import DataTable from "react-data-table-component";
import ImportArea from "../components/ImportArea";
import ExcelExport from "../components/ExcelExport";
import RejectionBatchModal from "./approval-components/RejectionBatchModal";
import StatusPill from "../components/StatusPill";
import { CURRENT_LABELS } from "../utilities/approval";
import ApproveBatchButton from "./approval-components/ApprovaBatchButton";
import moment from "moment";
import helper from "../utilities/helper";
import ColumnFilter from "../components/ColumnFilter";
const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");

const YEARS = [...Array(20).keys()].map((item) => {
  return {
    label: 2023 - item,
    value: 2023 - item,
  };
});

// alert(helper.isPreparer());
const AreaSources = () => {
  const isPreparer = helper.isPreparer();
  const [selectedRows, setSelectedRows] = useState([]);
  const [region, setRegion] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [loaded, setLoaded] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [year, setYear] = useState(YEARS[0]);
  const [currentData, setCurrentData] = useState([]);
  const [isPulling, setIsPulling] = useState(false);

  const [downloadData, setDownloadData] = useState([]);
  // column filter
  const [columns, setColums] = useState({
    date_added: {
      omit: false,
      label: "Date Added",
    },
    province: {
      omit: false,
      label: "Province",
    },
    city: {
      omit: false,
      label: "City",
    },
    co: {
      omit: false,
      label: "CO",
    },
    nox: {
      omit: false,
      label: "NOX",
    },
    pm: {
      omit: false,
      label: "PM",
    },
    sox: {
      omit: false,
      label: "SOX",
    },
    voc: {
      omit: false,
      label: "VOC",
    },
    total: {
      omit: false,
      label: "Total",
    },
  });

  const handleCloseAdd = (prompt = true) => {
    if (prompt) {
      swal({
        title: "Are you sure to quit adding?",
        text: "The details that you have entered will be lost",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setShowAdd(false);
        } else {
          // nothing to do for now
        }
      });
    } else {
      setShowAdd(false);
    }
  };
  const handleCloseImport = (prompt = true) => {
    if (prompt) {
      swal({
        title: "Are you sure to quit importing?",
        text: "The details that you have entered will be lost",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setShowImport(false);
        } else {
          // nothing to do for now
        }
      });
    } else {
      setShowImport(false);
    }
  };
  const handleShowAdd = () => setShowAdd(true);
  const handleShowImport = () => setShowImport(true);
  const localRemove = (id) => {
    let data = currentData.filter((d) => d.id != id);
    setCurrentData(data);
  };
  const getFilteredData = () => {
    setIsPulling(true);
    fetch(CONSTANTS.API_ROOT + "/area/getFilterData", {
      method: "POST",
      body: JSON.stringify({
        year: year.value,
        region: credentials.region_sess,
        province: selectedProvince.value,
        city: selectedCity.value,
        ...credentials,
      }),
    })
      .then(async (data) => {
        const list = await data.json();
        // console.log(list);
        setCurrentData(list);
        setIsPulling(false);

        let downloadable = list.map((item) => {
          return {
            Year: item["year"],
            Province: item["province"],
            City: item["city"],
            Type: item['type_area_source'],
            'Activity Rate' : item['activity_rate'],
            CO: item["co"],
            NOX: item["nox"],
            PM: item["pm"],
            SOX: item["sox"],
            VOC: item["voc"],
            TOTAL: item["total"],
            "Date Added": item["date_added"],
          };
        });
        setDownloadData(downloadable);
      })
      .catch((error) => {
        console.log(error);
        setIsPulling(false);
      });
  };

  useEffect(() => {
    console.log(showImport);
  }, [showImport]);
  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // console.log("REG CODE: :" + regionCode);
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    setProvinces([{ label: "All", value: "" }, ...formattedProvinces]);
    setSelectedProvince({ label: "All", value: "" });
    setSelectedCity({ label: "All", value: "" });
  }, [region]);

  useEffect(() => {
    // console.log(selectedProvince);
    let provinceDetails = PROVINCES.find(
      (p) => p?.provDesc == selectedProvince?.value
    );
    if (!provinceDetails) {
      return;
    }
    // console.log("PORovinde details: ");
    // console.log(provinceDetails);
    let provinceCode = provinceDetails["provCode"];
    let cities = CITIES.filter((c) => c.provCode === provinceCode);
    let formattedCities = cities.map((city) => {
      return {
        value: city["citymunDesc"],
        label: city["citymunDesc"],
      };
    });
    setCities([{ label: "All", value: "" }, ...formattedCities]);
    setSelectedCity({ label: "All", value: "" });
  }, [selectedProvince]);

  useEffect(() => {
    getFilteredData();
  }, [selectedCity]);

  useEffect(() => {
    if (!Object.keys(credentials).includes("region_sess")) {
      // console.log(Object.keys(credentials));
      navigate("/");
    } else {
      const finalRegion = ["NCR", "CAR", "ARMM"].includes(
        credentials.region_sess
      )
        ? credentials.region_sess
        : `Region ${credentials.region_sess}`;
      setRegion(finalRegion);
      // setSelectedProvince({
      //   value: finalRegion,
      //   label: finalRegion,
      // });
      setLoaded(true);
      // console.log(credentials);
    }
  }, []);
  return (
    <>
      <ModuleContainer region={"National Capital Region"}>
        <Row>
          <Col md={4}>
            <h5>Area Sources</h5>
          </Col>
          <Col md={8} className="module-actions">
            <Stack direction="horizontal" gap={2} className="float-end">
              {isPreparer && (
                <Button variant="success" onClick={handleShowAdd}>
                  <FontAwesomeIcon icon={faPlus} />
                  <span className="ms-1">Add Area Source</span>
                </Button>
              )}
              <Button variant="success" disabled>
                <FontAwesomeIcon icon={faCopy} />
                <span className="ms-1">Duplicate</span>
              </Button>

              <ExcelExport
                data={downloadData}
                filename={`${region}-${selectedProvince.value}-${year.value}-area-sources.csv`}
              />
              {isPreparer && (
                <Button variant="success" onClick={handleShowImport}>
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="ms-1">Upload</span>
                </Button>
              )}
              {/* <Button variant="success" disabled>
                <FontAwesomeIcon icon={faPrint} />
                <span className="ms-1">Print</span>
              </Button> */}
            </Stack>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Stack
              direction="horizontal"
              gap={3}
              style={{
                alignItems: "center",
              }}
            >
              <Form.Label>Year:</Form.Label>
              <div style={{ width: "150px" }}>
                <Select
                  options={YEARS}
                  value={year}
                  onChange={(e) => {
                    setYear(e);
                  }}
                />
              </div>
              <Form.Label>Province:</Form.Label>
              <div style={{ width: "150px" }}>
                <Select
                  options={provinces}
                  value={selectedProvince}
                  onChange={(e) => {
                    setSelectedProvince(e);
                  }}
                />
              </div>
              <Form.Label>City/Municipality:</Form.Label>
              <div style={{ width: "200px" }}>
                <Select
                  options={cities}
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e)}
                />
              </div>
              <Button variant="success" onClick={getFilteredData}>
                <FontAwesomeIcon icon={faSearch} /> Search
              </Button>
              <div style={{ marginLeft: "auto" }}>
                <ApproveBatchButton
                  disabled={selectedRows.length == 0}
                  selectedRows={selectedRows}
                  source="area"
                  refresher={getFilteredData}
                />
                <RejectionBatchModal
                  disabled={selectedRows.length == 0}
                  selectedRows={selectedRows}
                  source="area"
                  refresher={getFilteredData}
                />
                <ColumnFilter setColumns={setColums} columns={columns} />
              </div>
            </Stack>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="pt-2">
            {isPulling ? (
              <div style={{ textAlign: "center", marginTop: "10%" }}>
                <FontAwesomeIcon
                  className="fa-spin me-3"
                  icon={faSpinner}
                  style={{ fontSize: 30 }}
                />
                <h6>Please wait...</h6>
              </div>
            ) : (
              <DataTable
                customStyles={{ rows: { style: { minHeight: "80px" } } }}
                onSelectedRowsChange={({ selectedRows }) => {
                  console.log("Selection", selectedRows);
                  setSelectedRows(selectedRows);
                }}
                selectableRows={true}
                noDataComponent={
                  <div>
                    <img
                      src="https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=2000"
                      style={{ width: "500px", height: "500px" }}
                    />
                  </div>
                }
                columns={[
                  {
                    omit: columns.date_added.omit,
                    name: "Date Added",
                    selector: (row) => row.date_added,
                    sortable: true,
                    cell: (row) => {
                      return moment(row.date_added).format("MM/DD/YYYY h:mm a");
                    },
                  },
                  {
                    omit: columns.province.omit,
                    name: "Province",
                    selector: (row) => row.province,
                    sortable: true,
                    // width: "350px",
                  },
                  {
                    omit: columns.city.omit,
                    name: "City/Municipality",
                    selector: (row) => row.city,
                    sortable: true,
                    // width: "300px",
                  },
                  {
                    omit: columns.co.omit,
                    name: "CO",
                    selector: (row) => row.co,
                    sortable: true,
                  },
                  {
                    omit: columns.nox.omit,
                    name: "NOX",
                    selector: (row) => row.nox,
                    sortable: true,
                  },
                  {
                    omit: columns.pm.omit,
                    name: "PM",
                    selector: (row) => row.pm,
                    sortable: true,
                  },
                  {
                    omit: columns.sox.omit,
                    name: "SOX",
                    selector: (row) => row.sox,
                    sortable: true,
                  },
                  {
                    omit: columns.voc.omit,
                    name: "VOC",
                    selector: (row) => row.voc,
                    sortable: true,
                  },
                  {
                    omit: columns.total.omit,
                    name: "Total Emission (Tons/Year)",
                    selector: (row) => row.total,
                    sortable: true,
                  },
                  {
                    name: "Regional",
                    selector: (row) => row.status_2,
                    sortable: true,
                    cell: (row) => {
                      // try json
                      if (!row.regional_status) {
                        return (
                          <StatusPill
                            status="prepared"
                            id={row.id}
                            source="area"
                          />
                        );
                      }
                      if (row.regional_status.length > 20) {
                        var status;
                        try {
                          var statusDetails = JSON.parse(row.regional_status);
                          status = statusDetails["status"];
                        } catch (error) {
                          status = "prepared";
                        }
                        return (
                          <StatusPill
                            status={CURRENT_LABELS[status]}
                            id={row.id}
                            source="area"
                          />
                        );
                      } else {
                        return (
                          <StatusPill
                            status={CURRENT_LABELS[row.regional_status]}
                            id={row.id}
                            source="area"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "Central",
                    selector: (row) => row.status_3,
                    sortable: true,
                    cell: (row) => {
                      // try json
                      if (!row.central_status) {
                        return (
                          <StatusPill
                            status="awaiting"
                            id={row.id}
                            source="area"
                          />
                        );
                      }
                      if (row.regional_status.length > 20) {
                        var status;
                        try {
                          var statusDetails = JSON.parse(row.central_status);
                          status = statusDetails["nextFlag"];
                        } catch (error) {
                          status = "awaiting";
                        }
                        return (
                          <StatusPill
                            status={CURRENT_LABELS[status]}
                            id={row.id}
                            source="area"
                          />
                        );
                      } else {
                        return (
                          <StatusPill
                            status={CURRENT_LABELS[row.central_status]}
                            id={row.id}
                            source="area"
                          />
                        );
                      }
                    },
                  },
                  {
                    name: "",
                    cell: (row) => (
                      <RecordActions
                        source={"area"}
                        {...row}
                        rowData={row}
                        localRemove={localRemove}
                        setShowEdit={setShowEdit}
                        setEditData={setEditData}
                        updateTable={getFilteredData}
                      />
                    ),
                    right: true,
                  },
                ]}
                data={currentData}
              />
            )}
          </Col>
        </Row>
      </ModuleContainer>
      <AddArea
        handleClose={handleCloseAdd}
        show={showAdd}
        region={credentials.region_sess}
        updateTable={getFilteredData}
      />
      <ImportArea
        handleClose={handleCloseImport}
        show={showImport}
        region={credentials.region_sess}
        updateTable={getFilteredData}
      />
    </>
  );
};

export default AreaSources;
