import {
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCircleInfo,
  faClock,
  faClose,
  faSpinner,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ProgressBar,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Legend from "../components/Legend";
import ModuleContainer from "../components/ModuleContainer";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import approvalHelper, { FLAG_APPROVER } from "../utilities/approval";
import CONSTANTS from "../utilities/constants";
import ApprovalBanner from "./approval-components/ApprovalBanner";
import ApprovalProgress from "./approval-components/ApprovalProgress";
import ApproveButton from "./approval-components/ApproveButton";
import RejectionModal from "./approval-components/RejectionModal";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import DraggableMarker from "../components/DraggableMarker";
import FixedMarker from "../components/FixedMarker";
import BackButton from "../components/BackButton";
const StationaryView = () => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  let { id } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [stationaryData, setStationaryData] = useState([]);
  const [generalData, setGeneralData] = useState({});
  const [loading, setLoading] = useState(true);
  const getMobileData = async () => {
    let response = await fetch(CONSTANTS.API_ROOT + "/stationary/get/" + id, {
      method: "POST",
      body: JSON.stringify({
        credentials,
      }),
    });
    let data = await response.json();
    const plants = [...data.plants];

    const apsis = plants.map((apsi) => {
      return apsi.apsi[0];
    });
    setStationaryData(apsis);
    setGeneralData(data["general_data"][0]);
    setLoading(false);
  };
  useEffect(() => {
    getMobileData();
  }, []);
  return (
    <ModuleContainer region={"National Capital Region"}>
      <Container fluid>
        <BackButton />
        <ApprovalBanner id={id} source="stationary" refresher={forceUpdate} />
        <Row>
          <Col md={9}>
            <Card style={{ minHeight: "700px" }}>
              <Card.Header>
                <h5> Stationary Source Preview</h5>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <Row>
                    <Col className="text-center">
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <Form.Label>
                                  Year: <b>{generalData["year"]} </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  PTO #:
                                  <b>
                                    {generalData.pto_number
                                      ? generalData.pto_number
                                      : "  --"}
                                  </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  Province: <b>{generalData["province"]} </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  City: <b>{generalData["city"]} </b>
                                </Form.Label>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Form.Label>
                                  Barangay: <b>{generalData["barangay"]} </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  Company Name: <b>{generalData["company"]} </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  Address: <b>{generalData["address"]} </b>
                                </Form.Label>
                              </td>
                              <td>
                                <Form.Label>
                                  Industry:{" "}
                                  <b>{generalData["industry_type"]} </b>
                                </Form.Label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    {stationaryData.map((stationary, index) => {
                      return <StationaryCard {...stationary} index={index} />;
                    })}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <ApprovalProgress
              id={id}
              source="stationary"
              refresher={forceUpdate}
            />
          </Col>
        </Row>
      </Container>
    </ModuleContainer>
  );
};

const StationaryCard = (props) => {
  console.log("Cards Props", props);
  var utm;
  if (props.utm) {
    utm = props.utm.replace('"', "").replace('"', "");
  } else {
    utm = `${props.utm_easting},${props.utm_northing}`;
  }
  return (
    <Accordion defaultActiveKey={props.index} className="mt-3">
      <Accordion.Item eventKey={props.index}>
        <Accordion.Header>
          {"Stationary Source " + parseInt(props.index + 1)}
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Fuel Type</th>
                    <th>APSI Type</th>
                    <th className="text-end">APSI Capacity</th>
                    <th className="text-end">Operating Capacity</th>
                    <th className="text-end">Fuel Rate/Hr</th>
                    <th className="text-end">Fuel Consumption</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-capitalize">{props.fuel_type}</td>
                    <td className="text-capitalize">{props.apsi_type}</td>
                    <td className="text-end">
                      {props.apsi_capacity} <i>{props.apsi_unit}</i>
                    </td>
                    <td className="text-end">
                      {props.operating_capacity
                        ? props.operating_capacity
                        : "--"}
                    </td>
                    <td className="text-end">{props.fuel_rate}</td>
                    <td className="text-end">
                      {props.fuel_used} <i>{props.fuel_used_unit}</i>
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th colSpan={4} className="text-end">
                      Operating Hours
                    </th>
                    <th className="text-end">APCD</th>
                    <th className="text-end">View Map</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={4} className="text-end">
                      {props.operating_hours}
                    </td>
                    <td className="text-end">{props.apcd_total}%</td>
                    <td className="text-end">
                      <MapButton utm={utm} />
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th colSpan={6}>Pollutants</th>
                  </tr>
                  <tr>
                    <th className="text-end">CO (Tons/Year)</th>
                    <th className="text-end">NOX (Tons/Year)</th>
                    <th className="text-end">PM (Tons/Year)</th>
                    <th className="text-end">SOX (Tons/Year)</th>
                    <th className="text-end">TOG (Tons/Year)</th>
                    <th className="text-end">Total (Tons/Year)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-end">{props.co}</td>
                    <td className="text-end">{props.nox}</td>
                    <td className="text-end">{props.pm}</td>
                    <td className="text-end">{props.sox}</td>
                    <td className="text-end">{props.voc}</td>
                    <td className="text-end">
                      {(
                        parseFloat(props.co ?? 0) +
                        parseFloat(props.nox ?? 0) +
                        parseFloat(props.pm ?? 0) +
                        parseFloat(props.sox ?? 0) +
                        parseFloat(props.voc ?? 0)
                      ).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
const MapButton = (props) => {
  const [showMap, setShowMap] = useState(false);
  const [east, north] = props.utm.split(",");
  console.log("EASTING", east, north);
  return (
    <>
      <Button onClick={() => setShowMap(true)}>Show Map</Button>
      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        size={"xl"}
      >
        {" "}
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Stationary Source Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "80vh" }}>
          <MapContainer
            center={[east, north]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "80vh" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[east, north]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}
            <FixedMarker east={east} north={north} />
          </MapContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default StationaryView;
