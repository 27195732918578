import { faFileInvoice, faParagraph } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";

const ViewMobileEF = (props) => {
  const [show, setShow] = useState(false);
  const [emissionFactorData, setEmissionFactorData] = useState(
    JSON.parse(props.emission_factors)
  );

  return (
    <>
      <Button onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faFileInvoice} />
        <span> View</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            View Emission Factor &mdash; {props.source_type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Label>Source Name:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Car / Specific Model"
                    value={props.source_type}
                    disabled
                  />
                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                {Object.keys(emissionFactorData).map((ef, index) => {
                  let details = emissionFactorData[ef];
                  return (
                    <Table>
                      <thead>
                        <tr>
                          <th colSpan={7}>{details["description"]}</th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Fuel</th>
                          <th>CO</th>
                          <th>NOX</th>
                          <th>PM</th>
                          <th>SOX</th>
                          <th>VOC</th>
                          <th>TOG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {"fuels" in details ? (
                          <>
                            {Object.keys(details["fuels"]).map((fuelKey) => {
                              return (
                                <tr>
                                  <td
                                    width="150px"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {fuelKey}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "co"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "nox"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "pm"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "sox"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "voc"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                  <td>
                                    {parseFloat(
                                      emissionFactorData[ef]["fuels"][fuelKey][
                                        "tog"
                                      ]
                                    ).toFixed(2)}
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td width="150px">
                              --
                            </td>
                            <td>
                              {!("co" in details)
                                ? "--"
                                : emissionFactorData[ef]["co"]}
                            </td>
                            <td>
                              {!("nox" in details)
                                ? "--"
                                : emissionFactorData[ef]["nox"]}
                            </td>
                            <td>
                              {!("pm" in details)
                                ? "--"
                                : emissionFactorData[ef]["pm"]}
                            </td>
                            <td>
                              {!("sox" in details)
                                ? "--"
                                : emissionFactorData[ef]["sox"]}
                            </td>
                            <td>
                              {!("voc" in details)
                                ? "--"
                                : emissionFactorData[ef]["voc"]}
                            </td>
                            <td>
                              {!("tog" in details)
                                ? "--"
                                : emissionFactorData[ef]["tog"].toFixed(2)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewMobileEF;
