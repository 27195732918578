import {
  Accordion,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
  Modal,
  InputGroup,
  Button,
} from "react-bootstrap";
import helper from "../utilities/helper";
import Select from "react-select";
import { useEffect, useState } from "react";
import PlantForm from "./PlantForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  faEdit,
  faInfoCircle,
  faMapLocation,
  faMapLocationDot,
  faPlug,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import areaHelper from "../utilities/area";
import mobileHelper from "../utilities/mobile";
import Legend from "./Legend";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";

const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");

const DEFAULT_REGION = "NCR"; // hardcoded for now

const VEHICLE_TYPES = [
  { label: "Cars", value: "cars" },
  { label: "Utility Vehicle", value: "uv" },
  { label: "Trucks", value: "trucks" },
  { label: "Buses", value: "buses" },
  { label: "Motorcycle/Tricycle", value: "mc_tc" },
];

const FUEL_TYPES = [
  { label: "Gasoline", value: "gas" },
  { label: "Diesel", value: "diesel" },
];
const EditMobile = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const finalRegion = ["NCR", "CAR", "ARMM"].includes(props.region)
    ? props.region
    : `Region ${props.region}`;
  const [region, setRegion] = useState(finalRegion);

  const [selectedProvince, setSelectedProvince] = useState({
    value: props.region,
    label: props.region,
  });
  const [mobileSources, setMobileSources] = useState([]);
  const [year, setYear] = useState(props.rowData.year);

  const [provinces, setProvinces] = useState([]);

  const updateMobileList = (index, data) => {
    let copies = [...mobileSources];
    copies[index] = { ...copies[index], ...data };
    setMobileSources(copies);
  };

  const getMobileSources = async () => {
    const resp = await fetch(
      CONSTANTS.API_ROOT + "/mobile/getSources/" + props.rowData.id
    );
    const data = await resp.json();
    setMobileSources(data);
    setLoading(false);
  };

  useEffect(() => {
    console.log(mobileSources);
  }, [mobileSources]);

  const updateMobileSources = () => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to update these Mobile Sources?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        let generalInfo = {
          year,
          province: selectedProvince["value"],
          region,
        };
        fetch(CONSTANTS.API_ROOT + "/mobile/editaction/" + props.rowData.id, {
          method: "POST",
          body: JSON.stringify({ mobileSources, generalInfo, credentials }),
        })
          .then(async (data) => {
            // const list = await data.json();
            //  // console.log(list);
            props.updateTable();
            setShow(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // nothing to do for now
      }
    });
  };

  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // console.log("REG CODE: :" + regionCode);
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    let currentProvince = formattedProvinces.find(
      (p) => p.value == props.rowData.province
    );
    setSelectedProvince(currentProvince);
  }, [region]);

  useEffect(() => {
    getMobileSources();
  }, []);
  return (
    <>
      <Button
        variant="info"
        className="text-white d-block"
        onClick={() => {
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <span className="ms-2">Edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        fullscreen={true}
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Edit Mobile Source &mdash; {props.region} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Card style={{ minHeight: "80vh" }}>
                <Card.Body>
                  <Card.Title>General Details</Card.Title>
                  <hr />
                  <Form.Label>Year:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="e.g. 2022"
                    max={2020}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />
                  <Form.Label className="mt-3">Province:</Form.Label>
                  <Select
                    options={provinces}
                    value={selectedProvince}
                    onChange={(e) => {
                      setSelectedProvince(e);
                    }}
                  />
                  {/* <Form.Label className="mt-3">City/Municipality:</Form.Label>
                <Select options={cities} value={selectedCity} /> */}
                  {/* <Form.Label className="mt-3">Barangay:</Form.Label>
                <Form.Control
                  placeholder="e.g. Project 6*"
                  value={barangay}
                  onChange={(e) => {
                    setBarangay(e.target.value);
                  }}
                /> */}
                </Card.Body>
              </Card>
            </Col>
            <Col xs={9}>
              <Card style={{ height: "80vh", overflowY: "scroll" }}>
                <Card.Body>
                  <Card.Title>Mobile Sources List</Card.Title>
                  <hr style={{ borderBottom: "1px solid #2A3F54" }} />
                  {loading && (
                    <center>
                      <FontAwesomeIcon
                        className="fa-spin me-3 mt-5"
                        icon={faSpinner}
                        style={{ fontSize: 30 }}
                      />
                      <div>Please wait...</div>
                    </center>
                  )}
                  <Stack>
                    {mobileSources.map((item, index) => {
                      return (
                        <MobileSourceForm
                          {...item}
                          index={index}
                          updateMobileList={updateMobileList}
                        />
                      );
                    })}
                  </Stack>
                  <Button
                    variant="success"
                    className="mt-4"
                    onClick={() => {
                      setMobileSources((prev) => [...prev, {}]);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add Line
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={updateMobileSources}>
            Update Sources
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const MobileSourceForm = (props) => {
  console.log(props);
  const [vehicleType, setVehicleType] = useState(
    VEHICLE_TYPES.find((v) => v.value == props.vehicle_type)
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    parseInt(props.no_vehicle)
  );
  const [fuelType, setFuelType] = useState(
    FUEL_TYPES.find((f) => f.value == props.fuel_type)
  );
  const [vkt, setVkt] = useState(parseInt(props.vkt)); //km travelled
  const [sulfurContent, setSulfurContent] = useState(
    parseFloat(props.percent_sulfur)
  ); // in percent
  const [trips, setTrips] = useState(props.trip);
  const [days, setDays] = useState(props.days_of_operation ?? 0);
  const [fuelConsumption, setFuelConsumption] = useState(
    props.fuel_consumption ?? 0
  );
  const [pollutants, setPollutants] = useState({
    co: props.co,
    nox: props.nox,
    pm: props.pm,
    sox: props.sox,
    tog: props.tog,
  });

  useEffect(() => {
    var data = {
      vehicle_type: vehicleType["value"],
      fuel_type: fuelType["value"],
      trip: trips,
      days_of_operation: days,
      no_vehicle: vehicleNumber,
      vkt,
      fuel_consumption: fuelConsumption,
      percent_sulfur: sulfurContent,
      ...pollutants,
    };

    props.updateMobileList(props.index, data);
  }, [
    vehicleType,
    fuelType,
    trips,
    days,
    vehicleNumber,
    vkt,
    fuelConsumption,
    sulfurContent,
    pollutants,
  ]);
  useEffect(() => {
    const computation = mobileHelper.calculateMobile(
      vehicleType["value"],
      fuelType["value"],
      trips,
      days,
      vehicleNumber,
      vkt,
      fuelConsumption,
      sulfurContent
    );
    setPollutants({
      ...computation["RESULTS"],
      pm:
        parseFloat(computation["RESULTS"]["pm10"]) +
        parseFloat(computation["RESULTS"]["pmex"]) +
        parseFloat(computation["RESULTS"]["pmtw"]),
      sox: computation["SOX"],
    });
  }, [
    vehicleType,
    vehicleNumber,
    fuelType,
    vkt,
    sulfurContent,
    trips,
    fuelConsumption,
    days,
  ]);

  return (
    <>
      <Accordion defaultActiveKey={props.index} className="mt-3">
        <Accordion.Item eventKey={props.index}>
          <Accordion.Header>
            {"Mobile Source " + parseInt(props.index + 1)}
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={2}>
                <Form.Label className="mt-3">Vehicle Type</Form.Label>
                <Select
                  options={VEHICLE_TYPES}
                  value={vehicleType}
                  onChange={(e) => {
                    console.log("NEW", e);
                    setVehicleType(e);
                  }}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3"># of Vehicles</Form.Label>
                <Form.Control
                  type="number"
                  value={vehicleNumber}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setVehicleNumber(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">Fuel Type</Form.Label>
                <Select
                  options={FUEL_TYPES}
                  value={fuelType}
                  onChange={(e) => {
                    setFuelType(e);
                  }}
                />
              </Col>
              <Col md={1}>
                <Form.Label className="mt-3">
                  VKT{" "}
                  <Legend>
                    The total kilometered travelled by the vehicle.
                  </Legend>
                </Form.Label>
                <Form.Control
                  type="number"
                  value={vkt}
                  onChange={(e) => {
                    setVkt(e.target.value);
                  }}
                />
              </Col>
              <Col md={1}>
                <Form.Label className="mt-3">Sulfur (%)</Form.Label>
                <Form.Control
                  placeholder="e.g. 0.03"
                  type="number"
                  value={sulfurContent}
                  onChange={(e) => {
                    setSulfurContent(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">Trip per Day</Form.Label>
                <Form.Control
                  type="number"
                  value={trips}
                  onChange={(e) => {
                    setTrips(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">Days of Operation</Form.Label>
                <Form.Control
                  type="number"
                  value={days}
                  onChange={(e) => {
                    setDays(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <Form.Label className="mt-3">Fuel (Gallons):</Form.Label>
                <Form.Control
                  type="number"
                  value={fuelConsumption}
                  onChange={(e) => {
                    setFuelConsumption(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Form.Label className="mt-3">CO (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.co}
                  style={{ color: "green" }}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        co: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">NOX (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.nox}
                  style={{ color: "green" }}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        nox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">PM (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.pm}
                  style={{ color: "green" }}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        pm: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">SOx (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.sox}
                  style={{ color: "green" }}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        sox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">TOG (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants["tog"]}
                  style={{ color: "green" }}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        voc: e.target.value,
                      };
                    })
                  }
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
export default EditMobile;
