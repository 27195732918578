import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import Header from "./Header";
import Sidebar from "./Sidebar";

const ModuleContainer = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [loaded, setLoaded] = useState(true);
  if (!loaded) {
    return (
      <div
        className="container-fluid p-0"
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FontAwesomeIcon
          className="fa-spin me-3"
          icon={faSpinner}
          style={{ fontSize: 30 }}
        />
        <h4>Please wait...</h4>
      </div>
    );
  }
  return (
    <div className="container-fluid p-0">
      <Header
        region={credentials.region_sess}
        username={credentials.user_fullname}
      />
      <div className="d-flex flex-row" style={{ minHeight: "100vh" }}>
        <Sidebar />
        <div
          style={{
            backgroundColor: "#F7F7F7",
            flex: 1,
            padding: "10px",
            width: "100%",
          }}
        >
          <Container
            fluid
            style={{
              backgroundColor: "white",
              minHeight: "100vh",
              padding: 16,
            }}
          >
            {props.children}
          </Container>
        </div>
      </div>
    </div>
  );
};
export default ModuleContainer;
