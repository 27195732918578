import {
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCircleInfo,
  faClock,
  faClose,
  faSpinner,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  ProgressBar,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Legend from "../components/Legend";
import ModuleContainer from "../components/ModuleContainer";
import BackButton from "../components/BackButton";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import approvalHelper, { FLAG_APPROVER } from "../utilities/approval";
import CONSTANTS from "../utilities/constants";
import ApprovalBanner from "./approval-components/ApprovalBanner";
import ApprovalProgress from "./approval-components/ApprovalProgress";
import ApproveButton from "./approval-components/ApproveButton";
import RejectionModal from "./approval-components/RejectionModal";
import * as L from "leaflet";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import DraggableMarker from "../components/DraggableMarker";
const AreaSourcesView = () => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  let { id } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [areaData, setAreaData] = useState({});
  const [loading, setLoading] = useState(true);
  const getMobileData = async () => {
    let response = await fetch(CONSTANTS.API_ROOT + "/area/get/" + id, {
      method: "POST",
      body: JSON.stringify({
        credentials,
      }),
    });
    let data = await response.json();
    console.log(data);
    setAreaData(data);
    setLoading(false);
  };
  useEffect(() => {
    getMobileData();
  }, []);
  return (
    <ModuleContainer region={"National Capital Region"}>
      <Container fluid>
        <BackButton/>
        <ApprovalBanner id={id} source="area" refresher={forceUpdate} />
        <Row>
          <Col md={9}>
            <Card style={{ minHeight: "700px" }}>
              <Card.Header>
                <h5> Area Source Preview</h5>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <Row>
                    <Col className="text-center">
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col md={3}>
                        <Form.Label>
                          Year : <b>{areaData["general_data"][0]["year"]}</b>
                        </Form.Label>
                      </Col>
                      <Col md={3}>
                        <Form.Label>
                          Province:
                          <b> {areaData["general_data"][0]["province"]}</b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <hr />
                    {areaData.area_source.map((area, index) => {
                      return <AreaCard {...area} index={index} />;
                    })}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <ApprovalProgress id={id} source="area" refresher={forceUpdate} />
          </Col>
        </Row>
      </Container>
    </ModuleContainer>
  );
};

const AreaCard = (props) => {
  var utm;
  if (props.utm) {
    utm = props.utm.replace('"', "").replace('"', "");
  } else {
    utm = `${props.utm_easting},${props.utm_northing}`;
  }
  return (
    <Accordion defaultActiveKey={props.index} className="mt-3">
      <Accordion.Item eventKey={props.index}>
        <Accordion.Header>
          {"Area Source " + parseInt(props.index + 1)}
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Source Type</th>
                    <th>Fuel</th>
                    <th>Activity Rate</th>
                    <th>Activity Duration</th>
                    <th>Address</th>
                    <th>UTM</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-capitalize">
                      {props.type_area_source}
                    </td>
                    <td>{"--"}</td>
                    <td className="text-capitalize">{props.activity_rate}</td>
                    <td>
                      {props.duration_in_monhts
                        ? props.duration_in_monhts
                        : "--"}
                    </td>
                    <td>{props.address}</td>
                    <td>
                      <MapButton utm={utm} geojson={props.geojson} />
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th colSpan={6}>Pollutants</th>
                  </tr>
                  <tr>
                    <th>CO (Tons/Year)</th>
                    <th>NOX (Tons/Year)</th>
                    <th>PM (Tons/Year)</th>
                    <th>SOX (Tons/Year)</th>
                    <th>TOG (Tons/Year)</th>
                    <th>Total (Tons/Year)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{props.co}</td>
                    <td>{props.nox}</td>
                    <td>{props.pm}</td>
                    <td>{props.sox}</td>
                    <td>{props.voc}</td>
                    <td>
                      {parseFloat(props.co) +
                        parseFloat(props.nox) +
                        parseFloat(props.pm) +
                        parseFloat(props.sox) +
                        parseFloat(props.voc)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
const MapButton = (props) => {
  const [showMap, setShowMap] = useState(false);

  const geojson = JSON.parse(props.geojson);
  console.log(geojson);
  let [east, north] = [{}, {}];
  if (geojson?.length > 0) {
    [east, north] = geojson[0]["geometry"]["coordinates"][0][0];
  }

  return (
    <>
      <Button
        disabled={geojson?.length == 0}
        onClick={() => {
          // setShowMap(true);
          if (Object.keys(east).length > 0) {
						setShowMap(true);
					}
        }}
      >
        Show Map
      </Button>
      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        size={"xl"}
      >
        {" "}
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Area Source Location</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "80vh" }}>
          <MapContainer
            center={[north, east]}
            zoom={11}
            scrollWheelZoom={true}
            style={{ height: "80vh" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

            {/* {east && <DraggableMarker callback={() => {}} />} */}
            {props.geojson && <GeoJSON data={geojson} />}
          </MapContainer>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AreaSourcesView;
