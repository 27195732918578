import {
  faSignIn,
  faSpinner,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";

const Login = () => {
  const navigate = useNavigate();
  const [viewPassword, setViewPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const usernameRef = useRef();

  const login = () => {
    setLoading(true);
    fetch(CONSTANTS.API_ROOT + "/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
    })
      .then(async (data) => {
        let response = await data.json();
        console.log(response);
        if (response["success"]) {
          let creds = response.data;
          if (response.data.user_fullname == "Central Section") {
            creds['region_sess'] = "central";
            creds['updated_role'] = "Administrator";
          }
          setCredentials({
            ...creds,
            isLogin: true,
          });
          setTimeout(() => {
            navigate("dashboard");
          }, 100);
          setLoginError("");
        } else {
          setLoginError("Invalid credentials. Please try again.");
          usernameRef.current.focus();
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoginError("Invalid credentials. Please try again.");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (credentials) {
      if (Object.keys(credentials).includes("region_sess")) {
        navigate("/dashboard");
      }
    }
  }, []);
  return (
    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <div style={{ width: "600px" }}>
        <div>
          <center>
            <img
              src="/logo-clear.png"
              style={{ width: "150px", height: "150px", marginTop: "40px" }}
            />
            <h3 style={{ marginTop: "10px" }}>
              EMB Online Emission Inventory System <br />
              (OEIS) v2.0
            </h3>
          </center>
          <Stack gap={3} style={{ marginTop: "40px" }}>
            {loginError && (
              <Alert variant="danger">
                <FontAwesomeIcon icon={faWarning} />
                <span className="ms-1">{loginError}</span>
              </Alert>
            )}

            <div>
              <Form.Label className="fw-bold">Username/Email Address:</Form.Label>
              <Form.Control
                ref={usernameRef}
                placeholder="e.g. NCR"
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <Form.Label style={{ fontWeight: "bold" }}>Password:</Form.Label>
              <div style={{ position: "relative" }}>
                <Form.Control
                  type={viewPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  variant={"light"}
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                >
                  {viewPassword ? (
                    <Eye style={{ fontSize: "20px" }} />
                  ) : (
                    <EyeSlash style={{ fontSize: "20px" }} />
                  )}
                </Button>
              </div>
            </div>
            {/* <Button style={{ backgroundColor: "rgb(42, 63, 84)" }}> */}
            <Button disabled={loading} onClick={login}>
              {loading && (
                <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
              )}
              <span className="ms-2">{loading ? "Logging in" : "Login"}</span>
            </Button>
          </Stack>
        </div>

        <footer
          className="text-center"
          style={{ marginTop: "50px", fontSize: "10px", color: "#2a2a2a50" }}
        >
          Developed by: Air Quality Management Section
          <br />
          Environmental Management Beaureu
          <br />
          &copy; 2022
        </footer>
      </div>
    </div>
  );
};

export default Login;
