import {
  faFileInvoice,
  faParagraph,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import CONSTANTS from "../../utilities/constants";

const EditMobileEF = (props) => {
  const [show, setShow] = useState(false);
  const [sourceName, setSourceName] = useState(props.source_type);
  const [emissionFactorData, setEmissionFactorData] = useState(
    JSON.parse(props.emission_factors)
  );
  const saveMobile = async () => {
    const payload = JSON.stringify({ emissionFactorData, sourceName });
    try {
      let response = await fetch(
        CONSTANTS.API_ROOT + "/MobileFactors/update/" + props.id,
        {
          method: "POST",
          body: payload,
        }
      );
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor saved successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        props.getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(emissionFactorData);
  }, [emissionFactorData]);

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        variant="success"
        className={props.className}
      >
        <FontAwesomeIcon icon={faPencil} />
        <span> Edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            View Emission Factor &mdash; {props.source_type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Label>Source Name:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Car / Specific Model"
                    value={sourceName}
                    onChange={(e) => setSourceName(e.target.value)}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                {Object.keys(emissionFactorData).map((ef, index) => {
                  let details = emissionFactorData[ef];
                  return (
                    <Table>
                      <thead>
                        <tr>
                          <th colSpan={7}>{details["description"]}</th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Fuel</th>
                          <th>CO</th>
                          <th>NOX</th>
                          <th>PM</th>
                          <th>SOX</th>
                          <th>VOC</th>
                          <th>TOG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {"fuels" in details ? (
                          <>
                            {Object.keys(details["fuels"]).map((fuelKey) => {
                              return (
                                <tr>
                                  <td width="150px">
                                    <Form.Control
                                      value={fuelKey}
                                      disabled
                                      style={{ textTransform: "capitalize" }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["co"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["co"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["nox"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["nox"] =
                                            parseFloat(e.target.value);

                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["pm"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["pm"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["sox"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["sox"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["voc"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["voc"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      value={
                                        emissionFactorData[ef]["fuels"][
                                          fuelKey
                                        ]["tog"]
                                      }
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["tog"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td width="150px">
                              <Form.Control value="--" disabled />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("co" in details)}
                                value={
                                  !("co" in details)
                                    ? ""
                                    : emissionFactorData[ef]["nox"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["co"] = parseFloat(e.target.value);
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("nox" in details)}
                                value={
                                  !("nox" in details)
                                    ? ""
                                    : emissionFactorData[ef]["nox"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["nox"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("pm" in details)}
                                value={
                                  !("pm" in details)
                                    ? ""
                                    : emissionFactorData[ef]["pm"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["pm"] = parseFloat(e.target.value);
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("sox" in details)}
                                value={
                                  !("sox" in details)
                                    ? ""
                                    : emissionFactorData[ef]["sox"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["voc"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("voc" in details)}
                                value={
                                  !("voc" in details)
                                    ? ""
                                    : emissionFactorData[ef]["voc"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["voc"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("tog" in details)}
                                value={
                                  !("tog" in details)
                                    ? ""
                                    : emissionFactorData[ef]["tog"]
                                }
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["tog"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger">Cancel</Button>
          <Button onClick={saveMobile}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMobileEF;
