import {
  faCheck,
  faCheckCircle,
  faClockFour,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import ApprovalProgress from "../pages/approval-components/ApprovalProgress";

const icons = {
  approved: faCheckCircle,
  rejected: faClose,
  awaiting: faClockFour,
};
const colors = {
  rejected: "red",
  approved: "green",
  awaiting: "#2a2a2a50",
  resubmitted: "green",
};
const StatusPill = (props) => {
  const status = props.status.toLocaleLowerCase();
  const [show, setShow] = useState(false);
  const backgroundColor = Object.keys(colors).includes(status)
    ? colors[status]
    : "green";
  const icon = Object.keys(icons).includes(status) ? icons[status] : faCheck;
  return (
    <>
      <span
        role={"button"}
        onClick={() => {
          setShow(true);
        }}
        style={{
          backgroundColor,
          cursor: "pointer",
          color: "white",
          padding: "8px",
          borderRadius: "5px",
          textTransform: "capitalize",
        }}
      >
        <FontAwesomeIcon icon={icon} />
        {""} {props.status}
      </span>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
        size="lg"
        close
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Approval Progress</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ApprovalProgress
            id={props.id}
            source={props.source}
            refresher={() => {}}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StatusPill;
