import { faCircleInfo, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Legend = (props) => {
  const content = props.children ? props.children : "Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet Lorem Ipsum DolorSit Amet Lorem Ipsum Dolor Sit Amet Lorem Ipsum Dolor Sit Amet"
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {/* <Button variant="success">Hover me to see</Button> */}
      <FontAwesomeIcon icon={faCircleInfo} color="#98989870" />
    </OverlayTrigger>
  );
};

export default Legend;
