import { useState } from "react";
import { Modal } from "react-bootstrap";

const UserManual = (props) => {
  const [conversionTable, setConversionTable] = useState(false);
  return (
    <>
      <h5 style={{ marginRight: "20px" }}>
        <a
          href="#"
          role="button"
          onClick={() => {
            setConversionTable(true);
          }}
        >
          User Manual
        </a>
      </h5>
      <Modal
        show={conversionTable}
        onHide={() => setConversionTable(false)}
        backdrop="static"
        keyboard={false}
        size={"xl"}
      >
        {" "}
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>User Manual</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "90vh" }}>
          <embed
            src="/User Manual.pdf#toolbar=0&navpanes=0&scrollbar=0"
            width="100%"
            height="100%"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserManual;
