import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"


const BackButton =  (props) => {
    const navigate = useNavigate()
    return(
        <Button onClick={()=>navigate(-1)} style={{marginBottom: 10}}><FontAwesomeIcon icon={faChevronCircleLeft}/> Back</Button>
    )
}

export default BackButton