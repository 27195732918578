import { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";

const YEARS = [...Array(20).keys()].map((item) => {
  return {
    label: 2023 - item,
    value: 2023 - item,
  };
});
const Duplicate = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [year, setYear] = useState("2022");
  const [toYear, setToYear] = useState("2023");
  const [loading, setLoading] = useState(false);

  const duplicate = async () => {
    setLoading(true);
    try {
      const resp = await fetch(
        `${CONSTANTS.API_ROOT}/${props.source}/duplicate/${year.value}/${toYear.value}`,
        { method: "POST", body: JSON.stringify(credentials) }
      );
      const data = await resp.json();
      console.log("Duplicated", data);
      setLoading(false);
      props.onHide();
      props.getFilteredData();
    } catch (error) {
      console.log("Error Duplicating", error);
      setLoading(false);
      props.onHide();
      props.getFilteredData();
    }
  };
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Duplicate {props.source}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={3}>From Year: </Col>
          <Col>
            <Select
              options={YEARS}
              value={year}
              onChange={(e) => {
                setYear(e);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={3}>To Year: </Col>
          <Col>
            <Select
              options={YEARS}
              value={toYear}
              onChange={(e) => {
                setToYear(e);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={duplicate} disabled={loading}>
          {loading ? "Please wait..." : "Duplicate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Duplicate;
