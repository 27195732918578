const SOURCE_TYPES = [
  {
    label: "Auto fire",
    value: "automotive_fires",
    ar_label: "No of fire incidents",
  },
  {
    label: "Structural fire",
    value: "structural_fires",
    ar_label: "No of fire incidents",
  },
  {
    label: "Municipal Refuse",
    value: "open_burning_municipal",
    ar_label: "Area(hectares)",
  },
  {
    label: "Tire Burning",
    value: "open_burning_tire",
    ar_label: "Tire Refuse",
  },
  {
    label: "Residential construction",
    value: "residential_construction",
    ar_label: "Area(Square meter)",
    duration_label: "Duration in months",
  },
  {
    label: "Non-residential construction",
    value: "non_residential_construction",
    ar_label: "Area(Square meter)",
    duration_label: "Duration in months",
  },
  {
    label: "Road construction",
    value: "road_construction",
    ar_label: "Area(hectares)",
    duration_label: "Duration in months",
  },
  {
    label: "Residential cooking",
    value: "residential_cooking",
    ar_label: "",
  },
  // {
  //   label: "Gasoline Dispensing - Underground Tank Working Loss",
  //   value: "underground_tank_working",
  //   ar_label: "Gallons",
  // },
  // {
  //   label: "Gasoline Dispensing - Vehicle Refueling Vapor Displacement",
  //   value: "vehicle_refueling",
  //   ar_label: "Gallons",
  // },
  // {
  //   label: "Gasoline Dispensing - Underground Tank Breathing Loss",
  //   value: "underground_tank_breathing",
  //   ar_label: "Gallons",
  // },
  // {
  //   label: "Gasoline Dispensing - Vehicle Refueling Spillage",
  //   value: "vehicle_refueling_spillage",
  //   ar_label: "Gallons",
  // },
  {
    label: "Dry Cleaning",
    value: "dry_cleaning",
    ar_label: "KG of laundry/yr",
  },
  {
    label: "Landfills",
    value: "landfills",
    ar_label: "m3 Methane (CH4)",
  },
  {
    label: "Surface Coating",
    value: "surface_coating",
    ar_label: "Amount of Paint",
    fuel_label: "Coating Type",
  },
  {
    label: "Fish Canning and Byproduct Manufacture",
    value: "fish_canning",
    ar_label: "Amount of fish processed)",
    fuel_label: "Process",
  },
  {
    label: "Gasoline Dispensing",
    value: "gasoline_dispensing",
    ar_label: "Barrels",
  },
];

const EMISSION_FACTORS = {
  gasoline_dispensing: {
    underground_tank_working: {
      label: "Underground Tank Working Loss",
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 9.5,
    },
    vehicle_refueling: {
      label: "Vehicle Refueling Vapor Displacement",
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 10,
    },
    underground_tank_breathing: {
      label: "Underground Tank Breathing Loss",
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 1,
    },
    vehicle_refueling_spillage: {
      label: "Vehicle Refueling Spillage",
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0.7,
    },
  },
  automotive_fires: {
    pm: 17,
    co: 21.25,
    nox: 0.7,
    sox: 0,
    voc: 7.21,
  },
  structural_fires: {
    pm: 14,
    co: 220,
    nox: 5,
    sox: 0,
    voc: 18,
  },
  open_burning_municipal: {
    pm: 16,
    co: 1,
    nox: 85,
    sox: 0,
    voc: 3,
  },
  open_burning_kaingin: {
    pm: 21,
    co: 117,
    nox: 0,
    sox: 0,
    voc: 0,
    multiplier: 4.5,
  },
  open_burning_tire: {
    pm: 281.8,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
    divisor: 1000,
  },
  dry_cleaning: {
    pm: 0,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0.7,
  },
  landfills: {
    pm: 270,
    co: 12000,
    nox: 650,
    sox: 0,
    voc: 0,
  },
  residential_cooking: {
    Wood: {
      unit: "tons",
      divisor: 2205, // lbs
      pm: 30.6,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0,
    },
    Charcoal: {
      unit: "kg",
      divisor: 1000000, // grams
      pm: 41.31,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0,
    },
    LPG: {
      unit: "kg",
      divisor: 1000000, // kg
      pm: 0.03,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0,
    },
    Kerosene: {
      unit: "Litre",
      divisor: 1000000, // kg
      pm: 0,
      co: 0,
      nox: 0,
      sox: 1.48,
      voc: 0,
    },
  },
  surface_coating: {
    Paint: {
      unit: "Amount of paint",
      divisor: 2205, // lbs
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 1120,
    },
    "Varnish and shellac": {
      unit: "Amount of paint",
      divisor: 2205, // lbs
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 1000,
    },
    Lacquer: {
      unit: "Amount of paint",
      divisor: 2205, // lbs
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 1540,
    },
    Enamel: {
      unit: "Amount of paint",
      divisor: 2205, // lbs
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 840,
    },
    "Primer(zinc chromate)": {
      unit: "Amount of paint",
      divisor: 1000000, // kg
      pm: 0,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 1320,
    },
  },
  fish_canning: {
    "Steam-tube dryer": {
      unit: "Amount of fished processed (Tons)",
      divisor: 1000, // kg
      pm: 2.5,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0,
    },
    "Direct-fired dryer": {
      unit: "Amount of fished processed (Tons)",
      divisor: 1000, // kg
      pm: 4,
      co: 0,
      nox: 0,
      sox: 0,
      voc: 0,
    },
  },
};

class Area {
  getSourceTypes = () => {
    return SOURCE_TYPES;
  };

  getEmissionFactors = () => {
    return EMISSION_FACTORS;
  };

  calculate = (activityRate, source, dependencies = {}) => {
    // general formula
    console.log(source);
    if (source == "road_construction") {
      var pm = (activityRate * dependencies["duration"] * 2.96).toFixed(2);
      var co = 0;
      var nox = 0;
      var sox = 0;
      var voc = 0;
      return { pm, co, nox, sox, voc };
    } else if (
      ["residential_construction", "non_residential_construction"].includes(
        source
      )
    ) {
      var pm = (
        (activityRate / 10000) *
        dependencies["duration"] *
        2.96
      ).toFixed(2);
      var co = 0;
      var nox = 0;
      var sox = 0;
      var voc = 0;
      return { pm, co, nox, sox, voc };
    } else if (
      ["residential_cooking", "surface_coating", "fish_canning"].includes(
        source
      )
    ) {
      var fuel = dependencies["fuel"];
      if (fuel == "") {
        var pm = 0;
        var co = 0;
        var nox = 0;
        var sox = 0;
        var voc = 0;
        return { pm, co, nox, sox, voc };
      }
      console.log(
        "DITO HERE",
        activityRate,
        EMISSION_FACTORS[source][fuel]["pm"],
        EMISSION_FACTORS[source][fuel]["divisor"]
      );
      var pm =
        (activityRate * EMISSION_FACTORS[source][fuel]["pm"]) /
        EMISSION_FACTORS[source][fuel]["divisor"];
      var co = (
        (activityRate * EMISSION_FACTORS[source][fuel]["co"]) /
        EMISSION_FACTORS[source][fuel]["divisor"]
      ).toFixed(5);
      var nox = (
        (activityRate * EMISSION_FACTORS[source][fuel]["nox"]) /
        EMISSION_FACTORS[source][fuel]["divisor"]
      ).toFixed(5);
      var sox =
        (activityRate * EMISSION_FACTORS[source][fuel]["sox"]) /
        EMISSION_FACTORS[source][fuel]["divisor"];
      var voc = (
        (activityRate * EMISSION_FACTORS[source][fuel]["voc"]) /
        EMISSION_FACTORS[source][fuel]["divisor"]
      ).toFixed(5);
      return { pm, co, nox, sox, voc };
    } else if (
      [
        "underground_tank_working",
        "vehicle_refueling",
        "underground_tank_breathing",
        "vehicle_refueling_spillage",
      ].includes(source)
    ) {
      var pm = (
        (activityRate * EMISSION_FACTORS["gasoline_dispensing"][source]["pm"]) /
        2205
      ).toFixed(2);
      var co = (
        (activityRate * EMISSION_FACTORS["gasoline_dispensing"][source]["co"]) /
        2205
      ).toFixed(2);
      var nox = (
        (activityRate *
          EMISSION_FACTORS["gasoline_dispensing"][source]["nox"]) /
        2205
      ).toFixed(2);
      var sox = (
        (activityRate *
          EMISSION_FACTORS["gasoline_dispensing"][source]["sox"]) /
        2205
      ).toFixed(2);
      var voc = (
        (activityRate *
          42 *
          EMISSION_FACTORS["gasoline_dispensing"][source]["voc"]) /
        2205 /
        1000
      ).toFixed(2);
      return { pm, co, nox, sox, voc };
    } else if (source == "dry_cleaning") {
      var voc = (
        (activityRate * EMISSION_FACTORS[source]["voc"]) /
        1000
      ).toFixed(2);
      return {
        pm: 0,
        co: 0,
        nox: 0,
        nox: 0,
        voc,
      };
    } else if (source == "landfills") {
      var pm = (
        (activityRate * EMISSION_FACTORS[source]["pm"]) /
        1000000 /
        100
      ).toFixed(5);
      var co = (
        (activityRate * EMISSION_FACTORS[source]["co"]) /
        1000000 /
        100
      ).toFixed(5);
      var nox = (
        (activityRate * EMISSION_FACTORS[source]["nox"]) /
        1000000 /
        100
      ).toFixed(5);
      return {
        pm,
        co,
        nox,
        sox: 0,
        voc: 0,
      };
    } else {
      var multiplier = EMISSION_FACTORS[source].multiplier ?? 1;
      var divisor = EMISSION_FACTORS[source].divisor ?? 1;
      var pm =
        (((activityRate * EMISSION_FACTORS[source]["pm"]) / 2205).toFixed(2) *
          multiplier) /
        divisor;
      var co =
        ((activityRate * EMISSION_FACTORS[source]["co"]) / 2205).toFixed(2) *
        multiplier;
      var nox = (
        (activityRate * EMISSION_FACTORS[source]["nox"]) /
        2205
      ).toFixed(2);
      var sox = (
        (activityRate * EMISSION_FACTORS[source]["sox"]) /
        2205
      ).toFixed(2);
      var voc = (
        (activityRate * EMISSION_FACTORS[source]["voc"]) /
        2205
      ).toFixed(2);
      return { pm, co, nox, sox, voc };
    }
  };
}

const areaHelper = new Area();
export default areaHelper;
export { EMISSION_FACTORS };
