import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import CONSTANTS from "../../utilities/constants";

const ApproveButton = (props) => {
  const navigate = useNavigate();
  const [credentials] = useLocalStorage("credentials");
  const approvalData = props.approvalData.details;
  const nextFlag = props.approvalData.nextFlag.key;
  const approve = () => {
    swal({
      text: "Do you want to approve this entry?",
      icon: "info",
      buttons: true,
    }).then(async (willSave) => {
      if (willSave) {
        var payload = {
          by: credentials.user_fullname,
          status: "approved",
          nextFlag,
          entry_id: approvalData.entry_id,
          source: props.source,
          credentials
        };
        const response = await fetch(
          CONSTANTS.API_ROOT + "/Approval/updateStatus/" + approvalData.id,
          {
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
        props.refresher();
      }
    });
  };
  return (
    <Button
      variant="success"
      onClick={approve}
      className="ms-auto"
      style={{ width: "120px" }}
    >
      <FontAwesomeIcon icon={faCheck} /> Approve
    </Button>
  );
};

export default ApproveButton;
