import {
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap";
import StepWizard from "react-step-wizard";
import areaHelper from "../utilities/area";
import readXlsxFile from "read-excel-file";
import swal from "sweetalert";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import mobileHelper from "../utilities/mobile";

const VEHICLE_TYPES = [
  { label: "Cars", value: "cars" },
  { label: "Utility Vehicle", value: "uv" },
  { label: "Trucks", value: "trucks" },
  { label: "Buses", value: "buses" },
  { label: "Motorcycle/Tricycle", value: "mc_tc" },
];

const FUEL_TYPES = [
  { label: "Gasoline", value: "gas" },
  { label: "Diesel", value: "diesel" },
];
const ImportMobile = (props) => {
  const wizardRef = useRef(null);
  const [importData, setImportData] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    console.log("Import modal", props.show);
  }, [props.show]);
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      //   centered
      //   fullscreen={true}
      size="xl"
    >
      <Modal.Header closeButton className="ei-background" closeVariant="white">
        <Modal.Title>Import Mobile Source &mdash; {props.region} </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ overflow: "hidden", minHeight: "60vh", padding: "30px" }}
      >
        {/* <Stepper /> */}
        <StepWizard
          style={{ overflow: "hidden" }}
          ref={wizardRef}
          setImportData={setImportData}
          importData={importData}
          setSaving={setSaving}
        >
          <UploadFile setImportData={setImportData} importData={importData} />
          <ReviewImport
            setImportData={setImportData}
            importData={importData}
            setSaving={setSaving}
          />
          <CompleteImport
            setImportData={setImportData}
            importData={importData}
            saving={saving}
            updateTable={props.updateTable}
            handleClose={props.handleClose}
            setSaving={setSaving}
          />
        </StepWizard>
      </Modal.Body>
      <Modal.Footer>
        <WizardNav wizardRef={wizardRef} setSaving={setSaving} />
      </Modal.Footer>
    </Modal>
  );
};

const Stepper = (props) => {
  const length = props.steps;
  const inactiveColor = "#e4ebe6";
  const activeColor = "#198754";
  const styles = {
    stepCircle: {
      width: "40px",
      height: "40px",
      backgroundColor: "white",
      borderRadius: 30,
      border: "4px solid " + inactiveColor,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      display: "flex",
      marginLeft: "-2px",
      zIndex: 9,
    },
    progress: {
      backgroundColor: inactiveColor,
      flexGrow: 1,
      height: "12px",
      marginLeft: "-2px",
    },
    progressBar: {
      backgroundColor: activeColor,
      width: "100%",
      height: "12px",
      transition: "width 1s",
    },
  };
  return (
    <div>
      <center>
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
          }}
        >
          {[...Array(length).keys()].map((item, index) => {
            let number = item + 1;
            if (item != length - 1) {
              return (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={styles.stepCircle}>{number}</div>
                  <div style={styles.progress}>
                    <div style={styles.progressBar} />
                  </div>
                </div>
              );
            } else {
              return (
                <div style={{}}>
                  <div style={styles.stepCircle}>{number}</div>
                </div>
              );
            }
          })}
        </Stack>
      </center>
    </div>
  );
};

const WizardNav = (props) => {
  return (
    <Stack direction="horizontal">
      <Button
        onClick={() => {
          props.wizardRef.current.previousStep();
        }}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        Back
      </Button>
      <Button
        onClick={() => {
          if (props.wizardRef.current.currentStep == 2) {
            // console.log("DELETING INDEX: " + deleteIndex);
            swal({
              text: "Do you want to save the following data?",
              icon: "info",
              buttons: true,
            }).then((willSave) => {
              if (willSave) {
                props.setSaving(true);
                props.wizardRef.current.nextStep();
              } else {
                // nothing to do for now
              }
            });
          } else {
            props.wizardRef.current.nextStep();
          }
        }}
        className="ms-2"
      >
        Next <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Stack>
  );
};
const UploadFile = (props) => {
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (file) {
      readXlsxFile(file)
        .then((data) => {
          data.shift(); // remove the header
          console.log(data);
          var computed = data.map((d) => {
            let vehicleType = VEHICLE_TYPES.find((v) => v.label == d[2])[
              "value"
            ];

            let fuelType = FUEL_TYPES.find((v) => v.label == d[4])["value"];
            console.log(
              vehicleType,
              fuelType,
              d[7],
              d[8],
              d[3],
              d[5],
              d[9],
              d[6] / 100
            );
            const computation = mobileHelper.calculateMobile(
              vehicleType,
              fuelType,
              d[7],
              d[8],
              d[3],
              d[5],
              d[9],
              d[6]
            );
            const result = computation["RESULTS"];
            let updated = d;
            let accumlatedPm =
              parseFloat(computation["RESULTS"]["pm10"]) +
              parseFloat(computation["RESULTS"]["pmex"]) +
              parseFloat(computation["RESULTS"]["pmtw"]);
            updated[10] = parseFloat(result["co"]).toFixed(3);
            updated[11] = parseFloat(result["nox"]).toFixed(3);
            updated[12] = accumlatedPm;
            updated[13] = computation["SOX"];
            updated[14] = parseFloat(result["tog"]).toFixed(3);

            return updated;
          });
          props.setImportData(computed);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file]);
  return (
    <div>
      <h3>Upload file</h3>
      <p>Preparing your import file</p>
      <ol>
        <li>
          <a href="/mobile-source-import-template.csv" download>
            Download Import Template for Mobile Source
          </a>
        </li>
        <li>
          Make sure to enter valid information.
          <ul>
            <li>
              Vehicle Type:
              <ol>
                {VEHICLE_TYPES.map((vtype) => {
                  return (
                    <li>
                      <b>{vtype.label}</b>
                    </li>
                  );
                })}
              </ol>
            </li>
            <li>
              Fuel Type:
              <ol>
                <li>
                  <b>Diesel</b>
                </li>
                <li>
                  <b>Gasoline</b>
                </li>
              </ol>
            </li>
            <li style={{ fontWeight: "bold" }}>Number of vehicles</li>
            <li style={{ fontWeight: "bold" }}>
              Vehicle kilometered travelled (VKT)
            </li>
            <li>
              <b>Sulfur Content</b> (<i>In percentage</i>)
            </li>
            <li style={{ fontWeight: "bold" }}>Trips per Day</li>
            <li style={{ fontWeight: "bold" }}>Days of Operation</li>
            <li>
              <b>Fuel consumption in Gallons.</b> (
              <i>For so computation of SOx.</i>)
            </li>
          </ul>
        </li>
        <li>
          Pollutants would be automatically computed based on their emissions
          factors
        </li>
      </ol>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Choose a file to import</Form.Label>
        <Form.Control
          type="file"
          style={{ width: "300px" }}
          onChange={(e) => setFile(e.target.files[0])}
        />
      </Form.Group>
    </div>
  );
};
const ReviewImport = (props) => {
  return (
    <div>
      <h3>Review Import</h3>
      <p>Please double check your import data</p>
      <Table style={{ overflowX: "scroll" }} bordered>
        <thead>
          <tr>
            <th>Year</th>
            <th>Province</th>
            <th>Vehicle Type</th>
            <th># of Vehicles</th>
            <th>Fuel Type</th>
            <th>VKT.</th>
            <th>Sulfur %</th>
            <th>Trips/Day</th>
            <th>Days of Operation</th>
            <th>Fuel Consumption</th>
            <th>CO</th>
            <th>NOX</th>
            <th>PM</th>
            <th>SOX</th>
            <th>TOG</th>
          </tr>
        </thead>
        <tbody>
          {props.importData.map((item) => {
            return (
              <tr>
                {[...Array(item.length).keys()].map((index) => {
                  return <td>{item[index]}</td>;
                })}
                {/* <td>
                  <Button variant="danger">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
const CompleteImport = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");

  const region = credentials.region_sess;

  const [totalRows, setTotalRows] = useState([]);
  const [imported, setImported] = useState(0);
  const [progressWidth, setProgressWidth] = useState("0%");
  const saveAll = async () => {
    let done = await Promise.all(
      props.importData.map(async (row) => {
        let generalInfo = {
          year: row[0],
          province: row[1],
          region,
        };
        let vehicleType = VEHICLE_TYPES.find((v) => v.label == row[2])["value"];

        let fuelType = FUEL_TYPES.find((v) => v.label == row[4])["value"];
        var mobileSources = [
          {
            vehicleType,
            fuelType,
            trips: row[7],
            days: row[8],
            vehicleNumber: row[3],
            vkt: row[5],
            fuelConsumption: row[9],
            sulfurContent: row[6],
            co: row[10],
            nox: row[11],
            pm: row[12],
            sox: row[13],
            tog: row[14],
          },
        ];
        try {
          let response = await fetch(CONSTANTS.API_ROOT + "/mobile/addaction", {
            method: "POST",
            body: JSON.stringify({ mobileSources, generalInfo, credentials }),
          });
          setImported((prev) => prev + 1);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
        return true;
      })
    );
    swal({
      text: "Import Success",
      icon: "success",
    }).then(() => {
      props.handleClose(false);
      props.updateTable();
      props.setImportData([]);
      props.setSaving(false);
    });
  };
  useEffect(() => {
    if (props.saving) {
      console.log("Saving this shit now");

      try {
        saveAll();
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.saving]);

  useEffect(() => {
    let width = parseInt((imported / totalRows) * 100) + "%";
    setProgressWidth(width);
  }, [imported]);
  useEffect(() => {
    setTotalRows(props.importData.length);
    console.log(totalRows);
  }, [props.importData]);
  return (
    <div>
      <h3>Completing Import</h3>
      <p>We are now saving your imported file.</p>
      <div
        style={{
          height: "30px",
          backgroundColor: "#2a2a2a20",
          borderRadius: 20,
          marginTop: "100px",
        }}
      >
        <div
          style={{
            height: "30px",
            width: progressWidth,
            backgroundColor: "#198754",
            borderRadius: 20,
            transition: "width 0.5s",
          }}
        ></div>
      </div>
      <h5 style={{ textAlign: "center", marginTop: 20 }}>
        {imported}/{totalRows} Imported
      </h5>
    </div>
  );
};
export default ImportMobile;
