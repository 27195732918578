import { faCheckCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Stack } from "react-bootstrap";
import approvalHelper, { FLAG_APPROVER } from "../../utilities/approval";
import ApproveButton from "./ApproveButton";
import RejectionModal from "./RejectionModal";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import swal from "sweetalert";
import CONSTANTS from "../../utilities/constants";

const ApprovalBanner = (props) => {
  const id = props.id;
  const source = props.source;
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [isApprover, setIsApprover] = useState(false);
  const [rejectedData, setRejectedData] = useState({});
  const [approvalData, setApprovalData] = useState({
    central: [],
    region: [],
    central_status: "",
    details: {},
  });

  const getApprovalDetails = async () => {
    try {
      let d = await approvalHelper.getApprovalDetails(id, props.source);
      console.log(d);
      let all = [...d["region"], ...d["central"]];
      let rejected = all.find((s) => s.status == "rejected");
      if (rejected) {
        setRejectedData(rejected);
      } else {
        setRejectedData({});
      }
      setApprovalData(d);
    } catch (error) {
      console.log(error);
    }
  };
  const resubmit = () => {
    swal({
      text: "Do you want to resubmit this entry?",
      icon: "info",
      buttons: true,
    }).then(async (willSave) => {
      console.log(rejectedData);
      if (willSave) {
        var payload = {
          by: credentials.user_fullname,
          status: "resubmitted",
          nextFlag: rejectedData.key,
          entry_id: approvalData.details.entry_id,
          source: props.source,
          credentials
        };
        const response = await fetch(
          CONSTANTS.API_ROOT +
            "/Approval/updateStatus/" +
            approvalData.details.id,
          {
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
        props.refresher();
      }
    });
  };

  useEffect(() => {
    try {
      const current_user_role = credentials.updated_role
        ? credentials.updated_role
        : "4";
      const isApprover =
        FLAG_APPROVER[approvalData.nextFlag.key] == current_user_role;
      setIsApprover(isApprover);
    } catch (error) {
      console.log("error@approvalDataEffect", error);
    }
  }, [approvalData]);
  useEffect(() => {
    getApprovalDetails();
  }, []);
  return (
    <>
      {Object.keys(rejectedData).length > 0 && (
        <Alert variant="danger">
          <Stack direction="horizontal">
            <div>
              <h5>
                <FontAwesomeIcon icon={faCheckCircle} />{" "}
                <i>
                  This {props.source} source information was rejected by{" "}
                  {rejectedData.by}
                </i>
              </h5>
            </div>
            <Button variant="success" className="ms-auto" onClick={resubmit}>
              Resubmit
            </Button>
          </Stack>
        </Alert>
      )}
      {approvalData.central_status == "central_approved" && (
        <Alert variant="success">
          <Stack direction="horizontal">
            <h5>
              <FontAwesomeIcon icon={faCheckCircle} />{" "}
              <i>
                This {props.source} source information has been approved by the
                Central Office.
              </i>
            </h5>
          </Stack>
        </Alert>
      )}
      {approvalData.nextFlag && Object.keys(rejectedData).length == 0 && (
        <Alert variant="info">
          <Stack direction="horizontal">
            <h5>
              <FontAwesomeIcon icon={faCircleInfo} />{" "}
              <i>
                This {source} source information is pending for{" "}
                {approvalData.nextFlag["pending_label"]}.
              </i>
            </h5>
            {isApprover && (
              <>
                <ApproveButton
                  source={source}
                  approvalData={approvalData}
                  refresher={getApprovalDetails}
                />
                <RejectionModal
                  source={source}
                  approvalData={approvalData}
                  refresher={getApprovalDetails}
                />
              </>
            )}
          </Stack>
        </Alert>
      )}
    </>
  );
};

export default ApprovalBanner;
