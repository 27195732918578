import { Nav } from "react-bootstrap";
import {
  faCar,
  faBuilding,
  faGear,
  faChartBar,
  faMap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  return (
    <div
      style={{
        minWidth: 230,
        backgroundColor: "#2A3F54",
        paddingTop: 20,
      }}
    >
      <Nav defaultActiveKey="/dashboard" className="flex-column">
        <Nav.Link
          onClick={() => {
            navigate("/dashboard");
          }}
          className={`${currentPath == "/dashboard" ? "active" : ""}`}
          style={{ color: "white" }}
        >
          <FontAwesomeIcon
            icon={faChartBar}
            style={{ fontSize: 24, marginRight: 8, width: 26 }}
          />
          Dashboard
        </Nav.Link>

        <span style={{ paddingLeft: 20, color: "white" }}>Sources</span>
        <Nav.Link
          eventKey="link-1"
          style={{ color: "white" }}
          className={`${currentPath == "/stationary" ? "active" : ""}`}
          onClick={() => {
            navigate("/stationary");
          }}
        >
          <FontAwesomeIcon
            icon={faBuilding}
            style={{ fontSize: 24, marginRight: 8, width: 26 }}
          />
          Stationary
        </Nav.Link>
        <Nav.Link
          eventKey="link-2"
          style={{ color: "white" }}
          className={`${currentPath == "/area" ? "active" : ""}`}
          onClick={() => {
            navigate("/area");
          }}
        >
          <FontAwesomeIcon
            icon={faMap}
            style={{ fontSize: 24, marginRight: 8, width: 26 }}
          />
          Area
        </Nav.Link>
        <Nav.Link
          eventKey="link-3"
          style={{ color: "white" }}
          className={`${currentPath.includes('mobile') ? "active" : ""}`}
          onClick={() => {
            navigate("/mobile");
          }}
        >
          <FontAwesomeIcon
            icon={faCar}
            style={{ fontSize: 24, marginRight: 8, width: 26 }}
          />
          Mobile
        </Nav.Link>
        <Nav.Link
          eventKey="link-4"
          style={{ color: "white" }}
          className={`${currentPath == "/settings" ? "active" : ""}`}
          onClick={() => {
            navigate("/settings");
          }}
        >
          <FontAwesomeIcon
            icon={faGear}
            style={{ fontSize: 24, marginRight: 8, width: 26 }}
          />
          Settings
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
