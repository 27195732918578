import "leaflet/dist/leaflet.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

const icon = new L.Icon({
  iconUrl: require("../assets/marker-icon.png"),
  iconRetinaUrl: require("../assets/marker-icon.png"),
  iconAnchor: null,
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(60, 75),
});

const FixedMarker = (props) => {
  const [draggable, setDraggable] = useState(true);
  const [position, setPosition] = useState({
    lat: props.east,
    lng: props.north,
  });
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  useEffect(() => {
    if (props.callback) {
      let coords = `${position.lat},${position.lng}`;
      console.log("Coords set to: ", coords);
      props.callback(coords);
    }
  }, [position]);
  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  return (
    <Marker
      draggable={false}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={icon}
    />
  );
};

export default FixedMarker;
