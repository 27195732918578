import {
  faCheck,
  faClose,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Card, ProgressBar, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Legend from "../../components/Legend";
import approvalHelper from "../../utilities/approval";

const ApprovalProgress = (props) => {
  const navigate = useNavigate();
  const id = props.id;
  const [progress, setProgress] = useState(0);
  const ICON_COLOR = {
    approved: "green",
    rejected: "red",
    awaiting: "#2a2a2a50",
    resubmitted: "#2a2a2a50",
  };
  const ICON = {
    approved: faCheck,
    rejected: faClose,
    awaiting: faUserClock,
    resubmitted: faUserClock,
  };

  const [approvalData, setApprovalData] = useState({
    central: [],
    region: [],
    central_status: "",
    details: {},
  });

  const getApprovalDetails = async () => {
    try {
      let d = await approvalHelper.getApprovalDetails(id, props.source);
      setApprovalData(d);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let all = [...approvalData["region"], ...approvalData["central"]];
    let approved = all.filter((s) => s.status == "approved");
    const progressBar = parseInt((approved.length / all.length) * 100);
    setProgress(progressBar);
  }, [approvalData]);
  useEffect(() => {
    getApprovalDetails();
  }, []);

  return (
    <Card style={{ minHeight: "700px" }}>
      <Card.Header>
        Approval progress
        <ProgressBar now={progress} className="mt-1" label={`${progress}%`} />
      </Card.Header>

      <Card.Body>
        <Card.Title>Regional Status</Card.Title>
        <Stack gap={2}>
          {approvalData["region"].map((step, index) => {
            const iconColor = ICON_COLOR[step.status];
            const icon = ICON[step.status];
            return (
              <Stack direction="horizontal">
                <div
                  style={{
                    ...internalStyles.progressIcon,
                    backgroundColor: iconColor,
                  }}
                >
                  <FontAwesomeIcon icon={icon} />
                </div>
                <div
                  className="ms-2"
                  style={{
                    color: iconColor,
                  }}
                >
                  <div className="fw-bold">{step.label}</div>
                  <div style={{ fontSize: 12, fontStyle: "italic" }}>
                    by: {step.by}{" "}
                    {step.status == "rejected" && (
                      <Legend>{step.reason}</Legend>
                    )}
                  </div>
                </div>
              </Stack>
            );
          })}
        </Stack>
        <hr />
        <Card.Title className="mt-2">Central Status</Card.Title>
        <Stack gap={2}>
          {approvalData["central"].map((step, index) => {
            return (
              <Stack direction="horizontal">
                <div
                  style={{
                    ...internalStyles.progressIcon,
                    backgroundColor:
                      step.status == "approved" ? "green" : "#2a2a2a50",
                  }}
                >
                  <FontAwesomeIcon
                    icon={step.status == "approved" ? faCheck : faUserClock}
                  />
                </div>
                <div
                  className="ms-2"
                  style={{
                    color: step.status == "approved" ? "#2a2a2a" : "#2a2a2a50",
                  }}
                >
                  <div className="fw-bold">{step.label}</div>
                  {step.status == "approved" ? (
                    <div style={{ fontSize: 12, fontStyle: "italic" }}>
                      by: {step.by}
                    </div>
                  ) : (
                    <div style={{ fontSize: 12, fontStyle: "italic" }}>
                      Awaiting
                    </div>
                  )}
                </div>
              </Stack>
            );
          })}
        </Stack>
      </Card.Body>
    </Card>
  );
};

const internalStyles = {
  progressIcon: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: 20,
  },
};

export default ApprovalProgress;
