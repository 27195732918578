import { useEffect, useState } from "react";

const getStored = (key, firstValue) => {
  var data = localStorage.getItem(key);
  if (data) {
    try {
        return JSON.parse(data);
    } catch (error) {
      return data;
    }
  }
  if (firstValue instanceof Function) return firstValue(); //pag function

  return firstValue; //pag wala ng no choice
};

const useLocalStorage = (key, initval) => {
  const [value, setValue] = useState(() => {
    return getStored(key, initval);
  });

  useEffect(() => {
    var data;
    try {
      data = JSON.stringify(value);
    } catch (error) {
      data = value;
    }
    localStorage.setItem(key, data);
  });
  return [value, setValue];
};
export default useLocalStorage;
