import CONSTANTS from "./constants";

const LABELS_MAP = {
  regional_prepare: "Prepared",
  regional_review: "Reviewed",
  regional_recommend: "Recommended for Approval",
  regional_approved: "Approved",
  central_reviewed: "Reviewed",
  central_recommend: "Recommended for Approval",
  central_approved: "Approved",
};

const REGIONAL_FLAG = [
  "regional_prepare",
  "regional_review",
  "regional_recommend",
  "regional_approved",
];
const CENTRAL_FLAG = [
  "central_reviewed",
  "central_recommend",
  "central_approved",
];
const ROLES = [
  {
    name: "Preparer",
    value: 1,
  },
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approval",
    value: 4,
  },
];
const FLAG_APPROVER = {
  regional_prepare: "1",
  regional_review: "2",
  regional_recommend: "3",
  regional_approved: "4",
  central_reviewed: "2",
  central_recommend: "3",
  central_approved: "4",
};
const PENDING_LABELS = {
  regional_prepare: "Preparing",
  regional_review: "Regional Review",
  regional_recommend: "Recommending Approval (Region)",
  regional_approved: "Regional Approval",
  central_reviewed: "Central Review",
  central_recommend: "Recommending Approval (Central)",
  central_approved: "Central Approval",
};

const CURRENT_LABELS = {
  regional_prepare: "Prepared",
  regional_review: "Reviewed",
  regional_recommend: "Recommended",
  regional_approved: "Approved",
  central_reviewed: "Reviewed",
  central_recommend: "Recommended",
  central_approved: "Approved",
  approved: "Approved",
  rejected: "Rejected",
  resubmitted: "Resubmitted",
};

class Approval {
  constructor() {
    this.name = "Approval Helper";
  }
  identifyPending = (current) => {
    let allFlags = [...REGIONAL_FLAG, ...CENTRAL_FLAG];
    try {
      const nextFlag = allFlags[allFlags.indexOf(current) + 1];
      return {
        flag: nextFlag,
        label: PENDING_LABELS[nextFlag],
      };
    } catch (error) {
      return false;
    }
  };

  getApprovalDetails = async (id, source = "mobile") => {
    console.log('GETTING PROOVAL DETAILS FOR', source);
    const response = await fetch(
      CONSTANTS.API_ROOT + "/Approval/get/" + id + "/" + source
    );
    let approvals = await response.json();
    let original = { ...approvals };
    Object.keys(approvals).forEach(function (key, index) {
      try {
        approvals[key] = JSON.parse(approvals[key]);
      } catch (error) {
        approvals[key] = null;
      }
    });
    const regionApproval = REGIONAL_FLAG.map((flag) => {
      const details = approvals[flag];
      let date;
      try {
        date = new Date(details["date"] * 1000).toISOString();
      } catch (error) {
        date = "NA";
      }
      if (details) {
        return {
          key: flag,
          label: LABELS_MAP[flag],
          by: details["by"],
          reason: details.reason ?? "",
          date: date,
          status: ["prepared"].includes(details["status"])
            ? "approved"
            : details["status"],
        };
      } else {
        return {
          key: flag,
          label: LABELS_MAP[flag],
          by: "awaiting",
          reason: "",
          date: "",
          status: "awaiting",
        };
      }
    });

    const centralApproval = CENTRAL_FLAG.map((flag) => {
      const details = approvals[flag];
      let date;
      try {
        date = new Date(details["date"] * 1000).toISOString();
      } catch (error) {
        date = "NA";
      }
      if (details) {
        return {
          key: flag,
          label: LABELS_MAP[flag],
          by: details["by"],
          date: date,
          status: ["prepared"].includes(details["status"])
            ? "approved"
            : details["status"],
        };
      } else {
        return {
          key: flag,
          label: LABELS_MAP[flag],
          by: "awaiting",
          date: "",
          status: "waiting",
        };
      }
    });
    let allProgress = [...regionApproval, ...centralApproval];
    let nextFlag = allProgress.find(
      (p) => p.by == "awaiting" || p.status == "resubmitted"
    );
    if (nextFlag) {
      nextFlag["pending_label"] = PENDING_LABELS[nextFlag["key"]];
    }
    return {
      region: regionApproval,
      central: centralApproval,
      nextFlag,
      central_status: original["central_status"],
      details: original,
    };
  };
}
const approvalHelper = new Approval();
export default approvalHelper;
export {
  LABELS_MAP,
  REGIONAL_FLAG,
  CENTRAL_FLAG,
  FLAG_APPROVER,
  CURRENT_LABELS,
};
