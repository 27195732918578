import { useEffect, useState } from "react";
import { Map, TileLayer, FeatureGroup, Circle } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";

const LeaftletDrawer = (props) => {
  const [mapLayers, setMapLayers] = useState([]);
  const onCreated = (e) => {
    console.log(e);
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { leaflet_id } = layer;

      setMapLayers((layers) => [...layers, layer.toGeoJSON()]);
    }
  };

  const onEdited = (e) => {
    // console.log('Edited data', e);

    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  useEffect(() => {
    if (props.setter) {
      console.log(mapLayers);
      props.setter(mapLayers);
    } else {
      console.log(props);
    }
  }, [mapLayers]);
  return (
    <FeatureGroup>
      <EditControl
        position="topright"
        onEdited={onEdited}
        onCreated={onCreated}
        onDeleted={(e) => {
          console.log("Deleting");
          console.log(e);
          props.setter([]);
        }}
        draw={{
          rectangle: false,
          polyline: false,
          circle: false,
          marker: false,
          circlemarker: false,
        }}
      />
      <Circle center={[51.51, -0.06]} radius={200} />
    </FeatureGroup>
  );
};

export default LeaftletDrawer;
