import {
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap";
import StepWizard from "react-step-wizard";
import areaHelper from "../utilities/area";
import readXlsxFile from "read-excel-file";
import swal from "sweetalert";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import { EMISSION_FACTORS } from "../utilities/stationary";

const FUELS = Object.keys(EMISSION_FACTORS).map((factor, index) => {
  return {
    value: factor,
    label: EMISSION_FACTORS[factor]["label"],
  };
});

const ImportStationary = (props) => {
  const wizardRef = useRef(null);
  const [importData, setImportData] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    console.log("Import modal", props.show);
  }, [props.show]);
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      //   centered
      //   fullscreen={true}
      size="xl"
    >
      <Modal.Header closeButton className="ei-background" closeVariant="white">
        <Modal.Title>
          Import Stationary Source &mdash; {props.region}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ overflow: "hidden", minHeight: "60vh", padding: "30px" }}
      >
        {/* <Stepper /> */}
        <StepWizard
          style={{ overflow: "hidden" }}
          ref={wizardRef}
          setImportData={setImportData}
          importData={importData}
          setSaving={setSaving}
        >
          <UploadFile setImportData={setImportData} importData={importData} />
          <ReviewImport
            setImportData={setImportData}
            importData={importData}
            setSaving={setSaving}
          />
          <CompleteImport
            setImportData={setImportData}
            importData={importData}
            saving={saving}
            updateTable={props.updateTable}
            handleClose={props.handleClose}
            setSaving={setSaving}
          />
        </StepWizard>
      </Modal.Body>
      <Modal.Footer>
        <WizardNav wizardRef={wizardRef} setSaving={setSaving} />
      </Modal.Footer>
    </Modal>
  );
};

const Stepper = (props) => {
  const length = props.steps;
  const inactiveColor = "#e4ebe6";
  const activeColor = "#198754";
  const styles = {
    stepCircle: {
      width: "40px",
      height: "40px",
      backgroundColor: "white",
      borderRadius: 30,
      border: "4px solid " + inactiveColor,
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      display: "flex",
      marginLeft: "-2px",
      zIndex: 9,
    },
    progress: {
      backgroundColor: inactiveColor,
      flexGrow: 1,
      height: "12px",
      marginLeft: "-2px",
    },
    progressBar: {
      backgroundColor: activeColor,
      width: "100%",
      height: "12px",
      transition: "width 1s",
    },
  };
  return (
    <div>
      <center>
        <Stack
          direction="horizontal"
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
          }}
        >
          {[...Array(length).keys()].map((item, index) => {
            let number = item + 1;
            if (item != length - 1) {
              return (
                <div
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={styles.stepCircle}>{number}</div>
                  <div style={styles.progress}>
                    <div style={styles.progressBar} />
                  </div>
                </div>
              );
            } else {
              return (
                <div style={{}}>
                  <div style={styles.stepCircle}>{number}</div>
                </div>
              );
            }
          })}
        </Stack>
      </center>
    </div>
  );
};

const WizardNav = (props) => {
  return (
    <Stack direction="horizontal">
      <Button
        onClick={() => {
          props.wizardRef.current.previousStep();
        }}
        variant="secondary"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        Back
      </Button>
      <Button
        onClick={() => {
          if (props.wizardRef.current.currentStep == 2) {
            // console.log("DELETING INDEX: " + deleteIndex);
            swal({
              text: "Do you want to save the following data?",
              icon: "info",
              buttons: true,
            }).then((willSave) => {
              if (willSave) {
                props.setSaving(true);
                props.wizardRef.current.nextStep();
              } else {
                // nothing to do for now
              }
            });
          } else {
            props.wizardRef.current.nextStep();
          }
        }}
        className="ms-2"
      >
        Next <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </Stack>
  );
};
const UploadFile = (props) => {
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (file) {
      readXlsxFile(file)
        .then((data) => {
          data.shift(); // remove the header
          var computed = data.map((d) => {
            let activityRate = d[5];
            let duration = d[6];
            let sourceType = areaHelper
              .getSourceTypes()
              .find(
                (source) =>
                  source.label.toLowerCase() == d[3].toLocaleLowerCase()
              );
            let fuel = d[4];
            var result = areaHelper.calculate(activityRate, sourceType.value, {
              duration: duration,
              fuel: fuel,
            });
            let updated = d;
            updated[9] = parseFloat(result["co"]).toFixed(3);
            updated[10] = parseFloat(result["nox"]).toFixed(3);
            updated[11] = parseFloat(result["pm"]).toFixed(3);
            updated[12] = parseFloat(result["sox"]).toFixed(3);
            updated[13] = parseFloat(result["voc"]).toFixed(3);

            return updated;
          });
          props.setImportData(computed);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file]);
  return (
    <div>
      <h3>Upload file</h3>
      <p>Preparing your import file</p>
      <ol>
        <li>
          <a href="/stationary-source-import-templatey.csv" download>
            Download Import Template for Stationary Source
          </a>
        </li>
        <li>
          Make sure to enter a valid area source type and activity rate.
          <ul style={{ fontWeight: "bold" }}>
            <li>Year</li>
            <li>Province</li>
            <li>City</li>
            <li>Barangay</li>
            <li>
              Company Name (<i>This is different from the Plant Name</i>
            </li>
            <li>
              Plant Address (<i>This is different from the Plant Address</i>
            </li>
            <li>
              Plant Name (<i>This is different from the Company Name</i>
            </li>
            <li>
              Plant Address (<i>This is different from the Company Address</i>
            </li>
            <li>
              Fuel Type
              <ol>
                {FUELS.map((fuel) => {
                  return <li>{fuel.label}</li>;
                })}
              </ol>
            </li>
            <li>APSI Type</li>
            <li>APSI Capacity</li>
            <li>Coordinates</li>
            <li>Operating Capacity (In Percentage)</li>
            <li>Fuel Rate/Hour</li>
            <li>Operating Hours</li>
            <li>APCD Efficiency</li>
            <li>CO</li>
            <li>NOX</li>
            <li>PM</li>
            <li>SOX</li>
            <li>VOC</li>
          </ul>
        </li>
        <li>
          Pollutants would be automatically computed based on their emissions
          factors
        </li>
      </ol>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Choose a file to import</Form.Label>
        <Form.Control
          type="file"
          style={{ width: "300px" }}
          onChange={(e) => setFile(e.target.files[0])}
        />
      </Form.Group>
    </div>
  );
};
const ReviewImport = (props) => {
  return (
    <div>
      <h3>Review Import</h3>
      <p>Please double check your import data</p>
      <Table style={{ overflowX: "scroll" }} bordered>
        <thead>
          <tr>
            <th>Year</th>
            <th>Province</th>
            <th>Ciy</th>
            <th>Source Type</th>
            <th>Fuel</th>
            <th>A.R.</th>
            <th>Duration</th>
            <th>Address</th>
            <th>UTM</th>
            <th>CO</th>
            <th>NOX</th>
            <th>PM</th>
            <th>SOX</th>
            <th>VOC</th>
            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>
          {props.importData.map((item) => {
            return (
              <tr>
                {[...Array(item.length).keys()].map((index) => {
                  return <td>{item[index]}</td>;
                })}
                {/* <td>
                  <Button variant="danger">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
const CompleteImport = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");

  const region = credentials.region_sess;

  const [totalRows, setTotalRows] = useState([]);
  const [imported, setImported] = useState(0);
  const [progressWidth, setProgressWidth] = useState("0%");
  const saveAll = async () => {
    let done = await Promise.all(
      props.importData.map(async (row) => {
        let generalInfo = {
          year: row[0],
          province: row[1],
          city: row[2],
          region,
        };
        let areaSources = [
          {
            source_type: row[3],
            co: row[9],
            nox: row[10],
            pm: row[11],
            sox: row[12],
            voc: row[13],
            coordinates: row[8],
            activityRate: row[5],
            duration: row[6],
            fuel: row[4],
            address: row[7],
            sourceType: row[3],
          },
        ];
        try {
          let response = await fetch(CONSTANTS.API_ROOT + "/area/addaction", {
            method: "POST",
            body: JSON.stringify({ areaSources, generalInfo, credentials }),
          });
          setImported((prev) => prev + 1);
          console.log(response);
        } catch (error) {
          console.log(error);
        }
        return true;
      })
    );
    swal({
      text: "Import Success",
      icon: "success",
    }).then(() => {
      props.handleClose(false);
      props.updateTable();
      props.setImportData([]);
      props.setSaving(false);
    });
  };
  useEffect(() => {
    if (props.saving) {
      console.log("Saving this shit now");

      try {
        saveAll();
      } catch (error) {
        console.log(error);
      }
    }
  }, [props.saving]);

  useEffect(() => {
    let width = parseInt((imported / totalRows) * 100) + "%";
    setProgressWidth(width);
  }, [imported]);
  useEffect(() => {
    setTotalRows(props.importData.length);
    console.log(totalRows);
  }, [props.importData]);
  return (
    <div>
      <h3>Completing Import</h3>
      <p>We are now saving your imported file.</p>
      <div
        style={{
          height: "30px",
          backgroundColor: "#2a2a2a20",
          borderRadius: 20,
          marginTop: "100px",
        }}
      >
        <div
          style={{
            height: "30px",
            width: progressWidth,
            backgroundColor: "#198754",
            borderRadius: 20,
            transition: "width 0.5s",
          }}
        ></div>
      </div>
      <h5 style={{ textAlign: "center", marginTop: 20 }}>
        {imported}/{totalRows} Imported
      </h5>
    </div>
  );
};
export default ImportStationary;
