import {
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCircleInfo,
  faClock,
  faClose,
  faSpinner,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useReducer, useState } from "react";
import {
  Accordion,
  Alert,
  Card,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Stack,
  Table,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import BackButton from "../components/BackButton";
import Legend from "../components/Legend";
import ModuleContainer from "../components/ModuleContainer";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import approvalHelper, { FLAG_APPROVER } from "../utilities/approval";
import CONSTANTS from "../utilities/constants";
import ApprovalBanner from "./approval-components/ApprovalBanner";
import ApprovalProgress from "./approval-components/ApprovalProgress";
import ApproveButton from "./approval-components/ApproveButton";
import RejectionModal from "./approval-components/RejectionModal";

const MobileSourcesView = () => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  let { id, generalId } = useParams();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [mobileData, setMobileData] = useState({});
  const [loading, setLoading] = useState(true);
  const getMobileData = async () => {
    let response = await fetch(
      CONSTANTS.API_ROOT + "/mobile/get/" + generalId,
      {
        method: "POST",
        body: JSON.stringify({
          credentials,
        }),
      }
    );
    let data = await response.json();
    console.log(data);
    setMobileData(data);
    setLoading(false);
  };
  useEffect(() => {
    getMobileData();
  }, []);
  return (
    <ModuleContainer region={"National Capital Region"}>
      <Container fluid>
        <BackButton />
        <ApprovalBanner
          id={generalId}
          source="mobile"
          refresher={forceUpdate}
        />
        <Row>
          <Col md={9}>
            <Card style={{ minHeight: "700px" }}>
              <Card.Header>
                <h5> Mobile Source Preview</h5>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <Row>
                    <Col className="text-center">
                      <FontAwesomeIcon className="fa-spin" icon={faSpinner} />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Row>
                      <Col md={3}>
                        <Form.Label>
                          Year : <b>{mobileData["general_data"][0]["year"]}</b>
                        </Form.Label>
                      </Col>
                      <Col md={3}>
                        <Form.Label>
                          Province:
                          <b> {mobileData["general_data"][0]["province"]}</b>
                        </Form.Label>
                      </Col>
                    </Row>
                    <hr />
                    {mobileData.vehicle_data.map((mobile, index) => {
                      return <MobileCard {...mobile} index={index} />;
                    })}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <ApprovalProgress
              id={generalId}
              source="mobile"
              refresher={forceUpdate}
            />
          </Col>
        </Row>
      </Container>
    </ModuleContainer>
  );
};

const MobileCard = (props) => {
  return (
    <Accordion defaultActiveKey={props.index} className="mt-3">
      <Accordion.Item eventKey={props.index}>
        <Accordion.Header>
          {"Mobile Source " + parseInt(props.index + 1)}
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>Vehicle Type</th>
                    <th># of Vehicle</th>
                    <th>Fuel Type</th>
                    <th>VKT</th>
                    <th>Sulfur %</th>
                    <th>Trip/day</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-capitalize">{props.vehicle_type}</td>
                    <td>{props.no_vehicle}</td>
                    <td className="text-capitalize">{props.fuel_type}</td>
                    <td>{props.vkt}</td>
                    <td>{props.percent_sulfur}</td>
                    <td>{props.trip}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th colSpan={6}>Pollutants</th>
                  </tr>
                  <tr>
                    <th>CO (Tons/Year)</th>
                    <th>NOX (Tons/Year)</th>
                    <th>PM (Tons/Year)</th>
                    <th>SOX (Tons/Year)</th>
                    <th>TOG (Tons/Year)</th>
                    <th>Total (Tons/Year)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{props.co}</td>
                    <td>{props.nox}</td>
                    <td>{props.pm}</td>
                    <td>{props.sox}</td>
                    <td>{props.voc}</td>
                    <td>
                      {parseFloat(props.co) +
                        parseFloat(props.nox) +
                        parseFloat(props.pm) +
                        parseFloat(props.sox) +
                        parseFloat(props.voc)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};


export default MobileSourcesView;
