import { Stack, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import useLocalStorage from "../custom-hooks/useLocalStorage";

import Select from "react-select";

const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");

const YEARS = [...Array(20).keys()].map((item) => {
  return {
    label: 2022 - item,
    value: 2022 - item,
  };
});
var SELECTABLE_REGIONS = REGIONS.map((r) => {
  return {
    label: r.long,
    value: r.key,
  };
});
SELECTABLE_REGIONS.unshift({
  label: "All",
  value: "",
});
const CommonFilter = (props) => {
  const [credentials] = useLocalStorage("credentials");
  var finalRegion = ["NCR", "CAR", "ARMM"].includes(credentials.region_sess)
    ? credentials.region_sess
    : `Region ${credentials.region_sess}`;
  if (credentials.region_sess == "central") {
    finalRegion = "NCR";
  }
  const [region, setRegion] = useState(finalRegion);

  const [selectedRegion, setSelectedRegion] = useState(SELECTABLE_REGIONS[0]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [year, setYear] = useState(YEARS[0]);

  useEffect(() => {
    if (props.handler) {
      props.handler({
        region,
        year,
        province: selectedProvince,
        city: selectedCity,
      });
    }
  }, [selectedProvince, selectedCity, year]);

  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    setProvinces([{ label: "All", value: "all" }, ...formattedProvinces]);
    setSelectedProvince({ label: "All", value: "all" });
    setSelectedCity({ label: "All", value: "all" });
  }, [region]);

  useEffect(() => {
    let provinceDetails = PROVINCES.find(
      (p) => p.provDesc == selectedProvince.value
    );
    if (!provinceDetails) {
      return;
    }
    let provinceCode = provinceDetails["provCode"];
    let cities = CITIES.filter((c) => c.provCode === provinceCode);
    let formattedCities = cities.map((city) => {
      return {
        value: city["citymunDesc"],
        label: city["citymunDesc"],
      };
    });
    setCities([{ label: "All", value: "all" }, ...formattedCities]);
    setSelectedCity({ label: "All", value: "all" });
  }, [selectedProvince]);

  return (
    <Stack
      direction="horizontal"
      gap={3}
      style={{
        alignItems: "center",
        zIndex: 999,
        position: "relative",
      }}
    >
      <Form.Label>Year:</Form.Label>
      <div style={{ width: "150px" }}>
        <Select
          options={YEARS}
          value={year}
          onChange={(e) => {
            setYear(e);
          }}
        />
      </div>

      {credentials.region_sess == "central" && (
        <>
          <Form.Label>Region:</Form.Label>
          <div style={{ width: "150px" }}>
            <Select
              options={SELECTABLE_REGIONS}
              value={selectedRegion}
              onChange={(e) => {
                setSelectedRegion(e);
                var finalRegion = ["NCR", "CAR", "ARMM"].includes(
                  e.value
                )
                  ? e.value
                  : `Region ${e.value}`;
                setRegion(finalRegion);
              }}
            />
          </div>
        </>
      )}
      <Form.Label>Province:</Form.Label>
      <div style={{ width: "150px" }}>
        <Select
          options={provinces}
          value={selectedProvince}
          onChange={(e) => {
            setSelectedProvince(e);
          }}
        />
      </div>
      <Form.Label>City/Municipality:</Form.Label>
      <div style={{ width: "200px" }}>
        <Select
          options={cities}
          value={selectedCity}
          onChange={(e) => setSelectedCity(e)}
        />
      </div>
    </Stack>
  );
};

export default CommonFilter;
