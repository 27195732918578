import {
  Accordion,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  Stack,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import helper from "../utilities/helper";
import Select from "react-select";
import { useEffect, useState } from "react";
import EditPlantForm from "./EditPlantForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import {
  faBuilding,
  faCheckCircle,
  faEdit,
  faExclamationTriangle,
  faIndustry,
  faInfo,
  faInfoCircle,
  faPlus,
  faSearch,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import stationaryHelper from "../utilities/stationary";
import CONSTANTS from "../utilities/constants";

const ORM_ENDPOINT = "https://lqgbfqhyin.alapaapp.tech/api/orm/searchPto/";
const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");
const INDUSTRIES = require("../utilities/json/industries.json");

const DEFAULT_REGION = "NCR"; // hardcoded for now

const EditStationary = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [show, setShow] = useState(false);
  const finalRegion = ["NCR", "CAR", "ARMM"].includes(props.region)
    ? props.region
    : `Region ${props.region}`;
  const [region, setRegion] = useState(finalRegion);
  const [industries, setIndustries] = useState(INDUSTRIES);
  const [generalDetails, setGeneralDetails] = useState({});
  const [ptoSearching, setPtoSearching] = useState(false);
  const [ptoNumber, setPtoNumber] = useState("");
  const [barangay, setBarangay] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [year, setYear] = useState("2022");
  const [selectedProvince, setSelectedProvince] = useState({
    value: finalRegion,
    label: finalRegion,
  });
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [ptoSearchNote, setPtoSearchNote] = useState({
    type: "secondary",
    text: "Search for PTO #",
    icon: faInfoCircle,
  });
  const [opmsPollutants, setOpmsPollutants] = useState([]);

  const [plants, setPlants] = useState([]);

  const updatePlantList = (index, data) => {
    let plantCopies = [...plants];
    plantCopies[index] = { ...plantCopies[index], ...data };
    setPlants(plantCopies);
  };
  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // console.log("REG CODE: :" + regionCode);
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    setProvinces(formattedProvinces);
    setSelectedProvince(formattedProvinces[0]);
  }, [region]);

  useEffect(() => {}, []);

  useEffect(() => {
    try {
			// console.log(selectedProvince);
			let provinceDetails = PROVINCES.find(
				(p) => p.provDesc == selectedProvince.value
			);
			if (!provinceDetails) {
				return;
			}
			// console.log("PORovinde details: ");
			// console.log(provinceDetails);
			let provinceCode = provinceDetails["provCode"];
			let cities = CITIES.filter((c) => c.provCode === provinceCode);
			let formattedCities = cities.map((city) => {
				return {
					value: city["citymunDesc"],
					label: city["citymunDesc"]
				};
			});
			setCities(formattedCities);
		} catch (error) {
      
    }
  }, [selectedProvince]);

  const deletePlant = (deleteIndex) => {
    // console.log("DELETING INDEX: " + deleteIndex);
    swal({
      title: "Are you sure to delete this plant?",
      text: "The details that you have entered will be lost",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setPlants((prev) => {
          let filtered = prev.filter((item, index) => index !== deleteIndex);
          return filtered;
        });
      } else {
        // nothing to do for now
      }
    });
  };

  const searchPto = async () => {
    setPtoSearching(true);
    setPlants([]);
    var search = ptoNumber.trim(); //remove the spaces on left and right
    try {
      let resp = await fetch(ORM_ENDPOINT + search);
      let data = await resp.json();
      let plants = JSON.parse(data);

      let stackSamplingDetails = plants.filter((p) => p.c && p.q);
      let stackSamplingPollutants = stackSamplingDetails.map((item) => {
        return {
          concentration: item.c,
          flowrate: item.q,
          pollutant: stationaryHelper.identifyPollutant(item.name),
          value: 0, //initial value
        };
      });
      setOpmsPollutants({
        co: stackSamplingPollutants.find((p) => p.pollutant == "co"),
        sox: stackSamplingPollutants.find((p) => p.pollutant == "sox"),
        nox: stackSamplingPollutants.find((p) => p.pollutant == "nox"),
        pm: stackSamplingPollutants.find((p) => p.pollutant == "pm"),
        voc: stackSamplingPollutants.find((p) => p.pollutant == "voc"),
      });
      // if none found, return
      if (plants.length == 0) {
        setPtoSearching(false);
        setPtoSearchNote({
          type: "danger",
          text: "No record found for the given PTO #",
          icon: faExclamationTriangle,
        });
        return;
      }

      setPlants(plants);
      // auto populate the general details
      var plant = plants[0];
      var finalRegion =
        plant["EMB Region"] === "National Capital Region"
          ? "NCR"
          : plant["EMB Region"];

      setRegion(finalRegion);

      setSelectedProvince({
        value: plant["Province"].includes("NCR") ? "NCR" : plant["Province"],
        label: plant["Province"].includes("NCR") ? "NCR" : plant["Province"],
      });
      setSelectedCity({
        value: plant["City/Municipality"],
        label: plant["City/Municipality"],
      });
      setBarangay(plant["Barangay"]);
      // for industry, check first if in list
      var industry = plant["Business Nature"];
      // console.log("OPMS Industry: " + industry);
      var exists = industries.find((i) => i.value == industry);
      // if exists, automatically else, else. add the nature to the list then select
      if (exists) {
        setSelectedIndustry(exists);
      } else {
        setIndustries((prev) => [
          ...prev,
          { value: industry, label: industry },
        ]);
        setSelectedIndustry({ value: industry, label: industry });
      }

      setGeneralDetails((prev) => {
        return {
          ...prev,
          companyName: plant["Name of Establishment"],
          address:
            plant["Office Address"] +
            ", " +
            plant["City/Municipality"] +
            ", " +
            plant["Province"],
        };
      });

      setPtoSearching(false);
      setPtoSearchNote({
        type: "success",
        text: "Details found for " + plant["Region"],
        icon: faCheckCircle,
      });
    } catch (error) {
      // console.log(error);
      setPtoSearchNote({
        type: "danger",
        text: "Error searching the PTO #",
        icon: faExclamationTriangle,
      });
      setPtoSearching(false);
    }
  };

  const addStationarySources = () => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to save these Stationary Sources?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        let generalInfo = {
          year,
          ptoNumber,
          province: selectedProvince["value"],
          city: selectedCity["value"],
          barangay,
          companyName: generalDetails["companyName"],
          address: generalDetails["address"],
          industry: selectedIndustry["value"],
          region,
        };
        fetch(CONSTANTS.API_ROOT + "/stationary/addaction", {
          method: "POST",
          body: JSON.stringify({ plants, generalInfo, credentials }),
        })
          .then(async (data) => {
            // const list = await data.json();
            // console.log(list);
            props.updateTable();
            props.handleClose(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // nothing to do for now
      }
    });
  };

  const getStationarySources = async () => {
    const resp = await fetch(
      CONSTANTS.API_ROOT + "/stationary/get/" + props.rowData.id
    );
    const data = await resp.json();
    const sources = data.plants.map(p => {
      return {
        plant_name: p["plant_name"],
        plant_address: p["plant_address"],
        ...p["apsi"][0],
      };
    })
    setPlants(sources);
    console.log("STATIONARY DATA", sources);
    const generalData = data["general_data"][0];
    console.log(generalData);
    setGeneralDetails((prev) => {
      return {
        ...prev,
        companyName: generalData["company"],
        address: generalData["address"],
      };
    });
    setBarangay(generalData['barangay']);
    const industry = generalData["other_industry_type"];
    var exists = industries.find((i) => i.value == industry);
    // if exists, automatically else, else. add the nature to the list then select
    if (exists) {
      setSelectedIndustry(exists);
    } else {
      setIndustries((prev) => [...prev, { value: industry, label: industry }]);
      setSelectedIndustry({ value: industry, label: industry });
    }
    setSelectedCity({
      value: generalData["city"],
      label: generalData["city"],
    });
  };
  useEffect(() => {
    console.log("On Edit Stationary", props);
    getStationarySources();
  }, []);
  return (
    <>
      <Button
        variant="info"
        className="text-white d-block"
        onClick={() => {
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <span className="ms-2">Edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        fullscreen={true}
        style={{ paddingLeft: "0px !important" }}
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            Add Stationary Source &mdash; {finalRegion}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>
              <Card style={{ minHeight: "100vh" }}>
                <Card.Body>
                  <Card.Title>General Details</Card.Title>
                  <hr />
                  <Form.Label>Year:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="e.g. 2022"
                    max={2020}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                  />

                  <Form.Label className="mt-3">PTO #:</Form.Label>
                  <Form.Control
                    placeholder="e.g. PTO-OL-R12-*"
                    onChange={(e) => {
                      setPtoNumber(e.target.value);
                    }}
                  />
                  <Stack direction="horizontal" className="mt-1">
                    <Form.Label className={"text-" + ptoSearchNote.type}>
                      <FontAwesomeIcon
                        icon={ptoSearchNote.icon ?? faInfoCircle}
                        className="me-1"
                      />
                      {ptoSearchNote.text}
                    </Form.Label>
                    <Button
                      variant="success"
                      className="ms-auto"
                      onClick={searchPto}
                    >
                      <FontAwesomeIcon
                        className={ptoSearching ? "me-1 fa-spin" : "me-1"}
                        icon={ptoSearching ? faSpinner : faSearch}
                      />
                      {ptoSearching ? "Searching" : "Search"}
                    </Button>
                  </Stack>
                  <Form.Label className="mt-3">Province:</Form.Label>
                  <Select
                    options={provinces}
                    value={selectedProvince}
                    onChange={(e) => {
                      setSelectedProvince(e);
                    }}
                  />
                  <Form.Label className="mt-3">City/Municipality:</Form.Label>
                  <Select
                    options={cities}
                    value={selectedCity}
                    onChange={(e) => {
                      setSelectedCity(e);
                    }}
                  />

                  <Form.Label className="mt-3">Barangay:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Project 6*"
                    value={barangay}
                    onChange={(e) => {
                      setBarangay(e.target.value);
                    }}
                  />

                  <Form.Label className="mt-3">Name of Company:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Alapaapp Inc."
                    value={generalDetails.companyName ?? ""}
                    onChange={(e) =>
                      setGeneralDetails((prev) => {
                        return {
                          ...prev,
                          companyName: e.target.value,
                        };
                      })
                    }
                  />
                  <Form.Label className="mt-3">Address:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Visayas Ave, Quezon City"
                    value={generalDetails.address ?? ""}
                    onChange={(e) =>
                      setGeneralDetails((prev) => {
                        return {
                          ...prev,
                          address: e.target.value,
                        };
                      })
                    }
                  />
                  <Form.Label className="mt-3">Industry:</Form.Label>
                  <Select
                    value={selectedIndustry}
                    options={industries}
                    onChange={(e) => {
                      setSelectedIndustry(e);
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={9}>
              <Card style={{ height: "100vh", overflowY: "scroll" }}>
                <Card.Body>
                  <Card.Title>Air Pollution Source Installations</Card.Title>
                  <hr style={{ borderBottom: "1px solid #2A3F54" }} />
                  <Stack>
                    {plants.length == 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "40vh",
                          flexDirection: "column",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBuilding}
                          style={{ fontSize: 50 }}
                        />
                        <h4 className="mt-2">No APSI Found.</h4>
                        <h5>
                          You can try searching using the PTO # or click the
                          button bellow.
                        </h5>
                        <Button
                          variant="success"
                          className="mt-4"
                          onClick={() => {
                            setPlants((prev) => [
                              ...prev,
                              {
                                "Name of Plant":
                                  "APSI " + parseInt(prev.length + 1),
                              },
                            ]);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlus} /> Add APSI
                        </Button>
                      </div>
                    ) : (
                      <Stack>
                        {/* <Row className="mb-4 reverse">
                        <Col xs={9}></Col>
                        <Col xs={3}>
                          <Button
                            variant="success"
                            className="mt-4 float-end"
                            onClick={() => {
                              setPlants((prev) => [
                                ...prev,
                                {
                                  "Name of Plant":
                                    "Plant " + parseInt(prev.length + 1),
                                },
                              ]);
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Add Plant
                          </Button>
                        </Col>
                      </Row> */}
                        {plants.map((item, index) => (
                          <>
                            <Row>
                              <Col xs={12}>
                                <EditPlantForm
                                  key={"stationary-source-" + index}
                                  index={index}
                                  updatePlantList={updatePlantList}
                                  opmsPollutants={opmsPollutants}
                                  {...item}
                                  deleteAction={
                                    <Button
                                      variant="danger"
                                      className="mt-2"
                                      onClick={() => deletePlant(index)}
                                    >
                                      <FontAwesomeIcon icon={faTrash} /> Delete
                                    </Button>
                                  }
                                />
                              </Col>
                            </Row>
                          </>
                        ))}
                        <Row>
                          <Col md={12} className="text-center">
                            <Button
                              variant="success"
                              className="mt-4"
                              onClick={() => {
                                setPlants((prev) => [
                                  ...prev,
                                  {
                                    "Name of Plant":
                                      "APSI " + parseInt(prev.length + 1),
                                  },
                                ]);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} /> Add APSI
                            </Button>
                          </Col>
                        </Row>
                      </Stack>
                    )}
                  </Stack>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={addStationarySources}>
            Add Sources
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditStationary;
