import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { CSVLink, CSVDownload } from "react-csv";
import swal from "sweetalert";



const ExcelExport = (props) => {
  return (
    <CSVLink data={props.data} target="_blank" filename={props.filename} onClick={()=>{
        if(props.data.length == 0) {
            swal({
                icon:'error',
                text:'Oops! You have empty data to export'
            })
            return false;
        }
    }}>
      <Button variant="success">
        <FontAwesomeIcon icon={faDownload} />
        <span className="ms-1">Download</span>
      </Button>
    </CSVLink>
  );
};


export default ExcelExport;