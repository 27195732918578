import {
  faCopy,
  faDownload,
  faList,
  faPencil,
  faPlus,
  faTrash,
  faUpload,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Row,
  Col,
  Stack,
  Form,
  Modal,
  Container,
  Alert,
  Accordion,
} from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import swal from "sweetalert";
import ModuleContainer from "../components/ModuleContainer";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import AddStationaryEF from "./settings-components/AddStationaryEF";
import AddUser from "./settings-components/AddUser";
import EditMobileEF from "./settings-components/EditMobileEF";
import EditStationaryEF from "./settings-components/EditStationaryEF";
import EditUser from "./settings-components/EditUser";
import ViewMobileEF from "./settings-components/ViewMobileEF";
import ViewStationaryEF from "./settings-components/ViewStationaryEF";
const Settings = () => {
  const [credentials] = useLocalStorage("credentials");
  return (
    <ModuleContainer>
      <Tabs>
        <TabList>
          <Tab>My Account</Tab>
          <Tab>Users</Tab>
          {credentials.region_sess == "central" && (
            <>
              <Tab>Stationary Sources</Tab>
              <Tab>Area Sources</Tab>
              <Tab>Mobile Sources</Tab>
            </>
          )}
        </TabList>
        <TabPanel>
            <MyAccount/>
        </TabPanel>
        <TabPanel>
          <UsersSettings />
        </TabPanel>
        <TabPanel>
          <StationarySourceSettings />
        </TabPanel>
        <TabPanel>
          <AreaSourceSettings />
        </TabPanel>
        <TabPanel>
          <MobileSourceSettings />
        </TabPanel>
      </Tabs>
    </ModuleContainer>
  );
};

const MOBILE_EF_TEMPLATE = {
  coldstart: {
    description: "Coldstart Emission Factors",
    fuels: {
      gas: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
      diesel: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
    },
  },
  hotstart: {
    description: "HotStart Emission Factors",
    fuels: {
      gas: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
      diesel: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
    },
  },
  running_emission: {
    description: "Running Emission Factors",
    fuels: {
      gas: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
      diesel: {
        co: 0,
        nox: 0,
        pm: 0,
        sox: 0,
        voc: 0,
        tog: 0,
      },
    },
  },
  hotsoak: {
    description: "Hotsoak Emission Factor",
    tog: 0,
  },
  diurnal: {
    description: "Diurnal Emission Factor",
    tog: 0,
  },
  erl: {
    description: "Running Loss Emission Factor",
    tog: 0,
  },
};

const MyAccount = () => {
  const [loading, setLoading] = React.useState(false);
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNew] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const changePassword = async () => {
    if (oldPassword.trim() == "") {
      alert("Please enter an old password");
      setLoading(false);
      return;
    }
    if(newPassword.trim() == "") {
      alert("Please enter new password");
      setLoading(false);
      return;
    }
    if (confirm.trim() == "") {
      alert("Please confirm new password");
      setLoading(false);
      return;
    }
    if(newPassword.trim() != confirm.trim()) {
      alert('Password confirmation mismatch');
      setLoading(false);
      return;
    }
    //1. check if old password is correct
    const userId = credentials["user_id"];
    const resp = await fetch(
      `${CONSTANTS.API_ROOT}/Users/checkPassword/${userId}/${oldPassword}`
    );
    const data = await resp.json();
    if(!data['matched']) {
      alert('Invalid old password');
      setLoading(false);
      return;
    }
      const updateResp = await fetch(
        `${CONSTANTS.API_ROOT}/Users/updatePassword/${userId}/${newPassword}`
      );
      const updateJson = await updateResp.json();
      alert("Password successfully updated");
      setLoading(false);
      window.location.reload();
    //2. new and confirm new are matched
    //3. update the password on the backend
  }
  return (
    <Row>
      <Col md={6} className="mt-2">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Change Password</Accordion.Header>
            <Accordion.Body>
              <Form.Group>
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <Form.Label className="mt-2">New Password</Form.Label>
                <Form.Control 
                  type="password" 
                  onChange={(e) => setNew(e.target.value)}/>
                <Form.Label className="mt-2">Confirm New Password</Form.Label>
                <Form.Control
                  type="password"  
                  onChange={(e) => setConfirm(e.target.value)}/>
                <Button
                  onClick={changePassword}
                  className="mt-2"
                  disabled={loading}
                >
                  {loading ? "Please wait..." : "Change Password"}
                </Button>
              </Form.Group>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
}
const UsersSettings = () => {
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const [existingUsers, setExistingUsers] = useState([]);
  const [missingRoles, setMissingRoles] = useState([]);
  const ROLES = [
    {
      name: "Preparer",
      value: 1,
    },
    {
      name: "Reviewer",
      value: 2,
    },
    {
      name: "Recommending Approval",
      value: 3,
    },
    {
      name: "Approval",
      value: 4,
    },
  ];
  const getUsers = async () => {
    fetch(CONSTANTS.API_ROOT + "/Users/list/" + credentials.region_sess)
      .then(async (data) => {
        var list = await data.json();
        setExistingUsers(list);
        // identify the roles;
        let requiredRoles = ROLES.map((r) => r.value);
        let existingRoles = list.map((user) => {
          return parseInt(user.updated_role);
        });
        let missingRolesKey = requiredRoles.filter(
          (r) => !existingRoles.includes(r)
        );
        let missingRoles = missingRolesKey.map((k) =>
          ROLES.find((r) => r.value == k)
        );
        setMissingRoles(missingRoles);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteUser = async (id) => {
    try {
      swal({
        //  title: "Are you sure to save these Stationary Sources?",
        text: "Are you sure to delete user?",
        icon: "warning",
        buttons: true,
        // dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          let resp = await fetch(CONSTANTS.API_ROOT + "/Users/delete/" + id);
          let json = await resp.json();
          getUsers();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <Row className="mt-5">
      {missingRoles.length > 0 && (
        <Alert variant="danger">
          <h6>
            <FontAwesomeIcon icon={faWarning} color="black" />
            <span className="ms-1">
              This region is missing the following user role:
            </span>
            <ol className="mt-2">
              {missingRoles.map((role) => {
                return <li>{role.name}</li>;
              })}
            </ol>
          </h6>
        </Alert>
      )}
      <Col md={12} className="module-actions">
        <Stack direction="horizontal" gap={2} className="float-end">
          <AddUser updateUsers={getUsers} />
        </Stack>
      </Col>
      <Col md={12} className="mt-3">
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email Name</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {existingUsers.map((user, index) => {
              return (
                <tr>
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.email}</td>
                  <td>
                    {
                      ROLES.find((r) => parseInt(r.value) == user.updated_role)
                        .name
                    }
                  </td>
                  <td className="text-end">
                    <EditUser {...user} updateUsers={getUsers} />
                    <Button
                      variant="danger"
                      className="ms-1"
                      onClick={() => {
                        deleteUser(user.user_id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
const StationarySourceSettings = () => {
  const [existingFactors, setExistingFactors] = useState([]);
  const getExistingFactors = () => {
    fetch(CONSTANTS.API_ROOT + "/StationaryFactors/get")
      .then(async (data) => {
        var list = await data.json();
        console.log(list);
        setExistingFactors(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteStationary = (id) => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to save this Emission Factor?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(CONSTANTS.API_ROOT + "/StationaryFactors/delete/" + id)
          .then(async (data) => {
            getExistingFactors();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    getExistingFactors();
  }, []);
  return (
    <Row className="mt-5">
      <Col md={12} className="module-actions">
        <Stack direction="horizontal" gap={2} className="float-end">
          <AddStationaryEF />
        </Stack>
      </Col>
      <Col md={12} className="mt-3">
        <Table>
          <thead>
            <tr>
              <th>Fuel Type</th>
              <th>Ash Content</th>
              <th>Sulfur Content</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {existingFactors.map((factor) => {
              return (
                <tr>
                  <td>{factor.fuel_name}</td>
                  <td>{factor.ash_content}%</td>
                  <td>{factor.sulfur_content}%</td>
                  <td>
                    <ViewStationaryEF {...factor} />
                    <EditStationaryEF
                      {...factor}
                      className="ms-1"
                      getExistingFactors={getExistingFactors}
                    />
                    <Button
                      variant="danger"
                      className="ms-1"
                      onClick={() => {
                        deleteStationary(factor.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> <span> Delete</span>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};
const MobileSourceSettings = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [sourceName, setSourceName] = useState("");
  const [existingFactors, setExistingFactors] = useState([]);
  const [emissionFactorData, setEmissionFactorData] =
    useState(MOBILE_EF_TEMPLATE);

  const saveMobile = async () => {
    const payload = JSON.stringify({ emissionFactorData, sourceName });
    try {
      let response = await fetch(CONSTANTS.API_ROOT + "/MobileFactors/add", {
        method: "POST",
        body: payload,
      });
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor saved successfully",
        icon: "success",
      }).then(() => {
        setShowAdd(false);
        getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMobile = (id) => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to save this Emission Factor?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(CONSTANTS.API_ROOT + "/MobileFactors/delete/" + id)
          .then(async (data) => {
            getExistingFactors();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const getExistingFactors = () => {
    fetch(CONSTANTS.API_ROOT + "/MobileFactors/get")
      .then(async (data) => {
        var list = await data.json();
        console.log(list);
        setExistingFactors(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getExistingFactors();
  }, []);

  return (
    <>
      <Row className="mt-5">
        <Col md={2}>
          <h5>Emission Factors</h5>
        </Col>
        <Col md={12} className="module-actions">
          <Stack direction="horizontal" gap={2} className="float-end">
            <Button variant="success" onClick={() => setShowAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ms-1">Add Emission Factor</span>
            </Button>
          </Stack>
        </Col>
        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <th>Source Type</th>
                <th>Added By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {existingFactors.map((factor) => {
                return (
                  <tr>
                    <td>{factor.source_type}</td>
                    <td>John Marie Salcedo</td>
                    <td>
                      <ViewMobileEF {...factor} />
                      <EditMobileEF
                        {...factor}
                        getExistingFactors={getExistingFactors}
                        className="ms-1"
                      />
                      <Button
                        variant="danger"
                        className="ms-1"
                        onClick={() => {
                          deleteMobile(factor.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} /> <span> Delete</span>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Modal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            Add an Emission Factor &mdash; Mobile Sources{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Label>Source Name:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Car / Specific Model"
                    onChange={(e) => setSourceName(e.target.value)}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={12}>
                {Object.keys(emissionFactorData).map((ef, index) => {
                  let details = emissionFactorData[ef];
                  return (
                    <Table>
                      <thead>
                        <tr>
                          <th colSpan={7}>{details["description"]}</th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>Fuel</th>
                          <th>CO</th>
                          <th>NOX</th>
                          <th>PM</th>
                          <th>SOX</th>
                          <th>VOC</th>
                          <th>TOG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {"fuels" in details ? (
                          <>
                            {Object.keys(details["fuels"]).map((fuelKey) => {
                              return (
                                <tr>
                                  <td width="150px">
                                    <Form.Control
                                      value={fuelKey}
                                      disabled
                                      style={{ textTransform: "capitalize" }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["co"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["nox"] =
                                            parseFloat(e.target.value);

                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["pm"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["sox"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["voc"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      placeholder="0.00"
                                      onChange={(e) => {
                                        setEmissionFactorData((prev) => {
                                          let copy = { ...prev };
                                          copy[ef]["fuels"][fuelKey]["tog"] =
                                            parseFloat(e.target.value);
                                          return copy;
                                        });
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <tr>
                            <td width="150px">
                              <Form.Control value="--" disabled />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("co" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["co"] = parseFloat(e.target.value);
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("nox" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["nox"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("pm" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["pm"] = parseFloat(e.target.value);
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("sox" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["voc"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("voc" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["voc"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                placeholder="0.00"
                                disabled={!("tog" in details)}
                                onChange={(e) => {
                                  setEmissionFactorData((prev) => {
                                    let copy = { ...prev };
                                    copy[ef]["tog"] = parseFloat(
                                      e.target.value
                                    );
                                    return copy;
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger">Cancel</Button>
          <Button onClick={saveMobile}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const AreaSourceSettings = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [fuelDependent, setFuelDependent] = useState(false);
  const [existingFactors, setExistingFactors] = useState([]);
  const [emissionFactors, setEmissionFactors] = useState({
    co: 0,
    nox: 0,
    pm: 0,
    sox: 0,
    voc: 0,
    unit: "tons",
  });
  const [fuels, setFuels] = useState([
    {
      fuelName: "",
      co: 0,
      nox: 0,
      pm: 0,
      sox: 0,
      voc: 0,
      unit: "tons",
    },
  ]);
  const [sourceName, setSourceName] = useState("");

  const updateFuelList = (index, data) => {
    let copies = [...fuels];
    copies[index] = { ...copies[index], ...data };
    setFuels(copies);
  };

  useEffect(() => {
    console.log(fuels);
  }, [fuels]);

  const deleteFuel = (i) => {
    let updated = fuels.filter((item, index) => index != i);
    setFuels(updated);
  };

  useEffect(() => {
    console.log(emissionFactors);
  }, [emissionFactors]);

  const saveArea = async () => {
    const payload = JSON.stringify({ fuels, emissionFactors, sourceName });
    try {
      let response = await fetch(CONSTANTS.API_ROOT + "/areafactors/add", {
        method: "POST",
        body: payload,
      });
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor saved successfully",
        icon: "success",
      }).then(() => {
        setShowAdd(false);
        getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteArea = (id) => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to save this Emission Factor?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(CONSTANTS.API_ROOT + "/areafactors/delete/" + id)
          .then(async (data) => {
            getExistingFactors();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const getExistingFactors = () => {
    fetch(CONSTANTS.API_ROOT + "/AreaFactors/get")
      .then(async (data) => {
        var list = await data.json();
        setExistingFactors(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getExistingFactors();
  }, []);

  return (
    <>
      <Row className="mt-5">
        <Col md={2}>
          <h5>Emission Factors</h5>
        </Col>
        <Col md={12} className="module-actions">
          <Stack direction="horizontal" gap={2} className="float-end">
            <Button variant="success" onClick={() => setShowAdd(true)}>
              <FontAwesomeIcon icon={faPlus} />
              <span className="ms-1">Add Emission Factor</span>
            </Button>
          </Stack>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Table>
          <thead>
            <tr>
              <th>Source Type</th>
              <th>CO</th>
              <th>NOX</th>
              <th>PM</th>
              <th>SOX</th>
              <th>VOC</th>
              <th>Fuels</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {existingFactors.map((item, index) => {
              let efs = JSON.parse(item.emission_factors);
              let fuels = JSON.parse(item.fuels);
              let fuelDependent = fuels[0].fuelName != "";
              return (
                <tr>
                  <td>{item.source_type}</td>
                  <td>{fuelDependent ? "--" : efs.co}</td>
                  <td>{fuelDependent ? "--" : efs.nox}</td>
                  <td>{fuelDependent ? "--" : efs.pm}</td>
                  <td>{fuelDependent ? "--" : efs.sox}</td>
                  <td>{fuelDependent ? "--" : efs.voc}</td>
                  <td>
                    <FuelList
                      fuels={fuels}
                      disabled={fuels[0].fuelName == ""}
                    />
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteArea(item.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> <span> Delete</span>
                    </Button>
                    <EditAreaFactor
                      item_id={item.id}
                      fuels={fuels}
                      emissionFactors={efs}
                      sourceName={item.source_type}
                      fuelDependent={fuelDependent}
                      getExistingFactors={getExistingFactors}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
      <Modal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            Add an Emission Factor &mdash; Area Sources{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Label>Source Name:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Structural Fire"
                    onChange={(e) => setSourceName(e.target.value)}
                  />
                  <Form.Check
                    checked={fuelDependent}
                    type={"switch"}
                    id={`multiple-fuel`}
                    label={`Does this source type emission factor vary on the fuel used?`}
                    style={{ marginTop: "10px" }}
                    onChange={(e) => {
                      setFuelDependent(e.target.checked);
                    }}
                  />
                </Form>
              </Col>
            </Row>
            {fuelDependent ? (
              <Row className="mt-3">
                <h5>Emission Factors</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Fuel</th>
                      <th>Unit</th>
                      <th>CO</th>
                      <th>NOX</th>
                      <th>PM</th>
                      <th>SOX</th>
                      <th>VOC</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuels.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <Form.Control
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  fuelName: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              placeholder="e.g. Tons"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  unit: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  co: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  nox: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  pm: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  sox: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  voc: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                deleteFuel(index);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Button
                  style={{ marginLeft: "auto", width: "180px" }}
                  variant="success"
                  onClick={() => {
                    setFuels((f) => [
                      ...f,
                      {
                        fuelName: "",
                        co: 0,
                        nox: 0,
                        pm: 0,
                        sox: 0,
                        voc: 0,
                        unit: "tons",
                      },
                    ]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span> Add Fuel</span>
                </Button>
              </Row>
            ) : (
              <Row className="mt-3">
                <h5>Emission Factors</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>CO</th>
                      <th>NOX</th>
                      <th>PM</th>
                      <th>SOX</th>
                      <th>VOC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["co"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                co: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["nox"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                nox: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["pm"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                pm: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["sox"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                sox: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["voc"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                voc: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger">Cancel</Button>
          <Button onClick={saveArea}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const FuelList = (props) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button onClick={() => setShow(true)} disabled={props.disabled}>
        View Fuels
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Fuel List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Fuel Name</th>
                <th>Unit</th>
                <th>CO</th>
                <th>NOX</th>
                <th>PM</th>
                <th>SOX</th>
                <th>VOC</th>
              </tr>
            </thead>
            <tbody>
              {props.fuels.map((item) => {
                return (
                  <tr>
                    <td>{item.fuelName}</td>
                    <td>{(item.unit ?? "Tons").toUpperCase()}</td>
                    <td>{item.co}</td>
                    <td>{item.nox}</td>
                    <td>{item.pm}</td>
                    <td>{item.sox}</td>
                    <td>{item.voc}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

const EditAreaFactor = (props) => {
  const itemId = props.item_id;
  const [show, setShow] = useState(false);
  const [fuelDependent, setFuelDependent] = useState(props.fuelDependent);
  const [existingFactors, setExistingFactors] = useState([]);
  const [emissionFactors, setEmissionFactors] = useState(props.emissionFactors);
  const [fuels, setFuels] = useState(props.fuels);
  const [sourceName, setSourceName] = useState(props.sourceName);

  const updateFuelList = (index, data) => {
    let copies = [...fuels];
    copies[index] = { ...copies[index], ...data };
    setFuels(copies);
  };

  useEffect(() => {
    console.log(fuels);
  }, [fuels]);

  const deleteFuel = (i) => {
    let updated = fuels.filter((item, index) => index != i);
    setFuels(updated);
  };

  useEffect(() => {
    console.log(emissionFactors);
  }, [emissionFactors]);

  const saveArea = async () => {
    const payload = JSON.stringify({
      fuels,
      emissionFactors,
      sourceName,
      itemId,
    });
    try {
      let response = await fetch(
        CONSTANTS.API_ROOT + "/areafactors/update/" + itemId,
        {
          method: "POST",
          body: payload,
        }
      );
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor updated successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        props.getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button variant="success" className="ms-1" onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faPencil} /> <span> Edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            Update an Emission Factor &mdash; Area Sources{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Container>
            <Row>
              <Col md={6}>
                <Form>
                  <Form.Label>Source Name:</Form.Label>
                  <Form.Control
                    placeholder="e.g. Structural Fire"
                    onChange={(e) => setSourceName(e.target.value)}
                    value={sourceName}
                  />
                  <Form.Check
                    checked={fuelDependent}
                    type={"switch"}
                    id={`multiple-fuel`}
                    label={`Does this source type emission factor vary on the fuel used?`}
                    style={{ marginTop: "10px" }}
                    onChange={(e) => {
                      setFuelDependent(e.target.checked);
                    }}
                  />
                </Form>
              </Col>
            </Row>
            {fuelDependent ? (
              <Row className="mt-3">
                <h5>Emission Factors</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>Fuel</th>
                      <th>Unit</th>
                      <th>CO</th>
                      <th>NOX</th>
                      <th>PM</th>
                      <th>SOX</th>
                      <th>VOC</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuels.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <Form.Control
                              value={item.fuelName}
                              placeholder="e.g. 0.0"
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  fuelName: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              placeholder="e.g. Tons"
                              value={item.unit}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  unit: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              value={item.co}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  co: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              value={item.nox}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  nox: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              value={item.pm}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  pm: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              value={item.sox}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  sox: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="e.g. 0.0"
                              value={item.voc}
                              onChange={(e) => {
                                updateFuelList(index, {
                                  ...item,
                                  voc: e.target.value,
                                });
                              }}
                            />
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => {
                                deleteFuel(index);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Button
                  style={{ marginLeft: "auto", width: "180px" }}
                  variant="success"
                  onClick={() => {
                    setFuels((f) => [
                      ...f,
                      {
                        fuelName: "",
                        co: 0,
                        nox: 0,
                        pm: 0,
                        sox: 0,
                        voc: 0,
                        unit: "tons",
                      },
                    ]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <span> Add Fuel</span>
                </Button>
              </Row>
            ) : (
              <Row className="mt-3">
                <h5>Emission Factors</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>CO</th>
                      <th>NOX</th>
                      <th>PM</th>
                      <th>SOX</th>
                      <th>VOC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["co"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                co: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["nox"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                nox: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["pm"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                pm: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["sox"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                sox: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="number"
                          placeholder="e.g. 0.0"
                          value={emissionFactors["voc"]}
                          onChange={(e) => {
                            setEmissionFactors((ef) => {
                              return {
                                ...ef,
                                voc: e.target.value,
                              };
                            });
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button onClick={saveArea}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Settings;
