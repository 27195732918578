import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import approvalHelper from "../../../src/utilities/approval";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import CONSTANTS from "../../utilities/constants";

const ApproveBatchButton = (props) => {
  const navigate = useNavigate();
  const [credentials] = useLocalStorage("credentials");
  const shown = ["3", "4"].includes(credentials?.updated_role);
  const approve = async () => {
    try {
      for (const entry of props.selectedRows) {
        const approvalDetails = await approvalHelper.getApprovalDetails(
          entry.id,
          props.source
        );
        const approvalId = approvalDetails.details.id;
        const nextFlag = approvalDetails.nextFlag.key;
        var payload = {
          by: credentials.user_fullname,
          status: "approved",
          nextFlag,
          credentials,
          entry_id: entry.id,
          source: props.source,
        };
        const response = await fetch(
          CONSTANTS.API_ROOT + "/Approval/updateStatus/" + approvalId,
          {
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
      }
      swal({
        text: "Entry has been approved!",
        icon: "info",
      }).then(() => {
        props.refresher();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const approvePrompt = () => {
    swal({
      text: "Do you want to prove these entries?",
      icon: "info",
      buttons: true,
    }).then(async (willSave) => {
      if (willSave) {
        approve();
      }
    });
  };
  if (!shown) return <></>;
  return (
    <Button
      variant="success"
      onClick={approvePrompt}
      className="ms-auto"
      style={{ width: "120px" }}
      {...props}
    >
      <FontAwesomeIcon icon={faCheck} /> Approve
    </Button>
  );
};

export default ApproveBatchButton;
