import { useState } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";

const YEARS = [...Array(20).keys()].map((item) => {
  return {
    label: 2022 - item,
    value: 2022 - item,
  };
});
const Download = (props) => {
  const [year, setYear] = useState("2022");
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Download {props.source}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={3}>Select Year: </Col>
          <Col>
            <Select
              options={YEARS}
              value={year}
              onChange={(e) => {
                setYear(e);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary">Download</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Download;
