import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import approvalHelper from "../../utilities/approval";
import CONSTANTS from "../../utilities/constants";

const RejectionBatchModal = (props) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");

  const [credentials] = useLocalStorage("credentials");
  const shown = ["3", "4"].includes(credentials?.updated_role);
  if (!shown) return <></>;
  //   const approvalData = props.approvalData.details;
  //   const nextFlag = props.approvalData.nextFlag.key;
  const reject = async () => {
    try {
      for (const entry of props.selectedRows) {
        const approvalDetails = await approvalHelper.getApprovalDetails(
          entry.id,
          props.source
        );
        const approvalId = approvalDetails.details.id;
        const nextFlag = approvalDetails.nextFlag.key;
        var payload = {
          by: credentials.user_fullname,
          status: "rejected",
          reason,
          nextFlag,
          credentials,
          entry_id: entry.id,
          source: props.source,
        };
        const response = await fetch(
          CONSTANTS.API_ROOT + "/Approval/updateStatus/" + approvalId,
          {
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
      }
      swal({
        text: "Entry has been rejected!",
        icon: "info",
      }).then(() => {
        setShow(false);
        props.refresher();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rejectPrompt = () => {
    if (reason.trim().length == 0) {
      swal({
        text: "Oops! Rejection reason is required",
        icon: "error",
      });
      return;
    } else {
      swal({
        text: "Do you want to reject these entries?",
        icon: "info",
        buttons: true,
      }).then(async (willSave) => {
        if (willSave) {
          reject();
        }
      });
    }
  };
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => setShow(false)}
        size="lg"
        close
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Reject an entry.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>
              <h5>Why this entry is rejected?</h5>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button>Cancel</Button>
          <Button variant="danger" onClick={rejectPrompt}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant="danger"
        className="ms-2"
        style={{ width: "120px" }}
        onClick={() => setShow(true)}
        {...props}
      >
        <FontAwesomeIcon icon={faClose} /> Return
      </Button>
    </>
  );
};

export default RejectionBatchModal;
