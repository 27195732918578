import {
  faEye,
  faEyeSlash,
  faFileInvoice,
  faParagraph,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import useLocalStorage from "../../custom-hooks/useLocalStorage";
import CONSTANTS from "../../utilities/constants";

const REGION_ROLES = [
  {
    name: "Preparer",
    value: 1,
  },
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approval",
    value: 4,
  },
];

const CENTRAL_ROLES = [
  {
    name: "Reviewer",
    value: 2,
  },
  {
    name: "Recommending Approval",
    value: 3,
  },
  {
    name: "Approval",
    value: 4,
  },
];

const randomString = () => {
  // return "jmsalcedo";
  return Math.random().toString(36).slice(-6);
};
const EditUser = (props) => {
  console.log(props);
  const [credentials, setCredentials] = useLocalStorage("credentials");
  const isCentral = credentials.user_fullname == "Central Section";
  const ROLES = isCentral ? CENTRAL_ROLES : REGION_ROLES;
  const [userData, setUserdata] = useState({
    password: randomString(),
    firstname: props.firstname ?? "",
    lastname: props.lastname ?? "",
    email: props.email ?? "",
    role: props.updated_role ?? "",
    region: credentials.region_sess,
  });
  const [password, setPassword] = useState(randomString());
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);

  const addUser = async () => {
    console.log(userData);
    try {
      let response = await fetch(CONSTANTS.API_ROOT + "/Users/update/"+props.user_id, {
        method: "POST",
        body: JSON.stringify(userData),
      });
      let r = await response.json();
      console.log(r);
      swal({
        text: "User updated successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        props.updateUsers();
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(userData);
  }, [userData]);
  return (
    <>
      <Button variant="success" onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faPencil} />
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          {isCentral ? (
            <Modal.Title>Edit User &mdash; Central Office</Modal.Title>
          ) : (
            <Modal.Title>
              Edit User &mdash; Region {credentials.region_sess}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body style={{ padding: "20px", minHeight: "500px" }}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder="e.g. Juan"
                  value={userData.firstname}
                  onChange={(e) =>
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        firstname: e.target.value,
                      };
                    })
                  }
                />
                <Form.Label className="mt-2">Last Name</Form.Label>
                <Form.Control
                  placeholder="e.g. dela Cruz"
                  value={userData.lastname}
                  onChange={(e) =>
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        lastname: e.target.value,
                      };
                    })
                  }
                />
                <Form.Label className="mt-2">Email Address</Form.Label>
                <Form.Control
                  placeholder="e.g. delacruz.juan@denr.emb.gov.ph"
                  value={userData.email}
                  onChange={(e) =>
                    setUserdata((prev) => {
                      return {
                        ...prev,
                        email: e.target.value,
                      };
                    })
                  }
                />
                <Form.Label className="mt-2">Role</Form.Label>
                <Form.Select
                  value={userData.role}
                  onChange={(e) =>
                    setUserdata((prev) => {
                      console.log(e.target.value);
                      return {
                        ...prev,
                        role: e.target.value,
                      };
                    })
                  }
                >
                  {ROLES.map((role) => {
                    return <option value={role.value}>{role.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button onClick={addUser}>Update</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;
