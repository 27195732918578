const DECIMAL_PLACES = 2;
const APSI_TYPE_MAP = {
  boiler: {
    pm: 10, // lbs/1000gallons,
    co: 5, // lbs/1000gallons
    nox: 55, // lbs/1000gallons
    sox: 157, // lbs/1000gallons
    voc: 1.28, // lbs/1000gallons
  },
  generatorSet: {
    pm: 10, // lbs/1000gallons,
    co: 5, // lbs/1000gallons
    nox: 55, // lbs/1000gallons
    sox: 157, // lbs/1000gallons
    voc: 1.28, // lbs/1000gallons
  },
};

const APCD_LIST = [
  {
    value: "Fabric Filtration",
    label: "Fabric Filtration",
    capacity: 1,
  },
  {
    value: "Electrostatic Precipitators",
    label: "Electrostatic Precipitators",
    capacity: 2,
  },
  {
    value: "Cyclonic Seperators for Particles",
    label: "Cyclonic Seperators for Particles",
    capacity: 3,
  },
  {
    value: "Ceramic Filters",
    label: "Ceramic Filters",
    capacity: 4,
  },
  {
    value: "Particulate Scrubbers",
    label: "Particulate Scrubbers",
    capacity: 5,
  },
  {
    value: "Particulate Pre-treatment Systems",
    label: "Particulate Pre-treatment Systems",
    capacity: 6,
  },
  {
    value: "Biofiltration",
    label: "Biofiltration",
    capacity: 7,
  },
  {
    value: "Thermal Oxidation of Gaseous Contaminants",
    label: "Thermal Oxidation of Gaseous Contaminants",
    capacity: 8,
  },
  {
    value: "Scrubbing & Adsorption Systems",
    label: "Scrubbing & Adsorption Systems",
    capacity: 9,
  },
  {
    value: "Adsorption Systems",
    label: "Adsorption Systems",
    capacity: 10,
  },
  {
    value: "Fugitive Dust Control",
    label: "Fugitive Dust Control",
    capacity: 11,
  },
  {
    value: "Others",
    label: "Others",
    capacity: 12,
  },
];
const FUEL_EFS = {
  bagasse: {
    reference:
      "https://www.epa.gov/sites/default/files/2020-09/documents/1.8_bagasse_combustion_in_sugar_mills.pdf",
    pm: 1.36,
    co: 1560,
    nox: 1.2,
    sox: 0,
    voc: 0,
  },
  gasoline: {
    reference:
      " https://www.epa.gov/sites/default/files/2020-10/documents/c03s03.pdf",
    pm: 0.1,
    co: 0.99,
    nox: 1.63,
    sox: 0.084,
    voc: 0,
  },
  diesel: {
    reference:
      " https://www.epa.gov/sites/default/files/2020-10/documents/c03s03.pdf",
    pm: 0.31,
    co: 0.95,
    nox: 4.41,
    sox: 0.29,
    voc: 0,
  },
};
const FUELS = {
  liquid: ["Gas", "Diesel", "Bunker Fuel"],
  solid: ["Coal", "Bagasse", "Wood", "Textile", "others"],
};

const EMISSION_FACTORS = {
  gas: {
    label: "Natural Gas",
    unit: "BHP",
    fuel_units: ["SCF"],
    apsi_default: "boiler_hp",
    min_capacity: 2222, // in BHP
    efs: {
      pm: 7.6,
      co: 84,
      nox: 280,
      sox: 0.6,
      voc: 5.5,
    },
    efs_max: {
      pm: 7.6,
      co: 84,
      nox: 280,
      sox: 0.6,
      voc: 5.5,
    },
  },
  bunker_fuel: {
    label: "Bunker Fuel",
    unit: "BHP",
    apsi_default: "boiler_hp",
    min_capacity: 2222, // in BHP
    efs: {
      pm: 10,
      co: 5,
      nox: 55,
      sox: 471,
      voc: 1.28,
    },
    efs_max: {
      pm: 3.4957, //9.19(S) + 3.22 //assuming S is at 3%
      co: 5,
      nox: 47,
      sox: 471,
      voc: 0,
    },
  },

  diesel: {
    label: "Diesel",
    unit: "BHP",
    apsi_default: "boiler_hp",
    min_capacity: 2222, // in BHP
    efs: {
      pm: 2,
      co: 5,
      nox: 20,
      sox: 33.6,
      voc: 0.252,
    },
    efs_max: {
      pm: 2,
      co: 5,
      nox: 24,
      sox: 4.71,
      voc: 0.252,
    },
  },
  oil: {
    label: "Oil (Mixed Distillate & Residual)",
    unit: "BHP",
    apsi_default: "boiler_hp",
    min_capacity: 2222, // in BHP
    efs: {
      pm: 7,
      co: 5,
      nox: 20,
      sox: 150 * 0.03, // 150 @ 3% sulfur
      voc: 0.252,
    },
    efs_max: {
      pm: 0,
      co: 5,
      nox: 47,
      sox: 150 * 0.03, // 150 @ 3% sulfur
      voc: 0.252,
    },
  },
  oil_internal: {
    apsi_default: "generator_kw",
    label: "Oil (Internal Combustion)",
    unit: "HP",
    min_capacity: 600, // in BHP
    efs: {
      pm: 43,
      co: 131,
      nox: 607,
      sox: 40, // 150 @ 3% sulfur
      voc: 49,
    },
    efs_max: {
      pm: 14,
      co: 108,
      nox: 470,
      sox: 158, // 150 @ 3% sulfur
      voc: 14,
    },
  },
  oil_external: {
    label: "Oil (External Combustion - Liquid LSFO / DIST Fuels)",
    unit: "BHP",
    apsi_default: "boiler_hp",
    min_capacity: 2222, // in BHP
    efs: {
      pm: 2,
      co: 5,
      nox: 20,
      sox: 142, // 150 @ 3% sulfur
      voc: 0.252,
    },
    efs_max: {
      pm: 2,
      co: 5,
      nox: 24,
      sox: 157, // 150 @ 3% sulfur
      voc: 0.252,
    },
  },
  bagasse: {
    // unit: "BHP",
    apsi_default: "boiler_hp",
    label: "Bagasse",
    efs: {
      pm: 15.6,
      co: 0,
      nox: 1.2,
      sox: 0, // 150 @ 3% sulfur
      voc: 0.001,
    },
  },
  wood: {
    label: "Wood",
    fuel_units: ["Tons"], // ADD "LBS",
    apsi_default: "boiler_hp",
    efs: {
      pm: 6.44,
      co: 6.9,
      nox: 2.53,
      sox: 0.29, // 150 @ 3% sulfur
      voc: 0.2,
    },
  },
  lpg: {
    // unit: "BHP",
    apsi_default: "boiler_hp",
    label: "LPG (Propane/Butane)",
    efs: {
      pm: 0.4,
      co: 3.2,
      nox: 19,
      sox: 0.1 * 0.03, // 0.10 @ 3% sulfur
      voc: 0.5,
    },
  },
  coal_anthracite: {
    label: "Coal - Anthracite",
    apsi_default: "boiler_hp",
    fuel_units: ["Tons/hr"], // add lbs
    sulfur_content: 1,
    ash_content: 7,
    efs: {
      pm: 1.8,
      co: 0.6,
      nox: 9,
      sox: 39, // 0.10 @ 3% sulfur
      voc: 0.01,
    },
  },
  coal_bit_sub: {
    label: "Coal - Bituminous / Subbituminous",
    fuel_units: ["Tons/hr"], // add , "LBS"
    apsi_default: "boiler_hp",
    equipments: [
      {
        label: "Pulverized Coal (PC) Fired  Boilers",
        value: "coal_pc",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 10, // multipled to ash content
          co: 0.5,
          nox: 22,
          sox: 38, // multiplied to sulfur content
          voc: 0.0098586,
        },
      },
      {
        label: "Circulating Fluidized Bed (CFB) Coal Fired Boilers",
        value: "coal_cfb",
        sulfur_content: 5, //default
        ash_content: 3, //
        efs: {
          pm: 17,
          co: 18,
          nox: 5,
          sox: 38, // multiplied to sulfur content
          voc: 0,
        },
      },
    ],
  },
  portland_cement_manufacturing: {
    label: "Portland Cement Manufacturing for Cement Plants",
    fuel_units: ["Tons"], // add , "LBS"
    apsi_default: "boiler_hp",
    equipments: [
      {
        label: "Wet process kiln",
        value: "wet_process_kiln",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 130, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Wet process kiln with ESP",
        value: "wet_process_kiln_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.77, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Wet process kiln with fabric filter",
        value: "wet_process_kiln_fabric_filter",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.46, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Wet process kiln with cooling tower, multiclone, and ESP",
        value: "wet_process_kiln_cooling_multiclone_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.2, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Dry process kiln with ESP",
        value: "dry_process_kiln_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 1, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Dry process kiln with fabric_filter",
        value: "dry_process_kiln_fabric_filter",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.2, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Preheater kiln",
        value: "preheater_kiln",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 250, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Preheater kiln with ESP",
        value: "preheater_kiln_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.26, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Preheater kiln with fabric filter",
        value: "preheater_kiln_fabric_filter",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.25, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Preheater/precalciner kiln with ESP",
        value: "preheater_precalciner_with_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.048, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Preheater/precalciner process kiln",
        value: "preheater_precalciner_kiln",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.21, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Clinker cooler with ESP",
        value: "clinker_cooler_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.096, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Clinker cooler with ESP",
        value: "clinker_cooler_esp",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.096, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Clinker cooler with fabric filter",
        value: "clinker_cooler_fabric_filter",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.13, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Clinker cooler with gravel bed filter",
        value: "clinker_cooler_gravel_bed",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.21, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
    ],
  },

  "Steel Foundries for Steel Mills": {
    label: "Steel Foundries for Steel Mills",
    fuel_units: ["Tons"], // add , "LBS"
    apsi_default: "boiler_hp",
    equipments: [
      {
        label: "Electric arc",
        value: "Electric arc",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0, // multipled to ash content
          co: 0,
          nox: 0.1,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Open hearth",
        value: "Open hearth",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0, // multipled to ash content
          co: 0,
          nox: 0.005,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Electric induction",
        value: "Electric induction",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.045, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Sand grinding/handling in mold and core making",
        value: "Sand grinding/handling in mold and core making",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.27, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Core ovens",
        value: "Core ovens",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 1.11, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Pouring and casting",
        value: "Pouring and casting",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 1.14, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Casting cleaning",
        value: "Casting cleaning",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.85, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Charge handling",
        value: "Charge handling",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.18, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
      {
        label: "Casting coolingj",
        value: "Casting coolingj",
        sulfur_content: 5, // default,
        ash_content: 4, // default
        efs: {
          pm: 0.7, // multipled to ash content
          co: 0,
          nox: 0,
          sox: 0, // multiplied to sulfur content
          voc: 0,
        },
      },
    ],
  },

  grain_harvesting: {
    label: "Grain Harvesting",
    fuel_units: ["Tons"], // add , "LBS"
    apsi_default: "boiler_hp",
    equipments: [
      {
        label: "Harvest machine",
        value: "Harvest machine",
        efs: {
          pm: 0.96,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Truck loading",
        value: "Truck Loading",
        efs: {
          pm: 0.07,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Field Transport",
        value: "Field Transport",
        efs: {
          pm: 0.65,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
    ],
  },

  steel_production: {
    label: "Iron and steel production",
    fuel_units: ["Tons"], // add , "LBS"
    apsi_default: "boiler_hp",
    equipments: [
      {
        label: "Sintering - Controlled by dry ESP",
        value: "Sintering - Controlled by dry ESP",
        efs: {
          pm: 0.8,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Sintering - Controlled by wet ESP",
        value: "Sintering - Controlled by wet ESP",
        efs: {
          pm: 0.085,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Sintering - Controlled by venturi scrubber",
        value: "Sintering - Controlled by venturi scrubber",
        efs: {
          pm: 0.235,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Sinter discharge - Uncontrolled",
        value: "Sinter discharge - Uncontrolled",
        efs: {
          pm: 3.4,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Sinter discharge - Controlled by baghouse",
        value: "Sinter discharge - Controlled by baghouse",
        efs: {
          pm: 0.05,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Sinter discharge - Controlled by venturi scrubber",
        value: "Sinter discharge - Controlled by venturi scrubber",
        efs: {
          pm: 0.295,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Windbox and discharge - Controlled by baghouse",
        value: "Windbox and discharge - Controlled by baghouse",
        efs: {
          pm: 0.15,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Blast furnace - slip",
        value: "Blast furnace - slip",
        efs: {
          pm: 39.5,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Blast furnace - roof monitor",
        value: "Blast furnace - roof monitor",
        efs: {
          pm: 0.3,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Blast furnace - Taphole and trough only",
        value: "Blast furnace - Taphole and trough only",
        efs: {
          pm: 0.15,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Hot metal desulfurization - Uncontrolled",
        value: "Hot metal desulfurization - Uncontrolled",
        efs: {
          pm: 0.55,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Hot metal desulfurization - Controlled by baghouse",
        value: "Hot metal desulfurization - Controlled by baghouse",
        efs: {
          pm: 0.0045,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Basic oxygen furnace (BOF) - Uncontrolled",
        value: "Basic oxygen furnace (BOF) - Uncontrolled",
        efs: {
          pm: 14.25,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Basic oxygen furnace (BOF) - Controlled by open hood venter to ESP",
        value:
          "Basic oxygen furnace (BOF) - Controlled by open hood venter to ESP",
        efs: {
          pm: 0.065,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Basic oxygen furnace (BOF) - Controlled by open hood venter to scrubber",
        value:
          "Basic oxygen furnace (BOF) - Controlled by open hood venter to scrubber",
        efs: {
          pm: 0.045,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Basic oxygen furnace (BOF) - Controlled by closed hood venter to scrubber",
        value:
          "Basic oxygen furnace (BOF) - Controlled by closed hood venter to scrubber",
        efs: {
          pm: 0.0034,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "BOF Charging - Controlled by baghouse",
        value: "BOF Charging - Controlled by baghouse",
        efs: {
          pm: 0.003,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "BOF Tapping - Controlled by baghouse",
        value: "BOF Tapping - Controlled by baghouse",
        efs: {
          pm: 0.0013,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Hot metal transfer - at source",
        value: "Hot metal transfer - at source",
        efs: {
          pm: 0.095,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Hot metal transfer - at building monitor",
        value: "Hot metal transfer - at building monitor",
        efs: {
          pm: 0.028,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "BOF monitor",
        value: "BOF monitor",
        efs: {
          pm: 0.025,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Q-BOF melting and refining - controlled by scrubber",
        value: "Q-BOF melting and refining - controlled by scrubber",
        efs: {
          pm: 0.028,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Melting and refining - uncontrolled carbon steel",
        value: "Melting and refining - uncontrolled carbon steel",
        efs: {
          pm: 0.19,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Charging, tapping, and slagging - Uncontrolled emissions escaping monitor",
        value:
          "Charging, tapping, and slagging - Uncontrolled emissions escaping monitor",
        efs: {
          pm: 0.7,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Melting, refining, charging, tapping, and slagging - Uncontrolled alloy steel",
        value:
          "Melting, refining, charging, tapping, and slagging - Uncontrolled alloy steel",
        efs: {
          pm: 5.56,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Melting, refining, charging, tapping, and slagging - Uncontrolled carbon steel",
        value:
          "Melting, refining, charging, tapping, and slagging - Uncontrolled carbon steel",
        efs: {
          pm: 25,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Melting, refining, charging, tapping, and slagging - controlled by Building evacuation to baghouse for alloy steel",
        value:
          "Melting, refining, charging, tapping, and slagging - controlled by Building evacuation to baghouse for alloy steel",
        efs: {
          pm: 0.15,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Melting, refining, charging, tapping, and slagging -  vented to common baghouse for carbon steel",
        value:
          "Melting, refining, charging, tapping, and slagging -  vented to common baghouse for carbon steel",
        efs: {
          pm: 0.0215,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Open hearth furnace - Melting and refining - Uncontrolled",
        value: "Open hearth furnace - Melting and refining - Uncontrolled",
        efs: {
          pm: 10.55,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Open hearth furnace - Melting and refining - Controlled by ESP",
        value: "Open hearth furnace - Melting and refining - Controlled by ESP",
        efs: {
          pm: 0.14,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Open hearth furnace - Roof Monitor",
        value: "Open hearth furnace - Roof Monitor",
        efs: {
          pm: 0.084,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Teeming - Leaded Steel - Uncontrolled",
        value: "Teeming - Leaded Steel - Uncontrolled",
        efs: {
          pm: 0.405,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Teeming - Leaded Steel - Controlled by side draft hood vented to baghouse",
        value:
          "Teeming - Leaded Steel - Controlled by side draft hood vented to baghouse",
        efs: {
          pm: 0.0019,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Teeming - Unleaded Steel - Uncontrolled",
        value: "Teeming - Unleaded Steel - Uncontrolled",
        efs: {
          pm: 0.035,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label:
          "Teeming - Unleaded Steel - Controlled by side draft hood vented to baghouse",
        value:
          "Teeming - Unleaded Steel - Controlled by side draft hood vented to baghouse",
        efs: {
          pm: 0.0008,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Machine Scarfing - Uncontrolled",
        value: "Machine Scarfing - Uncontrolled",
        efs: {
          pm: 0.05,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Machine Scarfing - Controlled by ESP",
        value: "Machine Scarfing - Controlled by ESP",
        efs: {
          pm: 0.0115,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Blast furnace gas",
        value: "Blast furnace gas",
        efs: {
          pm: 0.015,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
      {
        label: "Coke oven gas",
        value: "Coke oven gas",
        efs: {
          pm: 0.0052,
          nox: 0,
          sox: 0,
          voc: 0,
        },
      },
    ],
  },
};

class Stationary {
  calculatePM = (fuelComsumption, apcdEfficiency) => {
    var result =
      (fuelComsumption * (10 / 1000) * (1 - apcdEfficiency / 100)) / 2210;
    return !isNaN(result) ? result.toFixed(5) : 0;
  };
  calculateCO = (fuelComsumption, apcdEfficiency) => {
    var result =
      (fuelComsumption * (5 / 1000) * (1 - apcdEfficiency / 100)) / 2210;
    return !isNaN(result) ? result.toFixed(5) : 0;
  };
  calculateNOX = (fuelComsumption, apcdEfficiency) => {
    var result =
      (fuelComsumption * (55 / 1000) * (1 - apcdEfficiency / 100)) / 2210;
    return !isNaN(result) ? result.toFixed(5) : 0;
  };
  calculateSOX = (fuelComsumption, apcdEfficiency) => {
    var result =
      (fuelComsumption * (157 / 1000) * (1 - apcdEfficiency / 100)) / 2210;
    return !isNaN(result) ? result.toFixed(5) : 0;
  };
  calculateVOC = (fuelComsumption, apcdEfficiency) => {
    var result =
      (fuelComsumption * (1.28 / 1000) * (1 - apcdEfficiency / 100)) / 2210;
    return !isNaN(result) ? result.toFixed(5) : 0;
  };

  detectEf = (fuelType, apsi) => {
    // console.log(fuelType, apsi);
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    // console.log(FUEL_EF);
    var apsiCapacity = apsi["value"];
    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
        if ("min_capacity" in FUEL_EF) {
          finalEf = apsiCapacity <= FUEL_EF["min_capacity"] ? "min" : "max";
        } else {
          finalEf = "min";
        }
        return fuelType + "-" + equipment.value + "-" + finalEf;
      }
    } else {
      if ("min_capacity" in FUEL_EF) {
        finalEf = apsiCapacity <= FUEL_EF["min_capacity"] ? "min" : "max";
      } else {
        finalEf = "min";
      }
      return fuelType + "-" + finalEf;
    }
  };

  identifyPollutant = (keyword) => {
    let finalKeyword = keyword
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .toLowerCase();
    let co = ["carbonmonoxide", "co"];
    let sox = ["sulfuroxide", "sox"];
    let nox = ["nitrogen oxide", "nox"];
    let pm = ["paticulatematter", "pm"];
    let voc = ["organicmatter", "volatileorganiccompound", "voc"];
    if (co.includes(finalKeyword)) return "co";
    else if (sox.includes(finalKeyword)) return "sox";
    else if (nox.includes(finalKeyword)) return "nox";
    else if (pm.includes(finalKeyword)) return "pm";
    else if (voc.includes(finalKeyword)) return "voc";
    else return finalKeyword;
  };

  // 10th edition
  calculatePMv2 = (
    fuelComsumption,
    apcdEfficiency,
    fuelType,
    apsi,
    ashContent
  ) => {
    ashContent = parseFloat(ashContent);
    if (isNaN(ashContent)) {
      ashContent = 1;
    }

    ashContent = ashContent == 0 ? 1 : ashContent;
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    var apsiCapacity = apsi["value"];
    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
      }
    }

    // return 0;
    if ("min_capacity" in FUEL_EF) {
      finalEf =
        apsiCapacity <= FUEL_EF["min_capacity"]
          ? FUEL_EF["efs"]
          : FUEL_EF["efs_max"];
    } else {
      finalEf = FUEL_EF["efs"];
    }

    if (!finalEf) return 0;

    var apcdPercentage = 1 - apcdEfficiency / 100;
    var result =
      (fuelComsumption *
        ((ashContent * finalEf["pm"]) / 1000) *
        apcdPercentage) /
      2210;

    // override
    if (["coal_bit_sub", "coal_anthracite"].includes(fuelType)) {
      result =
        (fuelComsumption / 2210) *
        (ashContent * finalEf["pm"]) *
        apcdPercentage;
      return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
    }

    return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
  };

  calculateNOXv2 = (fuelComsumption, apcdEfficiency, fuelType, apsi) => {
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    var apsiCapacity = apsi["value"];

    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
      }
    }

    if ("min_capacity" in FUEL_EF) {
      finalEf =
        apsiCapacity <= FUEL_EF["min_capacity"]
          ? FUEL_EF["efs"]
          : FUEL_EF["efs_max"];
    } else {
      finalEf = FUEL_EF["efs"];
    }
    if (!finalEf) return 0;
    var result =
      (fuelComsumption * (finalEf["nox"] / 1000) * (1 - apcdEfficiency / 100)) /
      2210;

    // override
    if (["coal_bit_sub", "coal_anthracite"].includes(fuelType)) {
      var apcdPercentage = 1 - apcdEfficiency / 100;
      result = (fuelComsumption / 2210) * finalEf["nox"] * apcdPercentage;
      return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
    }
    return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
  };

  calculateCOv2 = (fuelComsumption, apcdEfficiency, fuelType, apsi) => {
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    var apsiCapacity = apsi["value"];
    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
      }
    }
    if ("min_capacity" in FUEL_EF) {
      finalEf =
        apsiCapacity <= FUEL_EF["min_capacity"]
          ? FUEL_EF["efs"]
          : FUEL_EF["efs_max"];
    } else {
      finalEf = FUEL_EF["efs"];
    }
    if (!finalEf) return 0;
    var result =
      (fuelComsumption * (finalEf["co"] / 1000) * (1 - apcdEfficiency / 100)) /
      2210;
    // override
    if (["coal_bit_sub", "coal_anthracite"].includes(fuelType)) {
      var apcdPercentage = 1 - apcdEfficiency / 100;
      result = (fuelComsumption / 2210) * finalEf["co"] * apcdPercentage;
      return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
    }
    return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
  };
  calculateSOXv2 = (
    fuelComsumption,
    apcdEfficiency,
    fuelType,
    apsi,
    sulfurContent = 1,
    calciumRatio = 1
  ) => {
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }

    sulfurContent = parseFloat(sulfurContent);
    if (isNaN(sulfurContent) || sulfurContent == 0) {
      sulfurContent = 1;
    }

    calciumRatio = parseFloat(calciumRatio);
    if (isNaN(calciumRatio) || calciumRatio == 0) {
      calciumRatio = 1;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    var apsiCapacity = apsi["value"];
    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
      }
    }
    if ("min_capacity" in FUEL_EF) {
      finalEf =
        apsiCapacity <= FUEL_EF["min_capacity"]
          ? FUEL_EF["efs"]
          : FUEL_EF["efs_max"];
    } else {
      finalEf = FUEL_EF["efs"];
    }
    if (!finalEf) return 0;
    var result =
      (fuelComsumption *
        ((sulfurContent * finalEf["sox"]) / 1000) *
        (1 - apcdEfficiency / 100)) /
      2210;
    // override
    if (["coal_bit_sub", "coal_anthracite"].includes(fuelType)) {
      var apcdPercentage = 1 - apcdEfficiency / 100;
      var finalEFSox = finalEf["sox"] * sulfurContent;
      // we multiply with ash content ratio with fluidized bed
      finalEFSox = finalEFSox * (apsi["type"] == "coal_cfb" ? calciumRatio : 1);
      console.log("FINAL SOX EF", finalEFSox);
      result = (fuelComsumption / 2210) * finalEFSox * apcdPercentage;
      return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
    }
    return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
  };

  calculateVOCv2 = (fuelComsumption, apcdEfficiency, fuelType, apsi) => {
    if (!(fuelType in EMISSION_FACTORS)) {
      return 0;
    }
    var FUEL_EF = EMISSION_FACTORS[fuelType];
    var apsiCapacity = apsi["value"];
    var equipments = EMISSION_FACTORS[fuelType].equipments ?? [];
    var finalEf;
    if (equipments.length > 0) {
      var equipment = equipments.find((e) => e.value == apsi["type"]);
      if (equipment) {
        FUEL_EF = equipment;
      }
    }
    if ("min_capacity" in FUEL_EF) {
      finalEf =
        apsiCapacity <= FUEL_EF["min_capacity"]
          ? FUEL_EF["efs"]
          : FUEL_EF["efs_max"];
    } else {
      finalEf = FUEL_EF["efs"];
    }
    if (!finalEf) return 0;
    var result =
      (fuelComsumption * (finalEf["voc"] / 1000) * (1 - apcdEfficiency / 100)) /
      2210;
    // override
    if (["coal_bit_sub", "coal_anthracite"].includes(fuelType)) {
      var apcdPercentage = 1 - apcdEfficiency / 100;
      result = (fuelComsumption / 2210) * finalEf["voc"] * apcdPercentage;
      return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
    }
    return !isNaN(result) ? result.toFixed(DECIMAL_PLACES) : 0;
  };

  getApcds = () => {
    return APCD_LIST;
  };
}

const stationaryHelper = new Stationary();

export default stationaryHelper;

export { EMISSION_FACTORS };
