const COLD_START_FACTORS = {
  tog: {
    cars: { gas: 15.82, diesel: 0.72 },
    uv: { gas: 16.47, diesel: 0.83 },
    buses: { gas: 16.47, diesel: 0.83 },
    trucks: { gas: 16.47, diesel: 0.83 },
    mc_tc: { gas: 12.48, diesel: 0 }
  },
  co: {
    cars: { gas: 131.18, diesel: 4.11 },
    uv: { gas: 129.23, diesel: 4.02 },
    buses: { gas: 129.23, diesel: 4.02 },
    trucks: { gas: 129.23, diesel: 4.02 },
    mc_tc: { gas: 69.99, diesel: 0 }
  },
  nox: {
    cars: { gas: 4.3, diesel: 0.31 },
    uv: { gas: 4.5, diesel: 1.1 },
    buses: { gas: 4.5, diesel: 1.1 },
    trucks: { gas: 4.5, diesel: 1.1 },
    mc_tc: { gas: 0.87, diesel: 0 }
  }
};

const HOT_START_FACTORS = {
  tog: {
    cars: { gas: 10.98, diesel: 0.18 },
    uv: { gas: 10.88, diesel: 0.16 },
    buses: { gas: 10.88, diesel: 0.16 },
    trucks: { gas: 10.88, diesel: 0.16 },
    mc_tc: { gas: 4.22, diesel: 0 }
  },
  co: {
    cars: { gas: 26.12, diesel: 2.19 },
    uv: { gas: 23.3, diesel: 2.68 },
    buses: { gas: 23.3, diesel: 2.68 },
    trucks: { gas: 23.3, diesel: 2.68 },
    mc_tc: { gas: 9.83, diesel: 0 }
  },
  nox: {
    cars: { gas: 5.86, diesel: 0.15 },
    uv: { gas: 5.94, diesel: 0.74 },
    buses: { gas: 5.94, diesel: 0.74 },
    trucks: { gas: 5.94, diesel: 0.74 },
    mc_tc: { gas: 0.84, diesel: 0 }
  }
};

// FOR Evaporative emission
const HOT_SOAK_FACTOR = {
  tog: {
    cars: 10.19,
    uv: 9.39,
    trucks: 3.79,
    mc_tc: 3.92,
    buses: 9.39 // ??
  }
};

const DIURNAL_FACTOR = {
  tog: {
    cars: 16.7,
    uv: 15.66,
    trucks: 12.86,
    mc_tc: 7.7,
    buses: 9.39 // ??
  }
};

const ERL_FACTOR = {
  tog: {
    cars: 0.38,
    uv: 0.36,
    trucks: 0.36, // ??
    mc_tc: 0.36, // ??
    buses: 0.36 // ??
  }
};

const RUNNING_EMISSION_FACTOR = {
  co: {
    cars: { gas: 49.5, diesel: 1.9 },
    uv: { gas: 60.0, diesel: 2.5 },
    buses: { gas: 36.24, diesel: 12.4 },
    trucks: { gas: 36.24, diesel: 12.4 },
    mc_tc: { gas: 26, diesel: 0 }
  },
  hc: {
    cars: { gas: 6, diesel: 0.65 },
    uv: { gas: 8, diesel: 0.7 },
    buses: { gas: 36.24, diesel: 12.4 },
    trucks: { gas: 1.68, diesel: 3.7 },
    mc_tc: { gas: 18.6, diesel: 0 }
  },
  nox: {
    cars: { gas: 2.7, diesel: 2 },
    uv: { gas: 3, diesel: 1.4 },
    buses: { gas: 3.93, diesel: 12.5 },
    trucks: { gas: 3.93, diesel: 12.5 },
    mc_tc: { gas: 0.2, diesel: 0 }
  },
  pmex: {
    cars: { gas: 0.1, diesel: 0.6 },
    uv: { gas: 0.12, diesel: 0.9 },
    buses: { gas: 0.05, diesel: 1.5 },
    trucks: { gas: 0.05, diesel: 1.5 },
    mc_tc: { gas: 2, diesel: 0 }
  },
  pmtw: {
    cars: { gas: 0.124, diesel: 0.124 },
    uv: { gas: 0.124, diesel: 0.124 },
    buses: { gas: 0.21, diesel: 0.21 },
    trucks: { gas: 0.21, diesel: 0.21 },
    mc_tc: { gas: 0.62, diesel: 0 }
  },
  pm10: {
    cars: { gas: 0.099, diesel: 0.576 },
    uv: { gas: 0.119, diesel: 0.864 },
    buses: { gas: 0.048, diesel: 0.44 },
    trucks: { gas: 0.048, diesel: 0.44 },
    mc_tc: { gas: 1.99, diesel: 0 }
  }
};

const DIESEL_DENSITY = 7.05; //7.05lb each gallon
class Mobile {
  /**
   *
   * @param {String} type type of vehicle
   * @param {String} fuel  type of fuel
   * @param {Int} trips number of trips
   * @param {Int} days number of days vehicle used
   * @param {Int} units number of vehicles
   * @param {Float} distance distance travelled by vehicle in km
   * @param {Float} fuelGallons fuel used by vehicles
   * @param {Float} sulfurContentPercentage sulfur percentage (assumption is 0.03%)
   * @returns
   */
  calculateMobile = (
    type,
    fuel,
    trips,
    days,
    units,
    distance = 1,
    fuelGallons = 0,
    sulfurContentPercentage = 0.3,
    registeredVehicles = 1
  ) => {
    console.log(
      "PARAMETERS",
      type,
      fuel,
      trips,
      days,
      units,
      distance,
      fuelGallons,
      sulfurContentPercentage,
      registeredVehicles
    );
    var COLDSTARTS = {};
    for (var factor of Object.keys(COLD_START_FACTORS)) {
      COLDSTARTS[factor] =
        (trips * COLD_START_FACTORS[factor][type][fuel] * days * units) /
        1000000;
    }
    console.log("Coldstarts", COLDSTARTS);

    var HOTSTARTS = {};
    for (var factor of Object.keys(HOT_START_FACTORS)) {
      HOTSTARTS[factor] =
        (trips * HOT_START_FACTORS[factor][type][fuel] * days * units) /
        1000000;
    }

    console.log("Hostarts", HOTSTARTS);

    // evaporative emission
    var HOTSOAKS = {};
    for (var factor of Object.keys(HOT_SOAK_FACTOR)) {
      HOTSOAKS[factor] =
        (trips * HOT_SOAK_FACTOR[factor][type] * days * units) / 1000000;
    }

    var DIURNALS = {};
    for (var factor of Object.keys(DIURNAL_FACTOR)) {
      DIURNALS[factor] =
        (DIURNAL_FACTOR[factor][type] * days * units) / 1000000;
    }

    var ERLS = {};
    for (var factor of Object.keys(ERL_FACTOR)) {
      ERLS[factor] =
        ((ERL_FACTOR[factor][type] * distance * units) / 1000000 / 365) * days;
    }

    var evaporativeEmissionKeys = [
      ...new Set([
        ...Object.keys(ERL_FACTOR),
        ...Object.keys(DIURNAL_FACTOR),
        ...Object.keys(HOT_SOAK_FACTOR),
      ]),
    ];
    // var evaporativeEmission = hotsoaks + diurnals + ERLS;

    var EVAPORATIVE_EMISSIONS = {};
    for (var factor of evaporativeEmissionKeys) {
      EVAPORATIVE_EMISSIONS[factor] =
        (HOTSOAKS[factor] ?? 0) + (DIURNALS[factor] ?? 0) + (ERLS[factor] ?? 0);
    }

    var RUNNING_EMISSIONS = {};
    for (var factor of Object.keys(RUNNING_EMISSION_FACTOR)) {
      RUNNING_EMISSIONS[factor] =
        (units * distance * RUNNING_EMISSION_FACTOR[factor][type][fuel]) /
        1000000;
    }

    // TOTAL EMISSIONS
    var POLLUTANTS = [
      ...new Set([
        ...Object.keys(COLDSTARTS),
        ...Object.keys(HOTSTARTS),
        ...Object.keys(EVAPORATIVE_EMISSIONS),
        ...Object.keys(RUNNING_EMISSIONS),
      ]),
    ];
    var RESULTS = {};
    for (var pollutant of POLLUTANTS) {
      RESULTS[pollutant] = (
        (COLDSTARTS[pollutant] ?? 0) +
        (HOTSTARTS[pollutant] ?? 0) +
        (EVAPORATIVE_EMISSIONS[pollutant] ?? 0) +
        (RUNNING_EMISSIONS[pollutant] ?? 0)
      ).toFixed(2);
    }

    // FOR SOX

    let dieselVolume = fuelGallons;
    let estimation =
      dieselVolume * DIESEL_DENSITY * (sulfurContentPercentage / 100); //lbs
    let sulfurEstimate = (estimation / 2000).toFixed(2); // conver pounds to ton

    // use the ratio
    let SOX = sulfurEstimate * (units / registeredVehicles)
    let data = {
      RESULTS,
      COLDSTARTS,
      HOTSTARTS,
      HOTSOAKS,
      DIURNALS,
      ERLS,
      EVAPORATIVE_EMISSIONS,
      RUNNING_EMISSIONS,
      SOX,
    };
    console.log(data);
    return data;

    // compute totals

    // 𝑴𝑽𝑬𝑻𝑶𝑻𝑨𝑳 = 𝑴𝑽𝑪.𝑺. + 𝑴𝑽𝑯.𝑺.𝑬. + 𝑬𝑬 + 𝑴𝑽𝑹.𝑬.

    // 𝑀𝑉𝐸𝑇𝑂𝑇𝐴𝐿 − 𝑇𝑜𝑡𝑎𝑙 𝑀𝑜𝑡𝑜𝑟 𝑉𝑒ℎ𝑖𝑐𝑙𝑒 𝐸𝑚𝑖𝑠𝑠𝑖𝑜𝑛
    // • 𝑀𝑉𝐶.𝑆. − 𝑀𝑜𝑡𝑜𝑟 𝑉𝑒ℎ𝑖𝑐𝑙𝑒 𝐶𝑜𝑙𝑑 𝑆𝑡𝑎𝑟𝑡
    // • 𝑀𝑉𝐻.𝑆.𝐸. − 𝑀𝑜𝑡𝑜𝑟 𝑉𝑒ℎ𝑖𝑐𝑙𝑒 𝐻𝑜𝑡 𝑆𝑡𝑎𝑟𝑡 𝐸𝑚𝑖𝑠𝑠𝑖𝑜𝑛
    // • 𝐸𝐸 − 𝐸𝑣𝑎𝑝𝑜𝑟𝑎𝑡𝑖𝑣𝑒 𝐸𝑚𝑖𝑠𝑠𝑖𝑜𝑛𝑠
    // • 𝑀𝑉𝑅.𝐸. − 𝑀𝑜𝑡𝑜𝑟 𝑉𝑒ℎ𝑖𝑐𝑙𝑒 𝑅𝑢𝑛𝑛𝑖𝑛𝑔 𝐸𝑚𝑖𝑠𝑠𝑖𝑜n
  };
}

const mobileHelper = new Mobile();
// const results = mobileHelper.calculateMobile(
//   "cars",
//   "gas",
//   1,
//   240,
//   471100,
//   12000,
//   532543620,
//   23
// );
// console.log(results);
export default mobileHelper;
