class Constants {
    constructor () {
        this.name = "EMB Constants";
        // this.API_ROOT = "https://lqgbfqhyin.alapaapp.tech/api";
        this.API_ROOT = "https://eidatabank.emb.gov.ph/api/api";
        this.USER_TABLE = "accounts";
    }
    
}

const CONSTANTS = new Constants();

export default CONSTANTS;
