import "leaflet/dist/leaflet.css";
import {
  Accordion,
  Card,
  Col,
  Form,
  Row,
  Stack,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import helper from "../utilities/helper";
import Select from "react-select";
import { useEffect, useState } from "react";
import PlantForm from "./PlantForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import swal from "sweetalert";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import areaHelper, { EMISSION_FACTORS } from "../utilities/area";

// leaflet

import { MapContainer, TileLayer, useMap } from "react-leaflet";
import useLocalStorage from "../custom-hooks/useLocalStorage";
import CONSTANTS from "../utilities/constants";
import LeaftletDrawer from "./LeafletDrawer";
const PROVINCES = require("../utilities/json/province.json");
const REGIONS = require("../utilities/json/regions.json");
const CITIES = require("../utilities/json/cities.json");
const SOURCE_TYPES = areaHelper.getSourceTypes();

const RESIDENTIAL_COOKING_FUELS = {
  Wood: {
    unit: "Tons/Year",
    pm: 30.6,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  Charcoal: {
    unit: "Kg/Year",
    pm: 41.31,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  LPG: {
    unit: "Liter/Year",
    pm: 0.03,
    co: 0,
    nox: 0,
    sox: 0,
    voc: 0,
  },
  Kerosene: {
    unit: "Liter/Year",
    pm: 0,
    co: 0,
    nox: 0,
    sox: 1.48,
    voc: 0,
  },
};

const RESIDENTIAL_COOKING_FUELS_FORMATTED = Object.keys(
  RESIDENTIAL_COOKING_FUELS
).map((item) => {
  return {
    value: item,
    label: item,
  };
});

const SURFACE_COATING_FUELS = areaHelper.getEmissionFactors().surface_coating;
const SURFACE_COATING_FUELS_FORMATTED = Object.keys(SURFACE_COATING_FUELS).map(
  (item) => {
    return {
      value: item,
      label: item,
    };
  }
);

const FISH_CANNING_PROCESS = areaHelper.getEmissionFactors().fish_canning;
const FISH_CANNING_PROCESS_FORMATTED = Object.keys(FISH_CANNING_PROCESS).map(
  (item) => {
    return {
      value: item,
      label: item,
    };
  }
);

const DEFAULT_REGION = "NCR"; // hardcoded for now

const FORMAT_EF = () => {
  const formatted = Object.keys(EMISSION_FACTORS).flatMap((factor, index) => {
    // if has pm, it is one dimension dataset
    if ("pm" in EMISSION_FACTORS[factor]) {
      return {
        label: factor.replaceAll("_", " "),
        fuel_process: "--",
        ...EMISSION_FACTORS[factor],
        id: factor,
      };
    } else {
      const fuelProcesses = Object.keys(EMISSION_FACTORS[factor]).map(
        (fuel, index) => {
          return {
            ...EMISSION_FACTORS[factor][fuel],
            label: factor.replaceAll("_", " "),
            fuel_process:
              EMISSION_FACTORS[factor][fuel].label ?? fuel.replaceAll("_", " "),
            id: factor + "-" + fuel,
          };
        }
      );
      return fuelProcesses;
    }
  });
  // console.log("Formatted", formatted);
  return formatted;
};

const FORMATTED_EMISSION_FACTORS = FORMAT_EF();
const AddArea = (props) => {
  const [credentials, setCredentials] = useLocalStorage("credentials");

  const finalRegion = ["NCR", "CAR", "ARMM"].includes(props.region)
    ? props.region
    : `Region ${props.region}`;
  const [region, setRegion] = useState(finalRegion);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState({
    value: props.region,
    label: props.region,
  });
  const [selectedCity, setSelectedCity] = useState("");
  const [areaSources, setAreaSources] = useState([{ source_type: "" }]);
  const [year, setYear] = useState("2022");
  const updateAreaList = (index, data) => {
    let copies = [...areaSources];
    copies[index] = { ...copies[index], ...data };
    setAreaSources(copies);
    console.log(copies);
  };

  const addAreaSources = () => {
    swal({
      //  title: "Are you sure to save these Stationary Sources?",
      text: "Are you sure to save these Area Sources?",
      icon: "warning",
      buttons: true,
      // dangerMode: true,
    }).then((willSave) => {
      if (willSave) {
        let generalInfo = {
          year,
          province: selectedProvince["value"],
          city: selectedCity["value"],
          region,
        };
        fetch(CONSTANTS.API_ROOT + "/area/addaction", {
          method: "POST",
          body: JSON.stringify({ areaSources, generalInfo, credentials }),
        })
          .then(async (data) => {
            // const list = await data.json();
            // console.log(list);
            props.updateTable();
            props.handleClose(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // nothing to do for now
      }
    });
  };

  useEffect(() => {
    if (region == "") return;
    let regionDetails = REGIONS.find(
      (r) => r.name === region || r.long == region
    );
    // console.log(regionDetails);
    let regionCode = regionDetails["regCode"];
    // console.log("REG CODE: :" + regionCode);
    // get the provinces
    let provinces = PROVINCES.filter((p) => p.regCode === regionCode);
    // format for select
    let formattedProvinces = provinces.map((province) => {
      return {
        value: province["provDesc"],
        label: province["provDesc"],
      };
    });
    setProvinces(formattedProvinces);
    setSelectedProvince(formattedProvinces[0]);
  }, [region]);

  useEffect(() => {}, []);

  useEffect(() => {
    try {
			// console.log(selectedProvince);
			let provinceDetails = PROVINCES.find(
				(p) => p.provDesc == selectedProvince.value
			);
			if (!provinceDetails) {
				return;
			}
			// console.log("PORovinde details: ");
			// console.log(provinceDetails);
			let provinceCode = provinceDetails["provCode"];
			let cities = CITIES.filter((c) => c.provCode === provinceCode);
			let formattedCities = cities.map((city) => {
				return {
					value: city["citymunDesc"],
					label: city["citymunDesc"]
				};
			});
			setCities(formattedCities);
		} catch (error) {
      
    }
  }, [selectedProvince]);

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      //   centered
      fullscreen={true}
    >
      <Modal.Header closeButton className="ei-background" closeVariant="white">
        <Modal.Title>Add Area Source &mdash; {props.region} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={3}>
            <Card style={{ minHeight: "80vh" }}>
              <Card.Body>
                <Card.Title>General Details</Card.Title>
                <hr />
                <Form.Label>Year:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="e.g. 2022"
                  max={2020}
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
                <Form.Label className="mt-3">Province:</Form.Label>
                <Select
                  options={provinces}
                  value={selectedProvince}
                  onChange={(e) => {
                    setSelectedProvince(e);
                  }}
                />
                <Form.Label className="mt-3">City/Municipality:</Form.Label>
                <Select
                  options={cities}
                  value={selectedCity}
                  onChange={(e) => {
                    setSelectedCity(e);
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={9}>
            <Card style={{ height: "80vh", overflowY: "scroll" }}>
              <Card.Body>
                <Card.Title>Area Sources List</Card.Title>
                <hr style={{ borderBottom: "1px solid #2A3F54" }} />
                <Stack>
                  {areaSources.map((item, index) => {
                    return (
                      <AreaSourceForm
                        key={"area-source-" + index}
                        {...item}
                        index={index}
                        updateAreaList={updateAreaList}
                      />
                    );
                  })}
                </Stack>
                <Button
                  variant="success"
                  className="mt-4"
                  onClick={() => {
                    setAreaSources((prev) => [...prev, { source_type: "" }]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Line
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>{" "}
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={addAreaSources}>
          Add Sources
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AreaSourceForm = (props) => {
  const [showEf, setShowEf] = useState(false);
  const [detectedEf, setDetectedEf] = useState("");
  const [coordinates, setCoordinates] = useState("");
  const [fuelLabel, setFuelLabel] = useState("Fuel");
  const [geojson, setGeojson] = useState([]);
  const [address, setAddress] = useState("");
  const [pollutants, setPollutants] = useState({
    co: 0,
    nox: 0,
    pm: 0,
    sox: 0,
    voc: 0,
  });
  const [activityRate, setActivityRate] = useState("");
  const [sourceType, setSourceType] = useState("");
  const [duration, setDuration] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [ARPlaceholder, setARPlaceholder] = useState("");
  const [durationPlaceholder, setDurationPlaceholder] = useState("");
  const [fuels, setFuels] = useState(RESIDENTIAL_COOKING_FUELS_FORMATTED);
  const [fuel, setFuel] = useState("");
  const [gasResult, setGasResult] = useState({});
  useEffect(() => {
    let pm = 0,
      nox = 0,
      sox = 0,
      voc = 0,
      co = 0;
    // console.log(gasResult)
    for (let pollutant of Object.values(gasResult)) {
      pm += parseFloat(pollutant.pm);
      nox += parseFloat(pollutant.nox);
      sox += parseFloat(pollutant.sox);
      voc += parseFloat(pollutant.voc);
      co += parseFloat(pollutant.co);
    }
    let result = {
      pm,
      nox,
      sox,
      voc,
      co,
    };
    setPollutants(result);
  }, [gasResult]);

  useEffect(() => {
    props.updateAreaList(props.index, {
      ...pollutants,
      coordinates,
      activityRate,
      duration,
      fuel: fuel.value,
      address,
      sourceType: sourceType.label,
      geojson: JSON.stringify(geojson),
    });
  }, [
    sourceType,
    pollutants,
    coordinates,
    activityRate,
    duration,
    fuel,
    address,
    geojson,
  ]);
  useEffect(() => {
    if (fuel.value != "") {
      if (sourceType.value == "residential_cooking") {
        setARPlaceholder(RESIDENTIAL_COOKING_FUELS[fuel.value]["unit"]);
      } else if (sourceType.value == "surface_coating") {
        setARPlaceholder(SURFACE_COATING_FUELS[fuel.value]["unit"]);
      } else if (sourceType.value == "fish_canning") {
        setARPlaceholder(FISH_CANNING_PROCESS[fuel.value]["unit"]);
      }

      setDetectedEf(sourceType.value + "-" + fuel.value);
    } else {
      setDetectedEf(sourceType.value);
    }
  }, [fuel]);

  useEffect(() => {
    console.log("DETECTED", detectedEf);
  }, [detectedEf]);
  useEffect(() => {
    if (durationPlaceholder == "") {
      setDuration("");
    }
  }, [durationPlaceholder]);
  useEffect(() => {
    if (sourceType == "") {
      return;
    }

    console.log(activityRate);
    console.log(sourceType);
    console.log(duration);
    var result = areaHelper.calculate(activityRate, sourceType.value, {
      duration: duration,
      fuel: fuel.value ?? "",
    });
    console.log(result);
    setPollutants(result);
  }, [activityRate, sourceType, duration, fuel]);

  useEffect(() => {
    if (sourceType.value === "residential_cooking") {
      setFuels(RESIDENTIAL_COOKING_FUELS_FORMATTED);
    } else if (sourceType.value === "surface_coating") {
      setFuels(SURFACE_COATING_FUELS_FORMATTED);
    } else if (sourceType.value == "fish_canning") {
      setFuels(FISH_CANNING_PROCESS_FORMATTED);
    } else {
      setFuels([]);
    }
    setDetectedEf(sourceType.value);
  }, [sourceType]);
  useEffect(() => {
    console.log(coordinates);
  }, [coordinates]);

  return (
    <>
      <Accordion defaultActiveKey={props.index} className="mt-3">
        <Accordion.Item eventKey={props.index}>
          <Accordion.Header>
            {"Area Source " + parseInt(props.index + 1)}
          </Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={2} style={{ zIndex: 9999999 }}>
                <Form.Label className="mt-3">
                  Type of Sources:{" "}
                  <a href="#" onClick={() => setShowEf(true)}>
                    [View EF:]
                  </a>
                </Form.Label>
                <Select
                  options={SOURCE_TYPES}
                  value={sourceType}
                  onChange={(e) => {
                    setFuelLabel(e.fuel_label ?? "Fuel");
                    setDurationPlaceholder(e.duration_label ?? "");
                    setARPlaceholder(e.ar_label ?? "");
                    setSourceType(e);
                  }}
                />
              </Col>
              {sourceType.value != "gasoline_dispensing" ? (
                <>
                  <Col md={2} style={{ zIndex: 9999999 }}>
                    <Form.Label className="mt-3">{fuelLabel}:</Form.Label>
                    <Select
                      options={fuels}
                      value={fuel}
                      onChange={(e) => {
                        setFuel(e);
                      }}
                      isDisabled={
                        ![
                          "residential_cooking",
                          "surface_coating",
                          "fish_canning",
                        ].includes(sourceType.value)
                      }
                    />
                  </Col>{" "}
                  <Col md={2}>
                    <Form.Label className="mt-3">
                      {ARPlaceholder.length > 0
                        ? ARPlaceholder
                        : "Activity Rate"}
                    </Form.Label>
                    <Form.Control
                      value={activityRate}
                      onChange={(e) => setActivityRate(e.target.value)}
                      placeholder={ARPlaceholder}
                    />
                  </Col>
                  <Col md={2}>
                    <Form.Label className="mt-3">
                      Duration (in months):{" "}
                    </Form.Label>
                    <Form.Control
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                      placeholder={durationPlaceholder}
                      disabled={durationPlaceholder == ""}
                    />
                  </Col>
                </>
              ) : (
                <>
                  {Object.keys(
                    areaHelper.getEmissionFactors().gasoline_dispensing
                  ).map((activity) => {
                    return (
                      <Col md={2}>
                        <Form.Label
                          className="mt-3"
                          style={{ textTransform: "capitalize" }}
                        >
                          {
                            areaHelper.getEmissionFactors().gasoline_dispensing[
                              activity
                            ]["label"]
                          }
                          (Barrels)
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => {
                            let result = areaHelper.calculate(
                              parseFloat(e.target.value),
                              activity
                            );
                            setGasResult((prev) => {
                              let copy = { ...prev };
                              copy[activity] = result;
                              return copy;
                            });
                          }}
                          // value={duration}
                          // onChange={(e) => setDuration(e.target.value)}
                          // placeholder={durationPlaceholder}
                          // disabled={durationPlaceholder == ""}
                        />
                      </Col>
                    );
                  })}
                </>
              )}

              <Col md={3}>
                <Form.Label className="mt-3">Address:</Form.Label>
                <Form.Control onChange={(e) => setAddress(e.target.value)} />
              </Col>
              {/* <Col md={3}>
                <Form.Label className="mt-3">UTM:</Form.Label>
                <InputGroup>
                  <Form.Control
                    value={coordinates}
                    onChange={(e) => setCoordinates(e.target.value)}
                  />

                  <Button variant="success" onClick={() => setShowMap(true)}>
                    <FontAwesomeIcon icon={faMapLocationDot} /> Plot
                  </Button>
                </InputGroup>
              </Col> */}
            </Row>
            <Row>
              <Col className="mt-3 mb-3">
                <Form.Label>Plot the location of the area source</Form.Label>
                <MapContainer
                  center={[15.2033717, 120.5850848]}
                  zoom={13}
                  scrollWheelZoom={false}
                  style={{ height: "50vh" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

                  {/* <DraggableMarker callback={setCoordinates} /> */}
                  <LeaftletDrawer setter={setGeojson} />
                </MapContainer>
              </Col>
            </Row>

            <Stack direction="horizontal" gap={5}>
              <Col md={2}>
                <Form.Label className="mt-3">CO (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.co}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        co: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">NOX (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.nox}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        nox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">PM (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.pm}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        pm: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">SOx (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants.sox}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        sox: e.target.value,
                      };
                    })
                  }
                />
              </Col>
              <Col md={2}>
                <Form.Label className="mt-3">VOC (TONS/Year):</Form.Label>
                <Form.Control
                  value={pollutants["voc"]}
                  onChange={(e) =>
                    setPollutants((prev) => {
                      return {
                        ...prev,
                        voc: e.target.value,
                      };
                    })
                  }
                />
              </Col>
            </Stack>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Modal
        show={showMap}
        onHide={() => setShowMap(false)}
        backdrop="static"
        keyboard={false}
        //   centered
        size={"xl"}
      >
        {" "}
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>Plot a polygon for the given area source </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "hidden", height: "80vh" }}>
          <MapContainer
            center={[15.2033717, 120.5850848]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "80vh" }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {/* <Marker position={[51.505, -0.09]}>
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker> */}

            {/* <DraggableMarker callback={setCoordinates} /> */}
            <LeaftletDrawer setter={setGeojson} />
          </MapContainer>
        </Modal.Body>
      </Modal>

      <Modal show={showEf} onHide={() => setShowEf(false)} size={"xl"}>
        <Modal.Header closeButton>
          <Modal.Title>Emission Factors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Type of Source</th>
                <th>Fuel/Process</th>
                <th>PM</th>
                <th>CO</th>
                <th>NOX</th>
                <th>SOX</th>
                <th>VOC</th>
              </tr>
            </thead>
            <tbody>
              {FORMATTED_EMISSION_FACTORS.map((e, index) => {
                // console.log("ID", e.id);
                return (
                  <tr
                    data-key={e.id}
                    style={
                      detectedEf == e.id ? { backgroundColor: "#19875450" } : {}
                    }
                  >
                    <td style={{ textTransform: "capitalize" }}>{e.label}</td>
                    <td>{e.fuel_process}</td>
                    <td>{e.pm}</td>
                    <td>{e.co}</td>
                    <td>{e.nox}</td>
                    <td>{e.sox}</td>
                    <td>{e.voc}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddArea;
