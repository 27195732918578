import {
  faFileInvoice,
  faParagraph,
  faPencil,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import swal from "sweetalert";
import CONSTANTS from "../../utilities/constants";

const ViewStationaryEF = (props) => {
  const [show, setShow] = useState(false);
  const [fuelName, setFuelName] = useState(props.fuel_name);
  const [sulfurContent, setSulfurContent] = useState(props.sulfur_content);
  const [ashContent, setAshContent] = useState(props.ash_content);
  const [equipmentDependent, setEquipmentDependent] = useState(
    props.equipment_dependent
  );
  const [apsiList, setApsiList] = useState(JSON.parse(props.emission_factors));
  const [apsiListSimple, setApsiListSimple] = useState(
    JSON.parse(props.emission_factors)
  );
  useEffect(() => {
    console.log(apsiList);
  }, [apsiList]);
  const saveStationary = async () => {
    let apsis = equipmentDependent ? apsiList : apsiListSimple;
    const payload = JSON.stringify({
      apsis,
      fuelName,
      sulfurContent,
      ashContent,
      equipmentDependent,
    });
    try {
      let response = await fetch(
        CONSTANTS.API_ROOT + "/StationaryFactors/add",
        {
          method: "POST",
          body: payload,
        }
      );
      let r = await response.json();
      console.log(r);
      swal({
        text: "Emmission factor saved successfully",
        icon: "success",
      }).then(() => {
        setShow(false);
        // props.getExistingFactors();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button onClick={() => setShow(true)} className={props.className}>
        <FontAwesomeIcon icon={faFileInvoice} />
        <span> View</span>
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header
          closeButton
          className="ei-background"
          closeVariant="white"
        >
          <Modal.Title>
            View Emission Factor &mdash; Stationary Sources
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px", minHeight: "500px" }}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Fuel Name:</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="e.g. Natural Gas, Bagasse, etc"
                onChange={(e) => setFuelName(e.target.value)}
                value={fuelName}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Sulfur Content:</Form.Label>
              <Form.Control
                placeholder="Sulfur content in percentage"
                onChange={(e) => setSulfurContent(e.target.value)}
                value={sulfurContent}
                disabled={true}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Ash Content:</Form.Label>
              <Form.Control
                placeholder="Ash content in percentage"
                onChange={(e) => setAshContent(e.target.value)}
                value={ashContent}
                disabled={true}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form>
              <Form.Check
                disabled={true}
                checked={equipmentDependent}
                type={"switch"}
                id={`multiple-fuel`}
                label={`Does the emission factor on this fuel vary on the APSI Capacity?`}
                style={{ marginTop: "10px" }}
                onChange={(e) => {
                  setEquipmentDependent(e.target.checked);
                }}
              />
            </Form>
          </Row>
          <Row className="mt-3">
            {equipmentDependent ? (
              <APSI_TABLE
                apsiList={apsiList}
                setter={(index, data) => {
                  let copies = [...apsiList];
                  copies[index] = { ...copies[index], ...data };
                  setApsiList(copies);
                }}
                setList={setApsiList}
              />
            ) : (
              <APSI_TABLE_SIMPLE
                apsiList={apsiListSimple}
                setter={(index, data) => {
                  let copies = [...apsiListSimple];
                  copies[index] = { ...copies[index], ...data };
                  setApsiListSimple(copies);
                }}
                setList={setApsiListSimple}
              />
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

const APSI_TABLE = (props) => {
  const deleteApsi = (i) => {
    let updated = props.apsiList.filter((item, index) => index != i);
    props.setList(updated);
  };
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>APSI Type</th>
            <th>Min Capacity</th>
            <th>Max Capacity</th>
            <th>Capacity Unit</th>
            <th>CO</th>
            <th>NOX</th>
            <th>PM</th>
            <th>SOx</th>
            <th>VOC</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {props.apsiList.map((apsi, index) => {
            return (
              <tr>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.name}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        name: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.apsi_capacity_min}
                    type={"number"}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        apsi_capacity_min: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.apsi_capacity_max}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        apsi_capacity_max: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.apsi_unit}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        apsi_unit: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.co}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        co: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.nox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        nox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.pm}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        pm: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.sox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        sox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.voc}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        voc: e.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const APSI_TABLE_SIMPLE = (props) => {
  const deleteApsi = (i) => {
    let updated = props.apsiList.filter((item, index) => index != i);
    props.setList(updated);
  };
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>APSI Type</th>
            <th>CO</th>
            <th>NOX</th>
            <th>PM</th>
            <th>SOx</th>
            <th>VOC</th>
          </tr>
        </thead>
        <tbody>
          {props.apsiList.map((apsi, index) => {
            return (
              <tr>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.name}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        name: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.co}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        co: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.nox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        nox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.pm}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        pm: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.sox}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        sox: e.target.value,
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    disabled={true}
                    value={apsi.voc}
                    onChange={(e) => {
                      props.setter(index, {
                        ...apsi,
                        voc: e.target.value,
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Button
        style={{ marginLeft: "auto", width: "180px" }}
        variant="success"
        onClick={() => {
          props.setList((f) => [
            ...f,
            {
              name: "",
              co: "",
              nox: "",
              pm: "",
              sox: "",
              voc: "",
            },
          ]);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <span> Add APSI</span>
      </Button>
    </>
  );
};

export default ViewStationaryEF;
